import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BackIcon from '@material-ui/icons/ArrowBack'
import * as actions from 'src/redux/actions'
import * as selectors from 'src/redux/selectors'
import { logUserAction } from 'src/services/Api'

import { LeftMenu } from './LeftMenu'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: drawerWidth,
  },
  logoutButton: {
    color: '#FFFFFF',
    marginLeft: 'auto',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

export const AdminPage = ({
  title,
  children,
  onBackPressed,
}: {
  title: string
  children: any
  onBackPressed?: () => void
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const authenticatedUser = useSelector(selectors.getUser)
  const token = useSelector(selectors.getToken)
  const [
    showLogOutConfirmationModal,
    setShowLogOutConfirmationModal,
  ] = React.useState<boolean>(false)

  const handleBackPressed = () => {
    if (onBackPressed) {
      return onBackPressed()
    }
    history.goBack()
  }

  React.useEffect(() => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()
      return logUserOut(false) //this.doSomethingBeforeUnload()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelLogOut = () => {
    setShowLogOutConfirmationModal(false)
  }

  const logUserOut = (logAction: boolean) => {
    if (authenticatedUser === null) return
    if (logAction) {
      logUserAction(token, {
        username: authenticatedUser.username,
        action: 'Logged out',
      })
    }
    dispatch(actions.logout())
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleBackPressed}>
            <BackIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          <Button
            className={classes.logoutButton}
            onClick={() => {
              setShowLogOutConfirmationModal(true)
            }}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <LeftMenu />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      <Dialog
        open={showLogOutConfirmationModal}
        onClose={cancelLogOut}
        aria-labelledby="Confirm log out">
        <DialogTitle id="delete-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelLogOut} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => logUserOut(true)}
            color="secondary">
            Log out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
