import { RootState } from 'src/redux/types'

export const getUser = (state: RootState) => state.auth.user

export const getToken = (state: RootState) => state.auth.token || ''

export const getTCAcceptance = (state: RootState) =>
  state.auth.acceptedTermsAndConditions || false

export const getEncryptionKey = (state: RootState) =>
  state.auth.enteredEncryptionKey || false

export const getIsReEncryptingStatus = (state: RootState) =>
  state.auth.isReEncrypting || false

export const getUserRole = (state: RootState) =>
  state.auth.user ? state.auth.user.role : null

export const authError = (state: RootState) => state.auth.error

export const isLoggingIn = (state: RootState) => state.auth.isLoggingIn

export const isAuthenticated = (state: RootState) => state.auth.isAuthenticated
