import React from 'react'

export const useSubscriber = <T>() => {
  const listeners = React.useRef<((event: T) => void)[]>([])

  const publishEvent = React.useCallback((event: T) => {
    listeners.current.forEach((listener) => {
      listener(event)
    })
  }, [])

  const subscribe = React.useCallback((listener: (event: T) => void) => {
    listeners.current = listeners.current.concat(listener)

    return function unsubscribe() {
      listeners.current = listeners.current.filter(
        (prevListener) => prevListener !== listener,
      )
    }
  }, [])

  return { listeners, publishEvent, subscribe }
}
