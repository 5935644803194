import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  makeStyles,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { isEqual } from 'lodash'
import * as actions from 'src/redux/actions'
import * as selectors from 'src/redux/selectors'
import { SurveyFilters } from 'src/types'

const initialState: SurveyFilters = {
  gender: 'Any',
  dateOfBirth: null,
  ageStartYears: '',
  ageStartMonths: '',
  ageEndYears: '',
  ageEndMonths: '',
  diagnosis: 'Any',
  surveyStatus: 'Any',
  removalFlag: 'Any',
  country: 'Any',
  chwId: '',
  handedness: 'Any',
  childId: '',
  dataCollectionStart: null,
  dataCollectionEnd: null,
  deviceId: '',
}

const genders = ['Male', 'Female', 'Other', 'Any']
const diagnosisOptions = ['Available', 'Not available', 'Any']
const handednessOptions = ['Right', 'Left', 'Ambidextrous', 'Unknown', 'Any']
const removalFlagOptions = ['Kept', 'Removed', 'Any']
const countryOptions = ['India', 'Malawi', 'Any']
const surveyStatusOptions = ['Unread', 'Open', 'Closed', 'Any']

export const FilterControlsAndDialog = ({
  searching,
  setSearching,
  showChwIdError,
  getSurveys,
  deselectAll,
}: {
  searching: boolean
  setSearching: (value: boolean) => void
  showChwIdError: boolean
  getSurveys: (filters: SurveyFilters | {}) => Promise<void>
  deselectAll: () => void
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const initialFilterState = useSelector(selectors.getSurveyFilters)
  const [filterState, setFilterState] = React.useState(initialFilterState)

  const [filtersApplied, setFiltersApplied] = React.useState(
    !isEqual(initialState, filterState),
  )
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false)

  const clearFilters = async () => {
    setFilterState(initialState)
    setFiltersApplied(false)
    dispatch(actions.setSurveyFilters(initialState))
    await getSurveys({})
    deselectAll()
    setOpenFilterDialog(false)
  }

  const searchWithFilter = async () => {
    setSearching(true)
    setFiltersApplied(true)
    dispatch(actions.setSurveyFilters(filterState))
    await getSurveys({
      childId: filterState.childId,
      handedness: filterState.handedness,
      chwId: filterState.chwId,
      ageStartYears: filterState.ageStartYears,
      ageStartMonths: filterState.ageStartMonths,
      ageEndYears: filterState.ageEndYears,
      ageEndMonths: filterState.ageEndMonths,
      dataCollectionStart: filterState.dataCollectionStart,
      dataCollectionEnd: filterState.dataCollectionEnd,
      deviceId: filterState.deviceId,
      gender: filterState.gender,
      country: filterState.country,
      dateOfBirth: filterState.dateOfBirth,
      diagnosis: filterState.diagnosis,
      surveyStatus: filterState.surveyStatus,
      removalFlag: filterState.removalFlag,
    })
    deselectAll()
    setOpenFilterDialog(false)
  }

  return (
    <>
      <Button
        style={{ alignContent: 'center' }}
        className={classes.filtersButton}
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenFilterDialog(true)
        }}>
        <FilterListIcon style={{ marginRight: 5 }} />
        Filter
      </Button>
      <FormControl>
        <TextField
          id="searchterms"
          label="Search"
          size="small"
          className={classes.filtersButton}
          value={filterState.childId}
          autoFocus={true}
          onKeyDown={(evt) => {
            if (evt.keyCode === 13) {
              searchWithFilter()
            }
          }}
          onChange={(evt) => {
            setFilterState({ ...filterState, childId: evt.target.value })
          }}
          variant="outlined"
        />
      </FormControl>
      {filtersApplied && (
        <Button
          className={classes.filtersButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            clearFilters()
          }}>
          Clear Filters
        </Button>
      )}
      <Dialog
        open={openFilterDialog}
        onClose={() => {
          setOpenFilterDialog(false)
        }}
        aria-labelledby="Filter">
        <DialogTitle id="delete-dialog-title">Filter</DialogTitle>
        <DialogContent>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <Typography>Gender</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <Select
                  native
                  value={filterState.gender}
                  style={{ marginBottom: 10, height: 40 }}
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      gender: evt.target.value as
                        | 'Male'
                        | 'Female'
                        | 'Other'
                        | 'Any',
                    })
                  }}
                  inputProps={{
                    name: 'gender',
                    id: 'gender',
                  }}>
                  {genders.map((gender, ind) => (
                    <option
                      key={`gender-${ind}`}
                      aria-label={gender}
                      value={gender}>
                      {gender}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Date of birth</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <TextField
                  variant="outlined"
                  id="date"
                  type="date"
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      dateOfBirth: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Age</Typography>
              <Typography variant="body2">
                Age should be entered in days. If a minimum age or maximum age
                is entered, these filters will override the date of birth filter
                above.
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControl
                style={{
                  display: 'flex',
                  marginTop: 5,
                  marginBottom: 10,
                  marginRight: 10,
                }}>
                <TextField
                  id="startdate"
                  variant="outlined"
                  size="small"
                  value={filterState.ageStartYears}
                  placeholder="From (years)"
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      ageStartYears: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  display: 'flex',
                  marginTop: 5,
                  marginBottom: 10,
                  marginRight: 10,
                }}>
                <TextField
                  id="startdate"
                  variant="outlined"
                  size="small"
                  value={filterState.ageStartMonths}
                  placeholder="months"
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      ageStartMonths: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControl
                style={{
                  display: 'flex',
                  marginTop: 5,
                  marginBottom: 10,
                  marginLeft: 10,
                }}>
                <TextField
                  id="enddate"
                  variant="outlined"
                  size="small"
                  value={filterState.ageEndYears}
                  placeholder="To (years)"
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      ageEndYears: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl
                style={{
                  display: 'flex',
                  marginTop: 5,
                  marginBottom: 10,
                  marginLeft: 10,
                }}>
                <TextField
                  id="enddate"
                  variant="outlined"
                  size="small"
                  value={filterState.ageEndMonths}
                  placeholder="months"
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      ageEndMonths: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Diagnosis</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <Select
                  native
                  value={filterState.diagnosis}
                  style={{ marginBottom: 10, height: 40 }}
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      diagnosis: evt.target.value as
                        | 'Available'
                        | 'Not available'
                        | 'Any',
                    })
                  }}
                  inputProps={{
                    name: 'diagnosis',
                    id: 'diagnosis',
                  }}>
                  {diagnosisOptions.map((diagnosis, ind) => (
                    <option
                      key={`diagnosis-${ind}`}
                      aria-label={diagnosis}
                      value={diagnosis}>
                      {diagnosis}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Survey status</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <Select
                  native
                  value={filterState.surveyStatus}
                  style={{ marginBottom: 10, height: 40 }}
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      surveyStatus: evt.target.value as
                        | 'Unread'
                        | 'Open'
                        | 'Closed'
                        | 'Any',
                    })
                  }}
                  inputProps={{
                    name: 'surveystatus',
                    id: 'surveystatus',
                  }}>
                  {surveyStatusOptions.map((surveyStatus, surveyInd) => (
                    <option
                      key={`status-${surveyInd}`}
                      aria-label={surveyStatus}
                      value={surveyStatus}>
                      {surveyStatus}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Removal flag</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <Select
                  native
                  value={filterState.removalFlag}
                  style={{ marginBottom: 10, height: 40 }}
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      removalFlag: evt.target.value as
                        | 'Kept'
                        | 'Removed'
                        | 'Any',
                    })
                  }}
                  inputProps={{
                    name: 'removalflag',
                    id: 'removalflag',
                  }}>
                  {removalFlagOptions.map((removalFlag, ind) => (
                    <option
                      key={`remFlag-${ind}`}
                      aria-label={removalFlag}
                      value={removalFlag}>
                      {removalFlag}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Country</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <Select
                  native
                  value={filterState.country}
                  style={{ marginBottom: 10, height: 40 }}
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      country: evt.target.value as 'India' | 'Malawi' | 'Any',
                    })
                  }}
                  inputProps={{
                    name: 'country',
                    id: 'country',
                  }}>
                  {countryOptions.map((country, ind) => (
                    <option
                      key={`country-${ind}`}
                      aria-label={country}
                      value={country}>
                      {country}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>CHW ID</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <TextField
                  id="chwId"
                  variant="outlined"
                  value={filterState.chwId}
                  size="small"
                  onChange={(evt) => {
                    setFilterState({ ...filterState, chwId: evt.target.value })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {showChwIdError && (
                  <Typography variant="body2" color="secondary">
                    CHW ID not found!
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Handedness</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <Select
                  native
                  value={filterState.handedness}
                  style={{ marginBottom: 10, height: 40 }}
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      handedness: evt.target.value as
                        | 'Right'
                        | 'Left'
                        | 'Ambidextrous'
                        | 'Unknown'
                        | 'Any',
                    })
                  }}
                  inputProps={{
                    name: 'handedness',
                    id: 'handedness',
                  }}>
                  {handednessOptions.map((handedness, ind) => (
                    <option
                      key={`handedness-${ind}`}
                      aria-label={handedness}
                      value={handedness}>
                      {handedness}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Data collection</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                style={{
                  display: 'flex',
                  marginTop: 5,
                  marginBottom: 10,
                  marginRight: 10,
                }}>
                <TextField
                  id="collectionstartdate"
                  variant="outlined"
                  size="small"
                  placeholder="Since"
                  type="date"
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      dataCollectionStart: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                style={{
                  display: 'flex',
                  marginTop: 5,
                  marginBottom: 10,
                  marginLeft: 10,
                }}>
                <TextField
                  id="collectionenddate"
                  variant="outlined"
                  size="small"
                  placeholder="Till"
                  type="date"
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      dataCollectionEnd: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Device ID</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <TextField
                  id="deviceid"
                  variant="outlined"
                  size="small"
                  value={filterState.deviceId}
                  onChange={(evt) => {
                    setFilterState({
                      ...filterState,
                      deviceId: evt.target.value,
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={searching}
            type="submit"
            variant="contained"
            onClick={() => searchWithFilter()}
            color="primary">
            {searching ? <CircularProgress size="1.5rem" /> : 'Apply'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles({
  filtersButton: {
    marginLeft: 5,
    marginRight: 5,
  },
})
