import produce from 'immer'
import { Reducer } from 'src/redux/types'

export type User = {
  username: string
  firstName: string
  lastName: string
  role: 'admin' | 'superAdmin' | 'backendResearcher'
}

export type AuthState = {
  isAuthenticated: any
  user: User | null
  token: any
  acceptedTermsAndConditions: boolean
  enteredEncryptionKey: string | boolean //@TODO: fix this
  isReEncrypting: boolean
  isLoggingIn: boolean
  isResettingPassword: boolean
  isCreatingNewPassword: boolean
  error: any
}

const initialState = {
  isAuthenticated: null,
  user: null,
  token: null,
  acceptedTermsAndConditions: false,
  enteredEncryptionKey: false,
  isReEncrypting: false,
  isLoggingIn: false,
  isResettingPassword: false,
  isCreatingNewPassword: false,
  error: null,
}

export const authReducer: Reducer<AuthState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'INIT':
      return produce(state, (draft) => {
        draft.error = null
        draft.isLoggingIn = false
        if (state.isAuthenticated === null) draft.isAuthenticated = false
      })
    case 'LOGIN':
      return produce(state, (draft) => {
        draft.error = null
        draft.isLoggingIn = true
      })
    case 'LOGIN_SUCCESS':
      return produce(state, (draft) => {
        draft.isAuthenticated = true
        draft.acceptedTermsAndConditions = false
        draft.enteredEncryptionKey = false
        draft.user = action.payload.user
        draft.token = action.payload.token
        draft.error = null
        draft.isLoggingIn = false
      })
    case 'LOGIN_FAIL':
      return produce(state, (draft) => {
        draft.isAuthenticated = false
        draft.acceptedTermsAndConditions = false
        draft.enteredEncryptionKey = false
        draft.isLoggingIn = false
        draft.error = action.payload.error
      })
    case 'ACCEPTED_TERMS_AND_CONDITIONS':
      return produce(state, (draft) => {
        draft.acceptedTermsAndConditions = true
      })
    case 'ENTERED_ENCRYPTION_KEY':
      return produce(state, (draft) => {
        draft.enteredEncryptionKey = action.payload.key
      })
    case 'IS_RE_ENCRYPTING':
      return produce(state, (draft) => {
        draft.isReEncrypting = action.payload.status
      })
    case 'LOGOUT': {
      return {
        ...initialState,
        enteredEncryptionKey: false,
        isAuthenticated: false,
        isReEncrypting: false,
      }
    }

    default:
      return state
  }
}
