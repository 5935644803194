import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'

import { deleteUser, fetchUsers, logUserAction } from '../services/Api'
import { AdminPage } from './AdminPage'

export const SystemUsers = () => {
  const history = useHistory()
  const user = useSelector(selectors.getUser)
  const token = useSelector(selectors.getToken)
  const classes = useStyles()
  const { innerHeight: height } = window

  const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false)
  const [deleteUserId, setDeleteUserId] = React.useState(false)
  const [removingUser, setRemovingUser] = React.useState<boolean>(false)

  const openDeleteDialog = (id: any) => {
    setDeleteDialogVisible(true)
    setDeleteUserId(id)
  }

  const closeDeleteDialog = () => {
    setDeleteDialogVisible(false)
    setDeleteUserId(false)
  }

  const [users, setUsers] = React.useState<any>([])

  const roleMap = {
    superAdmin: 'Super Admin',
    communityHealthWorker: 'Community Health Worker',
    backendResearcher: 'Back End Researcher',
    admin: 'Admin',
  }

  const addNewUser = () => {
    history.push('/user-form')
  }

  const editUser = (id: any) => {
    history.push('/user-form', { userId: id })
  }

  const removeUser = () => {
    setRemovingUser(true)
    const userToRemove = users.find((u) => u.id === deleteUserId)
    deleteUser(token, deleteUserId).then((resp: any) => {
      if (resp.data.success) {
        if (user === null) return
        logUserAction(token, {
          username: user.username,
          action: `Deleted ${roleMap[userToRemove.role]} ${
            userToRemove.username
          }`,
        })
        history.push('/system-users')
        getUserList()
        setRemovingUser(false)
        closeDeleteDialog()
      }
    })
  }

  const getPrettyRole = (role) => {
    if (role) {
      if (roleMap[role]) {
        return roleMap[role]
      }
    }
    return ''
  }

  const getUserList = () => {
    if (token) {
      fetchUsers(token).then((resp: any) => {
        if (resp.data.success) {
          const usersOrderedByRegistrationDate = resp.data.users.sort((a, b) =>
            a.dateRegistered < b.dateRegistered
              ? 1
              : b.dateRegistered < a.dateRegistered
              ? -1
              : 0,
          )
          setUsers(usersOrderedByRegistrationDate)
        }
      })
    }
  }

  useEffect(() => {
    getUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AdminPage title="System users">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            From this screen, you can add, edit or delete backend and app users.
          </Typography>
        </Grid>
        <Grid className={classes.addnew}>
          <Button variant="contained" color="primary" onClick={addNewUser}>
            Add new user
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} style={{ maxHeight: 0.7 * height }}>
            <Table stickyHeader className={classes.table} aria-label="App">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>User ID</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Role</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Registration date</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Last login</strong>
                  </TableCell>
                  <TableCell className={classes.cellCentred}>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row, rowIndex) => (
                  <React.Fragment key={row.id}>
                    <TableRow key={rowIndex}>
                      <TableCell component="th" scope="row">
                        {`${row.firstName} ${row.lastName}`}
                      </TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell>{getPrettyRole(row.role)}</TableCell>
                      <TableCell>
                        {moment(row.dateRegistered).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(row.lastLogin).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell className={classes.cellCentred}>
                        <Button
                          color="primary"
                          onClick={() => editUser(row.id)}>
                          Edit
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => openDeleteDialog(row.id)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={deleteDialogVisible}
        onClose={closeDeleteDialog}
        aria-labelledby="Confirm user delete">
        <DialogTitle id="delete-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={removingUser}
            type="submit"
            variant="contained"
            onClick={() => removeUser()}
            color="secondary">
            {removingUser ? <CircularProgress size="1.5rem" /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </AdminPage>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  addnew: {
    textAlign: 'right',
    padding: 12,
  },
  cellCentred: {
    textAlign: 'center',
  },
})
