import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  CircularProgress,
  Container,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LockOutlined from '@material-ui/icons/LockOutlined'
import Alert from '@material-ui/lab/Alert'
import * as actions from 'src/redux/actions'
import { login } from 'src/redux/actionThunk/auth'
import * as selectors from 'src/redux/selectors'
import { logUserAction, resetPassword } from 'src/services/Api'

export const Login = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const isLoggingIn = useSelector(selectors.isLoggingIn)
  let authError = useSelector(selectors.authError)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [forgotPasswordMode, setForgotPasswordMode] = React.useState<any>(false)
  const [isResettingPassword, setIsResettingPassword] = React.useState<boolean>(
    false,
  )
  const [resetPasswordEmail, setResetPasswordEmail] = React.useState<string>('')
  const [resetPasswordError, setResetPasswordError] = React.useState<any>(false)
  const [passwordResetSent, setPasswordResetSent] = React.useState<boolean>(
    false,
  )

  const submit = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
      .then((result) => {
        if (result && typeof result !== 'string' && result.token) {
          logUserAction(result.token, {
            username: email,
            action: 'Logged in',
          })
          dispatch(actions.init())
          history.push('/')
        } else {
          authError = 'There was an error logging in.'
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const initResetPassword = async (emailToReset) => {
    try {
      const response = await resetPassword(emailToReset)
      const { result } = response.data
      if (!result) {
        setResetPasswordError(
          'An error occurred and your passwod was not reset.',
        )
      }
      if (result) {
        setPasswordResetSent(true)
        setIsResettingPassword(false)
        setForgotPasswordMode(false)
      }
    } catch (err) {
      let message = typeof err === 'string' ? err : 'Oops, something went wrong'
      if (err.response && err.response.data && err.response.data.message)
        message = err.response.data.message
      setResetPasswordError(message)
    }
  }

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {passwordResetSent && (
          <Alert severity="success" style={{ marginBottom: 15 }}>
            Please check your email for a link to reset your password.
          </Alert>
        )}
        <Avatar>
          <LockOutlined />
        </Avatar>
        {forgotPasswordMode && (
          <React.Fragment>
            <Typography component="h1" variant="h5">
              Reset password
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={(evt) => {
                evt.preventDefault()
                initResetPassword(resetPasswordEmail)
              }}>
              <TextField
                value={resetPasswordEmail}
                onChange={(e: any) => setResetPasswordEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="restore_password_email"
                label="Email Address"
                name="restore_password_email"
                autoComplete="email"
                autoFocus
              />
              <Button
                disabled={isResettingPassword}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}>
                {isLoggingIn ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  'Reset Password'
                )}
              </Button>
              {resetPasswordError && (
                <Typography color="error" align="center">
                  {resetPasswordError}
                </Typography>
              )}
            </form>
          </React.Fragment>
        )}
        {!forgotPasswordMode && (
          <React.Fragment>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={(data) => submit(data)}>
              <TextField
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                fullWidth
                variant="text"
                color="primary"
                onClick={() => {
                  setForgotPasswordMode(true)
                }}>
                Forgot password
              </Button>
              <Button
                disabled={isLoggingIn}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}>
                {isLoggingIn ? <CircularProgress size="1.5rem" /> : 'Sign In'}
              </Button>
              {authError && (
                <Typography color="error" align="center">
                  {authError}
                </Typography>
              )}
            </form>
          </React.Fragment>
        )}
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: '2.5rem',
  },
}))
