import React from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'

import {
  fetchAgeBasedFiltration,
  updateAgeBasedFiltration,
} from '../../services/Api'
import { getDateDiffInYearsAndMonths } from '../../services/Utils'
import { AdminPage } from '../AdminPage'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  searchBar: {
    marginLeft: theme.spacing(2),
  },
  convertedAges: {
    color: '#3F64CC',
  },
}))

type SnackBarStatusType = 'success' | 'error' | undefined

export const AgeBasedFiltration = () => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)

  const updates = React.useRef({})
  const [data, setData] = React.useState<any>([])
  const [pageData, setPageData] = React.useState<any>([])
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [isSaving, setIsSaving] = React.useState(false)
  const [
    snackbarStatus,
    setSnackbarStatus,
  ] = React.useState<SnackBarStatusType>()

  React.useEffect(() => {
    if (token) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const getData = () => {
    fetchAgeBasedFiltration(token).then(({ data: ageData }) => {
      let dataArray: any[] = []
      Object.keys(ageData.payload).map((id) => {
        dataArray.push(ageData.payload[id])
      })
      setData(dataArray)
      setPageData(dataArray.slice(0, pageSize))
      setCurrentPage(0)
    })
  }

  const handleChange = (id, row) => {
    updates.current[id] = row
  }

  const handleChangePage = (event, newPage) => {
    let targetIndex = pageSize * newPage
    setPageData(data.slice(targetIndex, targetIndex + pageSize))
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    let targetIndex = pageSize * currentPage
    setPageData(data.slice(targetIndex, targetIndex + event.target.value))
    setPageSize(event.target.value)
  }

  function save() {
    setIsSaving(true)
    const updatedTestIDs = Object.keys(updates.current)
    const currentData = updatedTestIDs.map((id) => updates.current[id])
    updateAgeBasedFiltration(token, currentData)
      .then(() => setSnackbarStatus('success'))
      .catch(() => setSnackbarStatus('error'))
      .finally(() => {
        setIsSaving(false)
        getData()
      })
    // update data shown on page
  }
  if (pageData.length === 0) return <div />

  return (
    <AdminPage title="Age Based Filtration">
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="App">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Starting Test / Item</strong>
                </TableCell>
                <TableCell colSpan={2}>
                  <strong>Minimum Age (Days)</strong>
                </TableCell>
                <TableCell colSpan={2}>
                  <strong>Maximum Age (Days)</strong>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData &&
                pageData.map((row, id) => {
                  return (
                    <CustomRow key={id} def={row} handleChange={handleChange} />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={pageSize}
          page={currentPage}
          onChangePage={(evt, newPage) => handleChangePage(evt, newPage)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      <Button
        disabled={isSaving}
        onClick={() => save()}
        style={{ marginTop: '2rem' }}
        variant="contained"
        color="primary">
        Save
        {isSaving && (
          <CircularProgress className={classes.buttonProgress} size={24} />
        )}
      </Button>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}

const CustomRow = ({ def, handleChange }) => {
  const classes = useStyles()

  const setConvertedAge = (ageInDays: number) => {
    return getDateDiffInYearsAndMonths(
      moment().subtract(ageInDays, 'days').toDate(),
      new Date(),
    )
  }

  const [minAge, setMinAge] = React.useState(def.minAge || 0)
  const [maxAge, setMaxAge] = React.useState(def.maxAge || 0)

  const [convertedMinAge, setConvertedMinAge] = React.useState<{
    years: number
    months: number
  }>(setConvertedAge(def.minAge || 0))
  const [convertedMaxAge, setConvertedMaxAge] = React.useState<{
    years: number
    months: number
  }>(setConvertedAge(def.maxAge || 0))

  React.useEffect(() => {
    if (!def) return
    if (minAge === def.minAge && maxAge === def.maxAge) return
    handleChange(def.testId, { ...def, minAge, maxAge })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minAge, maxAge])

  React.useEffect(() => {
    if (!def) return
    setMinAge(def.minAge)
    setMaxAge(def.maxAge)
    setConvertedMinAge(setConvertedAge(def.minAge))
    setConvertedMaxAge(setConvertedAge(def.maxAge))
  }, [def])

  return (
    <TableRow>
      <TableCell>{def.testName}</TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          size="small"
          onChange={(evt: any) => {
            let val = evt.target.value
            if (/^\d+$/.test(val) || val === '') {
              setMinAge(val)
              setConvertedMinAge(
                getDateDiffInYearsAndMonths(
                  moment().subtract(val, 'days').toDate(),
                  new Date(),
                ),
              )
            }
          }}
          onBlur={() => {
            if (minAge === '') setMinAge('0')
          }}
          style={{ maxWidth: 100 }}
          value={minAge}
        />
      </TableCell>
      <TableCell className={classes.convertedAges}>
        {ageToString(convertedMinAge)}
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          size="small"
          onChange={(evt: any) => {
            let val = evt.target.value
            if (/^\d+$/.test(val) || val === '') {
              setMaxAge(val)
              setConvertedMaxAge(
                getDateDiffInYearsAndMonths(
                  moment().subtract(val, 'days').toDate(),
                  new Date(),
                ),
              )
            }
          }}
          onBlur={() => {
            if (maxAge === '') setMaxAge('0')
          }}
          style={{ maxWidth: 100 }}
          value={maxAge}
        />
      </TableCell>
      <TableCell className={classes.convertedAges}>
        {ageToString(convertedMaxAge)}
      </TableCell>
    </TableRow>
  )
}

const ageToString = ({ years, months }: { years: number; months: number }) => {
  const yearOrYears = years === 1 ? 'year' : 'years'
  const monthOrMonths = months === 1 ? 'month' : 'months'
  return `${years} ${yearOrYears}, ${months} ${monthOrMonths}`
}
