export const getDateDiffInDays = (date1: Date | undefined, date2: Date) => {
  if (!date1) return 0
  const diffTime = Math.abs(date2.getTime() - date1.getTime())
  return Math.floor(diffTime / (1000 * 60 * 60 * 24))
}

export const getDateDiffInYearsAndMonths = (date1: Date, date2: Date) => {
  if (!date1 || !date2 || date1 > date2) {
    return { years: 0, months: 0 }
  }
  let yearsDiff = date2.getFullYear() - date1.getFullYear()
  let monthsDiff = date2.getMonth() - date1.getMonth()
  const daysDiff = date2.getDate() - date1.getDate()
  if (daysDiff < 0) {
    monthsDiff -= 1
  }
  if (monthsDiff < 0) {
    yearsDiff -= 1
    monthsDiff += 12
  }
  return { years: yearsDiff, months: monthsDiff }
}
