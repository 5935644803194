import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MuiAlert from '@material-ui/lab/Alert'
import retry from 'async-retry'
import { DialogLightAndHeavy } from 'src/pages/SurveyResults/DialogLightAndHeavy'
import { DialogTermsAndConditions } from 'src/pages/SurveyResults/DialogTermsAndConditions'
import {
  testLongNames,
  testShortNames,
} from 'src/pages/SurveyResults/staticValues'
import * as actions from 'src/redux/actions'
import * as selectors from 'src/redux/selectors'
import { ChildInfo } from 'src/types'

import { env } from '../env'
import {
  fetchChildBySurveyId,
  fetchParent,
  fetchPreviewWeight,
  fetchSurveyStatus,
  fetchSurveyTests,
  logUserAction,
  makeDownloadRequest,
  pollDownloadRequest,
  updateSurveyStatus,
} from '../services/Api'
import { AdminPage } from './AdminPage'
import { ViewChildInfo } from './ViewChildInfo'
const baseUrl = env.apiBaseUrl

export const ViewSurvey = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const TCAcceptance = useSelector(selectors.getTCAcceptance)
  const enteredEncryptionKey = useSelector(selectors.getEncryptionKey)
  const token = useSelector(selectors.getToken)
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const classes = useStyles()
  const user = useSelector(selectors.getUser)
  const singleTestDownloadId = React.useRef<string | null>(null)
  const downloadId = React.useRef<string | null>(null)
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [tests, setTests] = React.useState<any>([])
  const [surveyId, setSurveyId] = React.useState<string | null>(null)
  const [surveyDate, setSurveyDate] = React.useState<Date | null>(null)
  const [openChildDialog, setOpenChildDialog] = React.useState(false)
  const [surveyStatus, setSurveyStatus] = React.useState<any>()
  const [childInfo, setChildInfo] = React.useState<ChildInfo | null>(null)
  const [parent1Info, setParent1Info] = React.useState<any>({})
  const [parent2Info, setParent2Info] = React.useState<any>({})
  const [fileModel, setFileModel] = React.useState<any>()
  const [previewWaiting, setPreviewWaiting] = React.useState<any>(false)
  const [
    openLightOrHeavyDataDialog,
    setOpenLightOrHeavyDataDialog,
  ] = React.useState<any>()
  const [openEncryptionDialog, setOpenEncryptionDialog] = React.useState<any>(
    false,
  )
  const [TCAccepted, setTCAccepted] = React.useState<boolean>(
    TCAcceptance || false,
  )
  const [openTCDialog, setOpenTCDialog] = React.useState<any>(false)
  const [
    lightOrHeavyDataModel,
    setLightOrHeavyDataModel,
  ] = React.useState<any>()
  const statuses = {
    superAdmin: ['Viewed', 'Closed', 'Unread'],
    backendResearcher: ['Unread', 'Viewed'],
  }

  const location = useLocation()

  const handleSurveyStatusChange = (evt: any) => {
    const newstatus = evt.target.value
    setSurveyStatus(newstatus)
    if (token) {
      updateSurveyStatus(token, surveyId, evt.target.value)
        .then(() => {
          if (user === null || childInfo === null) return
          logUserAction(token, {
            username: user.username,
            action: `Updated the status of a survey for child ${childInfo.id} to ${newstatus}`,
          })
        })
        .catch(() => {
          setSnackbarStatus('error')
        })
    }
  }

  const downloadSurvey = async () => {
    if (!token || !surveyId) return
    setIsDownloading(true)
    const testRequest = {}
    testRequest[surveyId] = {}

    Object.keys(tests).map((test) => {
      if (tests[test]) {
        testRequest[surveyId][test] = true
      }
    })

    try {
      const resp = await makeDownloadRequest(token, {
        storedKey: enteredEncryptionKey,
        surveyIds: [surveyId],
        testsRequested: testRequest,
        dataModel: lightOrHeavyDataModel,
      })
      retry(
        async () => {
          downloadId.current = resp.data
          const response = await pollDownloadRequest(token, downloadId.current)
          if (response.status === 202) {
            window.open(
              `${baseUrl}/download/id/${downloadId.current}?token=${token}`,
            )
            if (user === null || childInfo === null) return
            logUserAction(token, {
              username: user.username,
              action: `Downloaded a survey for child ${childInfo.id}`,
            })
          }
        },
        {
          maxTimeout: 1000,
          retries: 30,
        },
      )
    } catch (err) {
      setSnackbarStatus(err.response.data.message)
    }
    setIsDownloading(false)
  }

  const getSurveyTests = async (id: string) => {
    if (!token) return
    const resp = await fetchSurveyTests(token, id)
    if (!resp.data) return
    setTests(resp.data)
  }

  const getSurveyStatus = async (id: string, fetchedChildInfo: ChildInfo) => {
    if (!token || !fetchedChildInfo) return
    const resp = await fetchSurveyStatus(token, id)
    if (!resp.data) return
    setSurveyStatus(resp.data)
    if (resp.data === 'Unread') {
      updateSurveyStatus(token, id, 'Viewed')
        .then(() => {
          setSurveyStatus('Viewed')
        })
        .catch(() => {
          setSnackbarStatus('error')
        })
    }
    if (user === null) return
    logUserAction(token, {
      username: user.username,
      action: `Opened a survey for child ${fetchedChildInfo.id}`,
    })
  }

  const getChildInfo = async (id: string) => {
    if (!token) return null
    try {
      const resp = await fetchChildBySurveyId(token, id)
      if (resp.data) {
        setChildInfo(resp.data)
        return resp.data
      }
    } catch (e) {
      console.log('GET CHILD INFO ERROR', e)
      setSnackbarStatus('error')
      return null
    }
  }

  const getParentsInfo = async (childID: string) => {
    if (!token) return
    await fetchParent(token, childID, 1)
      .then((resp: any) => {
        if (resp.data) {
          setParent1Info(resp.data)
        }
      })
      .catch(() => {
        setSnackbarStatus('error')
      })
    await fetchParent(token, childID, 2)
      .then((resp: any) => {
        if (resp.data) {
          setParent2Info(resp.data)
        }
      })
      .catch(() => {
        setSnackbarStatus('error')
      })
  }

  const viewData = async (testId, attemptId) => {
    if (!token || !surveyId) return
    const testRequest = {}
    testRequest[surveyId] = {}

    testRequest[surveyId][testId] = true
    try {
      const resp = await fetchPreviewWeight(token, {
        surveyIds: [surveyId],
        testsRequested: testRequest,
        attemptId,
      })
      const { weight } = resp.data
      if (weight === 'heavy') {
        history.push('/survey/attempt/preview', {
          surveyId,
          testId,
          attemptId,
          encryptionKeyNeeded: true,
        })
      } else {
        history.push('/survey/attempt/preview', {
          surveyId,
          testId,
          attemptId,
        })
      }
    } catch (err) {
      setSnackbarStatus(err.response.data.message)
    }
  }

  const downloadSingleTest = async (payload, attemptId) => {
    setIsDownloading(true)
    try {
      const response = await makeDownloadRequest(token, {
        storedKey: enteredEncryptionKey,
        surveyIds: [surveyId],
        testsRequested: payload,
        attemptId,
      })
      retry(
        async () => {
          singleTestDownloadId.current = response.data
          try {
            const pollResponse = await pollDownloadRequest(
              token,
              singleTestDownloadId.current,
            )
            if (pollResponse.status === 202) {
              window.open(
                `${baseUrl}/download/id/${singleTestDownloadId.current}?token=${token}`,
              )
            }
          } catch (err) {
            setSnackbarStatus(err.response.data.message)
          }
        },
        {
          maxTimeout: 1000,
          retries: 30,
        },
      )
    } catch (err) {
      setSnackbarStatus(err.response.data.message)
    }
    setIsDownloading(false)
  }

  const downloadData = async (test, attemptId) => {
    if (!token || !surveyId) return
    const testRequest = {}
    testRequest[surveyId] = {}

    testRequest[surveyId][test] = true
    try {
      const response = await fetchPreviewWeight(token, {
        surveyIds: [surveyId],
        testsRequested: testRequest,
        attemptId,
      })

      const { weight } = response.data
      if (weight === 'heavy') {
        if (enteredEncryptionKey) {
          downloadSingleTest(testRequest, attemptId)
        } else {
          setOpenEncryptionDialog(true)
        }
      } else {
        downloadSingleTest(testRequest, attemptId)
      }
    } catch (err) {
      setSnackbarStatus(err.response.data.message)
    }
  }

  useEffect(() => {
    const fetchData = async (idToFetch: string) => {
      setSurveyId(idToFetch)
      const fetchedChildInfo = await getChildInfo(idToFetch)
      getSurveyStatus(idToFetch, fetchedChildInfo)
      getSurveyTests(idToFetch)
    }
    if (!location || !location.state) return
    const {
      surveyId: id,
      surveyDate: date,
      showSuccess,
      showFailure,
    }: any = location.state
    if (!id || !date) return
    setSurveyDate(new Date(date))
    fetchData(id)

    if (showFailure) {
      setSnackbarStatus(showFailure)
    } else if (showSuccess) {
      setSnackbarStatus(showSuccess)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    childInfo && getParentsInfo(childInfo.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childInfo])

  if (openChildDialog && childInfo && surveyId && surveyDate) {
    return (
      <ViewChildInfo
        childInfo={childInfo}
        parent1Info={parent1Info}
        parent2Info={parent2Info}
        surveyId={surveyId}
        surveyDate={surveyDate}
        setOpenChildDialog={setOpenChildDialog}
      />
    )
  }

  return (
    <AdminPage title="View Survey">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {user &&
            (user.role === 'superAdmin' ||
              user.role === 'backendResearcher') && (
              <Typography variant="body1">
                View or decrypt and download tests results for this particular
                survey.
              </Typography>
            )}
          {user && user.role === 'admin' && (
            <Typography variant="body1">
              View child and parent info associated with this survey.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} className={classes.addnew}>
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
            <Button
              style={{ alignContent: 'center' }}
              color="primary"
              onClick={() => {
                setOpenChildDialog(true)
              }}>
              Child Info
              <VisibilityIcon style={{ marginLeft: 5 }} />
            </Button>
          </div>
        </Grid>
        {user &&
          (user.role === 'superAdmin' || user.role === 'backendResearcher') && (
            <>
              <Grid item xs={10}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="App">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.mainCol}>
                          <strong>Test</strong>
                        </TableCell>
                        <TableCell className={classes.attemptCol}>
                          <strong>Attempt 1</strong>
                        </TableCell>
                        <TableCell className={classes.attemptCol}>
                          <strong>Attempt 2</strong>
                        </TableCell>
                        <TableCell className={classes.attemptCol}>
                          <strong>Attempt 3</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {testShortNames.map((rowName, rowIndex) => {
                        const row = tests[rowName] ? tests[rowName] : false
                        if (!row) return <></>
                        return (
                          <React.Fragment key={row.id}>
                            {tests[rowName] && (
                              <TableRow key={rowIndex}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.mainCol}>
                                  {`${testLongNames[rowName]}`}
                                </TableCell>
                                <TableCell className={classes.attemptCol}>
                                  {user &&
                                    (user.role === 'superAdmin' ||
                                      user.role === 'backendResearcher') && (
                                      <>
                                        {tests[rowName][0] && (
                                          <>
                                            <Button
                                              color="primary"
                                              onClick={() =>
                                                viewData(
                                                  rowName,
                                                  tests[rowName][0],
                                                )
                                              }>
                                              <VisibilityIcon
                                                style={{ marginRight: 5 }}
                                              />
                                            </Button>
                                            <Button
                                              color="primary"
                                              onClick={() =>
                                                downloadData(
                                                  rowName,
                                                  tests[rowName][0],
                                                )
                                              }>
                                              <CloudDownloadIcon
                                                style={{ marginRight: 5 }}
                                              />
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    )}
                                </TableCell>
                                <TableCell className={classes.attemptCol}>
                                  {(user.role === 'superAdmin' ||
                                    user.role === 'backendResearcher') && (
                                    <>
                                      {tests[rowName][1] && (
                                        <>
                                          <Button
                                            color="primary"
                                            onClick={() =>
                                              viewData(
                                                rowName,
                                                tests[rowName][1],
                                              )
                                            }>
                                            <VisibilityIcon
                                              style={{ marginRight: 5 }}
                                            />
                                          </Button>
                                          <Button
                                            color="primary"
                                            onClick={() =>
                                              downloadData(
                                                rowName,
                                                tests[rowName][1],
                                              )
                                            }>
                                            <CloudDownloadIcon
                                              style={{ marginRight: 5 }}
                                            />
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell className={classes.attemptCol}>
                                  {user &&
                                    (user.role === 'superAdmin' ||
                                      user.role === 'backendResearcher') && (
                                      <>
                                        {tests[rowName][2] && (
                                          <>
                                            <Button
                                              color="primary"
                                              onClick={() =>
                                                viewData(
                                                  rowName,
                                                  tests[rowName][2],
                                                )
                                              }>
                                              <VisibilityIcon
                                                style={{ marginRight: 5 }}
                                              />
                                            </Button>
                                            <Button
                                              color="primary"
                                              onClick={() =>
                                                downloadData(
                                                  rowName,
                                                  tests[rowName][2],
                                                )
                                              }>
                                              <CloudDownloadIcon
                                                style={{ marginRight: 5 }}
                                              />
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    )}
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid container spacing={2}>
                <div
                  style={{
                    width: '80%',
                    margin: 20,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <div>
                    <FormLabel
                      style={{ marginBottom: 10 }}
                      htmlFor="questionid">
                      <Typography variant="h6">Change survey status</Typography>
                    </FormLabel>
                    <FormControl variant="outlined">
                      <Select
                        native
                        value={surveyStatus}
                        style={{ marginBottom: 10, height: 40 }}
                        onChange={handleSurveyStatusChange}
                        inputProps={{
                          name: 'surveystatus',
                          id: 'surveystatus',
                        }}>
                        {user &&
                          statuses[user.role].map((id, ind) => (
                            <option
                              key={ind}
                              aria-label={String(id)}
                              value={id}>
                              {id}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenLightOrHeavyDataDialog(true)}>
                      <CloudDownloadIcon style={{ marginRight: 5 }} />
                      Download survey
                    </Button>
                  </div>
                </div>
              </Grid>
            </>
          )}
      </Grid>

      <DialogLightAndHeavy
        openLightOrHeavyDataDialog={openLightOrHeavyDataDialog}
        setOpenLightOrHeavyDataDialog={setOpenLightOrHeavyDataDialog}
        setOpenEncryptionDialog={setOpenEncryptionDialog}
        enteredEncryptionKey={enteredEncryptionKey}
        lightOrHeavyDataModel={lightOrHeavyDataModel}
        setLightOrHeavyDataModel={setLightOrHeavyDataModel}
        downloadSurveys={downloadSurvey}
      />
      <Dialog
        open={openEncryptionDialog}
        onClose={() => {
          setOpenEncryptionDialog(false)
        }}
        aria-labelledby="Download">
        <DialogTitle id="delete-dialog-title">Download</DialogTitle>
        <DialogContent>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <Typography>
                To download a decrypted version of the survey(s), you must enter
                the decrypt key and agree to the Terms and Conditions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ marginTop: 10 }}>
                Upload the decrypt key
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <input
                  //className={classes.input}
                  style={{ display: 'none' }}
                  id="encryptionupload"
                  type="file"
                  onChange={(evt) => {
                    if (
                      evt &&
                      evt.target &&
                      evt.target.files &&
                      evt.target.files.length
                    ) {
                      setFileModel(evt.target.files[0])
                    }
                  }}
                />
                <label htmlFor="encryptionupload">
                  <Button variant="contained" color="primary" component="span">
                    {fileModel && `Choose a different file`}
                    {!fileModel && `Choose file`}
                  </Button>
                </label>
                {fileModel && (
                  <Typography>File chosen: {fileModel.name}</Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!fileModel}
            type="submit"
            variant="contained"
            onClick={async () => {
              setOpenEncryptionDialog(false)
              const objectUrl = URL.createObjectURL(fileModel)
              const keyContents = await fetch(objectUrl).then((res) =>
                res.text(),
              )
              dispatch(actions.enteredEncryptionKey({ key: keyContents }))
              if (!TCAcceptance) {
                setOpenTCDialog(true)
              } else {
                if (previewWaiting) {
                  history.push('/survey/attempt/preview', {
                    surveyId,
                    testId: previewWaiting.testId,
                    attemptId: previewWaiting.attemptId,
                    privateKeyFile: URL.createObjectURL(fileModel),
                  })
                  setPreviewWaiting(false)
                } else {
                  downloadSurvey()
                }
              }
            }}
            color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <DialogTermsAndConditions
        openTCDialog={openTCDialog}
        setOpenTCDialog={setOpenTCDialog}
        TCAccepted={TCAccepted}
        setTCAccepted={setTCAccepted}
        acceptanceButtonText={'Download'}
        onDownload={() => {
          if (TCAccepted) {
            dispatch(actions.acceptedTermsAndConditions())
            if (user === null) return
            logUserAction(token, {
              username: user.username,
              action: `Accepted Terms & Conditions`,
            })
            setOpenTCDialog(false)
            if (previewWaiting) {
              history.push('/survey/attempt/preview', {
                surveyId,
                testId: previewWaiting.testId,
                attemptId: previewWaiting.attemptId,
              })
              setPreviewWaiting(false)
            } else {
              downloadSurvey()
            }
          }
        }}
      />
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus === 'success' ? 'success' : 'error'}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : snackbarStatus}
        </MuiAlert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isDownloading}>
        <CircularProgress size={50} />
      </Backdrop>
    </AdminPage>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  addnew: {
    textAlign: 'right',
    padding: 12,
  },
  deleteButton: {
    marginRight: 10,
  },
  attemptCol: {
    textAlign: 'center',
  },
  mainCol: {
    minWidth: 100,
  },
  exportButton: {
    width: 180,
    height: 40,
    margin: 10,
  },
  statusColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 10,
  },
  backdrop: {
    zIndex: 100,
    color: '#fff',
  },
})
