import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiAlert from '@material-ui/lab/Alert'
import retry from 'async-retry'
import moment from 'moment'
import { ReEncryptionDialog } from 'src/components/ReEncryptionDialog'
import { useSubscriber } from 'src/hooks/useSubscriber'
import { DialogEncryptionKeyUpload } from 'src/pages/SurveyResults/DialogEncryptionKeyUpload'
import { DialogLightAndHeavy } from 'src/pages/SurveyResults/DialogLightAndHeavy'
import { DialogTermsAndConditions } from 'src/pages/SurveyResults/DialogTermsAndConditions'
import { FilterControlsAndDialog } from 'src/pages/SurveyResults/FilterControlsAndDialog'
import { KeepRemoveButtons } from 'src/pages/SurveyResults/KeepRemoveButtons'
import { SelectAllChildren } from 'src/pages/SurveyResults/SelectAllChildren'
import {
  testLongNames,
  testShortNames,
} from 'src/pages/SurveyResults/staticValues'
import { SurveyCheckItem } from 'src/pages/SurveyResults/SurveyCheckItem'
import { surveyDownloadDataManager } from 'src/pages/SurveyResults/surveyDownloadDataManager'
import {
  SurveyButtonEvent,
  SurveyResultEvent,
  TestsRecord,
} from 'src/pages/SurveyResults/types'
import * as actions from 'src/redux/actions'
import * as selectors from 'src/redux/selectors'
import { Survey, SurveyFilters } from 'src/types'

import { env } from '../env'
import {
  deleteSurveys,
  fetchSurveys,
  flagSurveyRemoval,
  logUserAction,
  makeDownloadRequest,
  pollDownloadRequest,
} from '../services/Api'
import { AdminPage } from './AdminPage'
const baseUrl = env.apiBaseUrl

export const SurveyResults = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { innerHeight: height } = window
  const token = useSelector(selectors.getToken)
  const isCurrentlyReEncrypting = useSelector(selectors.getIsReEncryptingStatus)
  const TCAcceptance = useSelector(selectors.getTCAcceptance)
  const enteredEncryptionKey = useSelector(selectors.getEncryptionKey)
  const classes = useStyles()
  const downloadId = React.useRef<string | null>(null)
  const surveyIdsForDownload = React.useRef<Record<string, TestsRecord>>({})
  const [isDownloading, setIsDownloading] = React.useState(false)

  const user = useSelector(selectors.getUser)
  const { publishEvent, subscribe } = useSubscriber<SurveyResultEvent>()
  const {
    publishEvent: publishButtonEvent,
    subscribe: subscribeButtonEvent,
  } = useSubscriber<SurveyButtonEvent>()

  const [fileModel, setFileModel] = React.useState<any>()
  const [
    openLightOrHeavyDataDialog,
    setOpenLightOrHeavyDataDialog,
  ] = React.useState(false)
  const [openEncryptionDialog, setOpenEncryptionDialog] = React.useState(false)
  const [TCAccepted, setTCAccepted] = React.useState(TCAcceptance || false)
  const [openTCDialog, setOpenTCDialog] = React.useState(false)
  const [lightOrHeavyDataModel, setLightOrHeavyDataModel] = React.useState<
    'heavy' | 'light' | 'both'
  >('both')

  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const [showChwIdError, setShowChwIdError] = React.useState(false)

  const [surveys, setSurveys] = React.useState<Survey[]>([])
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [showReEncryptModal, setShowReEncryptModal] = React.useState(false)
  const [isKeyUploadModalVisible, setIsKeyUploadModalVisible] = React.useState(
    false,
  )
  const [searching, setSearching] = React.useState(false)

  const surveysFlaggedForRemoval = React.useRef<string[]>([])

  const downloadSurveys = async () => {
    if (!token) return
    setIsDownloading(true)
    try {
      const resp = await makeDownloadRequest(token, {
        storedKey: enteredEncryptionKey,
        surveyIds: Object.keys(surveyIdsForDownload.current),
        testsRequested: surveyIdsForDownload.current,
        dataModel: lightOrHeavyDataModel,
      })
      retry(
        async () => {
          downloadId.current = resp.data
          const response = await pollDownloadRequest(token, downloadId.current)
          if (response.status === 202) {
            window.open(
              `${baseUrl}/download/id/${downloadId.current}?token=${token}`,
            )
            if (user === null) return
            const surveyIDs = Object.keys(surveyIdsForDownload.current)
            let childIDs: string[] = []
            surveyIDs.forEach((surveyID) => {
              const surveyItem = surveys.find(
                (item) => item.surveyId === surveyID,
              )
              if (surveyItem && !childIDs.includes(surveyItem.childId)) {
                childIDs.push(surveyItem.childId)
              }
            })
            logUserAction(token, {
              username: user.username,
              action: `Downloaded ${surveyIDs.length} ${
                surveyIDs.length === 1 ? 'survey' : 'surveys'
              }, for  ${
                childIDs.length === 1 ? 'child' : 'children'
              } ${childIDs.join(', ')}`,
            })
          }
        },
        {
          maxTimeout: 1000,
          retries: 30,
        },
      )
    } catch (e) {
      setSnackbarStatus('error')
    }
    setIsDownloading(false)
  }

  const getSurveys = async (filters: SurveyFilters | {}) => {
    if (!token) return
    try {
      const resp = await fetchSurveys(token, filters)
      if (resp.data) {
        setSearching(false)
        setShowChwIdError(false)
        setSurveys(sortSurveysInDateOrder(resp.data))
      }
    } catch (err) {
      console.log(err)
      setShowChwIdError(true)
      setSearching(false)
      setSnackbarStatus('error')
    }
  }

  const flagSurveyForRemoval = async (surveyId: string, value: boolean) => {
    if (token) {
      flagSurveyRemoval(token, {
        surveyId,
        removalStatus: value,
      }).catch(() => {
        setSnackbarStatus('error')
      })
    }
  }

  const storedFilterState = useSelector(selectors.getSurveyFilters)

  const removeSurveys = async () => {
    if (!token) return
    const surveyIDsToDelete = surveysFlaggedForRemoval.current
    try {
      await deleteSurveys(token, {
        surveyIds: surveyIDsToDelete,
      })
      if (user === null) return
      let childIDs: string[] = []
      surveyIDsToDelete.forEach((surveyID) => {
        const surveyItem = surveys.find((item) => item.surveyId === surveyID)
        if (surveyItem && !childIDs.includes(surveyItem.childId)) {
          childIDs.push(surveyItem.childId)
        }
      })
      logUserAction(token, {
        username: user.username,
        action: `Deleted ${surveyIDsToDelete.length} ${
          surveyIDsToDelete.length === 1 ? 'survey' : 'surveys'
        }, for ${childIDs.length === 1 ? 'child' : 'children'} ${childIDs.join(
          ', ',
        )}`,
      })
      publishEvent({ type: 'deselect-all' })
      getSurveys(storedFilterState)
    } catch (e) {
      console.log('REMOVE SURVEY ERROR', e)
      setSnackbarStatus('error')
    }
  }

  const viewSurvey = async (surveyId: string, surveyDate: string) => {
    history.push('/survey/view', { surveyId, surveyDate })
  }

  useEffect(() => {
    getSurveys(storedFilterState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    return subscribe(onSelectionEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys])

  const onSelectionEvent = (event: SurveyResultEvent) => {
    surveyDownloadDataManager({
      event,
      surveys,
      surveysFlaggedForRemoval,
      surveyIdsForDownload,
    })
    publishButtonEvent({
      type: 'delete-button',
      payload: { value: surveysFlaggedForRemoval.current.length !== 0 },
    })
    publishButtonEvent({
      type: 'download-button',
      payload: {
        value: Object.keys(surveyIdsForDownload.current).length !== 0,
      },
    })
  }

  return (
    <AdminPage title="Survey list">
      <Grid container spacing={3} style={{ maxWidth: '100%' }}>
        <Grid item xs={6}>
          <Typography variant="body1">
            Search for a survey by typing the child unique ID, Apply and combine
            filters, View and Download collected data.
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.addnew}>
          <div
            style={{ margin: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              style={{ alignContent: 'center' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setIsKeyUploadModalVisible(true)
              }}>
              Select Encryption key
            </Button>
            <FilterControlsAndDialog
              searching={searching}
              setSearching={setSearching}
              showChwIdError={showChwIdError}
              getSurveys={getSurveys}
              deselectAll={() => publishEvent({ type: 'deselect-all' })}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container style={{ maxWidth: '100%' }} item xs={12}>
        <TableContainer
          component={Paper}
          className={classes.table}
          style={{ maxHeight: 0.7 * height }}>
          <Table stickyHeader aria-label="App">
            <TableHead className={classes.tableHeader}>
              <TableRow className={classes.tableRow} style={{ height: 100 }}>
                <TableCell className={classes.cellWithLRBorder}>
                  <SelectAllChildren
                    publishEvent={publishEvent}
                    subscribe={subscribe}
                    user={user}
                  />
                </TableCell>
                <TableCell className={classes.cell} style={{ minWidth: 150 }}>
                  <strong>Child ID</strong>
                </TableCell>
                <TableCell className={classes.cellWithLRBorder}>
                  <strong>Date of Survey Upload</strong>
                </TableCell>
                {testShortNames.map((shortName, ind) => (
                  <Tooltip
                    key={`shortname-${shortName}-${ind}`}
                    title={testLongNames[shortName]}
                    placement="top">
                    <TableCell
                      className={[classes.cell, classes.taskColumn].join(' ')}>
                      <div>{shortName}</div>
                      <SurveyCheckItem
                        subscribe={subscribe}
                        onChange={(oldVal) => {
                          if (!oldVal) {
                            publishEvent({
                              type: 'select-column',
                              payload: { name: shortName },
                            })
                            return
                          }
                          publishEvent({
                            type: 'deselect-column',
                            payload: { name: shortName },
                          })
                        }}
                        disabled={user ? user.role === 'admin' : false}
                        name={`${shortName}`}
                      />
                    </TableCell>
                  </Tooltip>
                ))}
                <TableCell className={classes.cellCentredWithLRBorder}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys.map((row, rowIndex) => {
                return (
                  <React.Fragment key={`${row.surveyId}-${rowIndex}`}>
                    <TableRow className={classes.tableRow} key={rowIndex}>
                      <TableCell className={classes.cellWithLRBorder}>
                        <SurveyCheckItem
                          onChange={(oldVal) => {
                            if (!oldVal) {
                              return publishEvent({
                                type: 'select-row',
                                payload: { id: row.surveyId },
                              })
                            }
                            return publishEvent({
                              type: 'deselect-row',
                              payload: { id: row.surveyId },
                            })
                          }}
                          subscribe={subscribe}
                          disabled={user ? user.role === 'admin' : false}
                          name={row.surveyId}
                        />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {`${row.childId}`}
                      </TableCell>
                      <TableCell className={classes.cellWithLRBorder}>
                        {moment(row.dateCreated)
                          .utc()
                          .format('DD/MM/YYYY h:mm A')}
                      </TableCell>
                      {testShortNames.map((shortName, ind) => (
                        <TableCell
                          key={`$shortNameTable-${ind}`}
                          className={classes.cell}>
                          <SurveyCheckItem
                            subscribe={subscribe}
                            onChange={(oldVal) => {
                              publishEvent({
                                type: 'update-individual-item',
                                payload: {
                                  surveyId: row.surveyId,
                                  name: shortName,
                                  value: !oldVal,
                                },
                              })
                            }}
                            id={row.surveyId}
                            name={shortName}
                          />
                        </TableCell>
                      ))}
                      <TableCell className={classes.cellCentredWithLRBorder}>
                        <Button
                          color="primary"
                          onClick={() => {
                            viewSurvey(row.surveyId, row.dateCreated)
                          }}>
                          View
                        </Button>
                        {user &&
                          (user.role === 'superAdmin' ||
                            user.role === 'backendResearcher') && (
                            <KeepRemoveButtons
                              flagged={row.removal}
                              onClick={(value) => {
                                flagSurveyForRemoval(row.surveyId, value)
                              }}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container spacing={3} style={{ maxWidth: '100%' }}>
        <Grid item={true} xs={12} className={classes.addnew}>
          {user && user.role === 'superAdmin' && (
            <Button
              variant="contained"
              color="default"
              disabled={isCurrentlyReEncrypting}
              onClick={() => setShowReEncryptModal(true)}
              className={classes.buttonMargin}>
              {isCurrentlyReEncrypting && (
                <DeleteIcon style={{ marginRight: 5 }} />
              )}
              {isCurrentlyReEncrypting ? 'Busy' : 'Re-encrypt all surveys'}
            </Button>
          )}
          {user && user.role === 'superAdmin' && (
            <DeleteSurveysButton
              subscribe={subscribeButtonEvent}
              onClick={() => setShowDeleteModal(true)}
            />
          )}
          {user &&
            (user.role === 'superAdmin' ||
              user.role === 'backendResearcher') && (
              <DownloadButton
                subscribe={subscribeButtonEvent}
                onClick={() => setOpenLightOrHeavyDataDialog(true)}
              />
            )}
        </Grid>
      </Grid>
      <ReEncryptionDialog
        isVisible={showReEncryptModal}
        setIsVisible={setShowReEncryptModal}
      />
      <DialogEncryptionKeyUpload
        openEncryptionDialog={isKeyUploadModalVisible}
        setOpenEncryptionDialog={setIsKeyUploadModalVisible}
      />
      <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <DialogTitle id="delete-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete these surveys permanently?
          </DialogContentText>
          <DialogContentText
            style={{ color: 'red', fontSize: 12, fontStyle: 'italic' }}>
            Note: If the survey deleted is the last one pertaining to a given
            child that child in question will be removed from the database.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              removeSurveys()
              setShowDeleteModal(false)
            }}
            color="secondary">
            Delete permanently
          </Button>
          <Button onClick={() => setShowDeleteModal(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <DialogLightAndHeavy
        openLightOrHeavyDataDialog={openLightOrHeavyDataDialog}
        setOpenLightOrHeavyDataDialog={setOpenLightOrHeavyDataDialog}
        setOpenEncryptionDialog={setOpenEncryptionDialog}
        enteredEncryptionKey={enteredEncryptionKey}
        lightOrHeavyDataModel={lightOrHeavyDataModel}
        setLightOrHeavyDataModel={setLightOrHeavyDataModel}
        downloadSurveys={downloadSurveys}
      />
      <Dialog
        open={openEncryptionDialog}
        onClose={() => {
          setOpenEncryptionDialog(false)
        }}
        aria-labelledby="Download">
        <DialogTitle id="delete-dialog-title">Download</DialogTitle>
        <DialogContent>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <Typography>
                To download a decrypted version of the survey(s), you must enter
                the decrypt key and agree to the Terms and Conditions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ marginTop: 10 }}>
                Upload the decrypt key
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <input
                  style={{ display: 'none' }}
                  id="encryptionupload"
                  type="file"
                  onChange={(evt) => {
                    if (
                      evt &&
                      evt.target &&
                      evt.target.files &&
                      evt.target.files.length
                    ) {
                      setFileModel(evt.target.files[0])
                    }
                  }}
                />
                <label htmlFor="encryptionupload">
                  <Button variant="contained" color="primary" component="span">
                    {fileModel && `Choose a different file`}
                    {!fileModel && `Choose file`}
                  </Button>
                </label>
                {fileModel && (
                  <Typography>File chosen: {fileModel.name}</Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!fileModel}
            type="submit"
            variant="contained"
            onClick={async () => {
              setOpenEncryptionDialog(false)
              const objectUrl = URL.createObjectURL(fileModel)
              const keyContents = await fetch(objectUrl).then((res) =>
                res.text(),
              )
              dispatch(actions.enteredEncryptionKey({ key: keyContents }))
              if (!TCAcceptance) {
                setOpenTCDialog(true)
              } else {
                downloadSurveys()
              }
            }}
            color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <DialogTermsAndConditions
        openTCDialog={openTCDialog}
        setOpenTCDialog={setOpenTCDialog}
        TCAccepted={TCAccepted}
        setTCAccepted={setTCAccepted}
        acceptanceButtonText={'Download'}
        onDownload={() => {
          if (TCAccepted) {
            setOpenTCDialog(false)
            downloadSurveys()
            if (user === null) return
            logUserAction(token, {
              username: user.username,
              action: `Accepted Terms & Conditions`,
            })
            dispatch(actions.acceptedTermsAndConditions())
          }
        }}
      />
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isDownloading}>
        <CircularProgress size={50} />
      </Backdrop>
    </AdminPage>
  )
}

const sortSurveysInDateOrder = (surveys: Survey[]) => {
  const sortedSurveys = surveys.sort((a, b) =>
    a.dateCreated < b.dateCreated ? 1 : b.dateCreated < a.dateCreated ? -1 : 0,
  )
  return sortedSurveys
}

const DeleteSurveysButton = ({
  subscribe,
  onClick,
}: {
  subscribe: (listener: (event: SurveyButtonEvent) => void) => () => void
  onClick: () => void
}) => {
  const [buttonEnabled, setButtonEnabled] = React.useState(false)

  const onItemEvent = (event: SurveyButtonEvent) => {
    if (event.type === 'delete-button') {
      setButtonEnabled(event.payload.value)
    }
  }

  React.useEffect(() => {
    return subscribe(onItemEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button
      variant="contained"
      color="secondary"
      disabled={!buttonEnabled}
      onClick={onClick}
      style={{ marginRight: 10 }}>
      <DeleteIcon style={{ marginRight: 5 }} />
      Delete permanently
    </Button>
  )
}

const DownloadButton = ({
  subscribe,
  onClick,
}: {
  subscribe: (listener: (event: SurveyButtonEvent) => void) => () => void
  onClick: () => void
}) => {
  const [buttonEnabled, setButtonEnabled] = React.useState(false)

  const onItemEvent = (event: SurveyButtonEvent) => {
    if (event.type === 'download-button') {
      setButtonEnabled(event.payload.value)
    }
  }

  React.useEffect(() => {
    return subscribe(onItemEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button
      disabled={!buttonEnabled}
      variant="contained"
      color="primary"
      onClick={onClick}>
      <CloudDownloadIcon style={{ marginRight: 5 }} />
      Download
    </Button>
  )
}

const useStyles = makeStyles({
  table: {
    marginTop: 25,
    marginBottom: 50,
    borderRadius: 0,
  },
  addnew: {
    textAlign: 'right',
    padding: 12,
  },
  horizontalScroll: {
    overflowX: 'scroll',
    maxWidth: 300,
  },
  tableHeader: {
    height: 100,
  },
  taskColumn: {
    textAlign: 'center',
  },
  tableRow: {
    height: 66,
  },
  cell: {
    padding: 11,
  },
  cellWithLRBorder: {
    padding: 11,
    borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
  },
  cellCentredWithLRBorder: {
    padding: 11,
    textAlign: 'center',
    borderLeft: '1px solid rgba(0, 0, 0, 0.08)',
    borderRight: '1px solid rgba(0, 0, 0, 0.08)',
  },
  buttonMargin: {
    marginRight: 10,
  },
  backdrop: {
    zIndex: 100,
    color: '#fff',
  },
})
