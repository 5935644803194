import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import * as selectors from 'src/redux/selectors'
import {
  editCustomFields,
  fetchCustomFields,
  getAvailableQuestionIds,
} from 'src/services/Api'

import { VideoContainer } from '../components/VideoContainer'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const AddParentAssessmentQuestion = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const token = useSelector(selectors.getToken)
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const [englishQuestion, setEnglishQuestion] = React.useState<string>('')
  const [hindiQuestion, setHindiQuestion] = React.useState<string>('')
  const [chichewaQuestion, setChichewaQuestion] = React.useState<string>('')
  const [fetchedQuestionIds] = React.useState<boolean>(false)
  const [maxError, setMaxError] = React.useState<boolean>(false)
  const [addingAnswer, setAddingAnswer] = React.useState<boolean>(false)
  const [questionIdModel, setQuestionIdModel] = React.useState<number>()
  const [answers, setAnswers] = React.useState<string[]>([])
  const [answerModel, setAnswerModel] = React.useState<string>('')
  const [questionTypeModel, setQuestionTypeModel] = React.useState<string>(
    'multiple-choice',
  )
  const [videoFileChosen, setVideoFileChosen] = React.useState<boolean>(false)
  const [editedQuestion, setEditedQuestion] = React.useState<any>(false)

  const videos = {
    video: {
      id: `parentAssessmentQuestionVideo${questionIdModel}`,
      title: 'Video (optional)',
      file: `parent-assessment-question-video-${questionIdModel}`,
    },
  }

  const availableIds = (editedQuestionId?: any) => {
    getAvailableQuestionIds(token, 'parentAssessment').then((result) => {
      if (result.data.success) {
        if (editedQuestionId !== undefined) {
          setQuestionIdModel(editedQuestionId)
        } else {
          setQuestionIdModel(result.data.availableIds[0])
        }
      } else {
        setMaxError(true)
      }
    })
  }

  const addAnswerField = () => {
    setAddingAnswer(true)
  }

  const saveAnswer = () => {
    let newAnswers = answers
    newAnswers.push(answerModel)
    setAnswers(newAnswers)
    setAddingAnswer(false)
    setAnswerModel('')
  }

  const deleteAnswer = (key: number) => {
    let newAnswers = answers.filter((answer, id) => {
      if (id === key) {
        return false
      }
      return true
    })
    setAnswers(newAnswers)
  }

  const handleQuestionTypeChange = (evt: any) => {
    setQuestionTypeModel(evt.target.value)
  }

  const saveQuestion = () => {
    fetchCustomFields(token, 'parentAssessment').then((resp: any) => {
      if (resp.data.fields) {
        const parsedFields = JSON.parse(resp.data.fields)
        const { questions: fetchedQuestions } = parsedFields
        let newQuestions: any[] = []
        if (fetchedQuestions) {
          newQuestions = fetchedQuestions
        }
        if (editedQuestion !== false && fetchedQuestions) {
          newQuestions = fetchedQuestions.filter((question) => {
            if (question.question_id === editedQuestion) {
              return false
            }
            return true
          })
        }
        if (videoFileChosen) {
          newQuestions.push({
            question_id: questionIdModel,
            question_type: questionTypeModel,
            question_en: englishQuestion,
            question_hi: hindiQuestion,
            question_ch: chichewaQuestion,
            video: videos.video.file,
            last_updated: new Date(),
            answers,
          })
        } else {
          newQuestions.push({
            question_id: questionIdModel,
            question_type: questionTypeModel,
            question_en: englishQuestion,
            question_hi: hindiQuestion,
            question_ch: chichewaQuestion,
            last_updated: new Date(),
            answers,
          })
        }
        editCustomFields(token, {
          fields: JSON.stringify({
            questions: newQuestions,
          }),
          testId: 'parentAssessment',
        })
          .then((result) => {
            if (result.data.success) {
              setSnackbarStatus('success')

              history.push(`/parent-assessment-questions`, {
                showSuccess: true,
              })
            } else {
              setSnackbarStatus('error')
            }
          })
          .catch(() => {
            setSnackbarStatus('error')
          })
      }
    })
  }

  useEffect(() => {
    const { state }: any = location
    if (state) {
      const { question }: any = location.state
      if (question) {
        setEditedQuestion(question.question_id)
        setQuestionTypeModel(question.question_type)
        setEnglishQuestion(question.question_en)
        setChichewaQuestion(question.question_ch)
        setHindiQuestion(question.question_hi)
        setAnswers(question.answers)
        availableIds(question.question_id)
      }
    } else {
      if (!fetchedQuestionIds) {
        availableIds()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AdminPage
      title={editedQuestion === false ? 'Add New Question' : 'Edit Question'}>
      <Grid container className={classes.root} spacing={2}>
        {maxError && (
          <Grid xs={12} item>
            <Alert severity="error">
              You have added the maximum number of questions. Please delete a
              question to add a new one.
            </Alert>
          </Grid>
        )}
        {/* <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="questionid">
            <Typography variant="h6">Question ID</Typography>
          </FormLabel>
          <FormControl variant="outlined">
            <Select
              native
              value={questionIdModel}
              disabled={true}
              onChange={handleQuestionIdChange}
              inputProps={{
                name: 'questionid',
                id: 'questionid',
              }}>
              {questionIds.map((id) => (
                <option aria-label={String(id)} value={id}>
                  {id}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="question_en">
            <Typography variant="h6">English Question</Typography>
          </FormLabel>
          <FormControl style={{ width: '100%' }}>
            <TextField
              key="question_en"
              id="question_en"
              name="question_en"
              value={englishQuestion}
              onChange={(evt: any) => {
                setEnglishQuestion(evt.target.value)
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="question_hi">
            <Typography variant="h6">Hindi Question</Typography>
          </FormLabel>
          <FormControl style={{ width: '100%' }}>
            <TextField
              key="question_hi"
              id="question_hi"
              name="question_hi"
              value={hindiQuestion}
              onChange={(evt: any) => {
                setHindiQuestion(evt.target.value)
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="question_ch">
            <Typography variant="h6">Chichewa Question</Typography>
          </FormLabel>
          <FormControl style={{ width: '100%' }}>
            <TextField
              key="question_ch"
              id="question_ch"
              name="question_ch"
              value={chichewaQuestion}
              onChange={(evt: any) => {
                setChichewaQuestion(evt.target.value)
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {/* <FormLabel style={{ paddingBottom: 10 }}>
            <Typography variant="h6">Video (optional)</Typography>
          </FormLabel> */}
          <Grid container spacing={2}>
            <Grid md={6} item>
              {questionIdModel && (
                <VideoContainer
                  video={videos.video}
                  deleteButton
                  showDummyBeforeUpload={
                    editedQuestion === false ? !videoFileChosen : false
                  }
                  // onFileChosen={(file) => {
                  //   setVideoFileChosen(true)
                  // }}
                  uploadCallback={() => {
                    setVideoFileChosen(true)
                    // history.push(`/parent-assessment-questions`, {
                    //   showSuccess: true,
                    // })
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="questiontype">
            <Typography variant="h6">Question type</Typography>
          </FormLabel>
          <FormControl variant="outlined">
            <Select
              native
              value={questionTypeModel}
              onChange={handleQuestionTypeChange}
              inputProps={{
                name: 'questiontype',
                id: 'questiontype',
              }}>
              <option aria-label="multiple choice" value="multiple-choice">
                Multiple choice
              </option>
              <option aria-label="open" value="open">
                Open
              </option>
            </Select>
          </FormControl>
        </Grid>
        {questionTypeModel === 'multiple-choice' && (
          <Grid xs={12} item style={{ paddingBottom: 30 }}>
            <FormLabel>
              <Typography variant="h6">Question answers</Typography>
              <Typography variant="subtitle1">
                Type an answer and press enter to register it. Press + to add a
                new answer.
              </Typography>
            </FormLabel>
            <div style={{ height: 20 }} />
            {answers.map((answer, key) => (
              <FormControl
                key={key}
                style={{
                  width: '60%',
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 30,
                }}>
                <TextField
                  style={{ flex: 1, marginRight: 20 }}
                  key={`answer_${key}`}
                  id={`answer_${key}`}
                  name={`answer_${key}`}
                  value={answers[key]}
                  inputProps={{
                    readOnly: true,
                  }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    style={{
                      display: 'block',
                      width: 30,
                      height: 30,
                      minWidth: 0,
                      padding: 0,
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteAnswer(key)}>
                    x
                  </Button>
                </div>
              </FormControl>
            ))}
            {!addingAnswer && answers.length < 10 && (
              <FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    display: 'block',
                    width: 40,
                    height: 40,
                    minWidth: 0,
                    padding: 0,
                  }}
                  onClick={() => addAnswerField()}>
                  +
                </Button>
              </FormControl>
            )}
            {addingAnswer && (
              <React.Fragment>
                <Grid container spacing={2} style={{ marginBottom: 30 }}>
                  <Grid item xs={12}>
                    <FormControl style={{ width: '60%' }}>
                      <TextField
                        key="unsaved_answer"
                        id="unsaved_answer"
                        name="unsaved_answer"
                        autoFocus
                        value={answerModel}
                        onKeyUp={(event) => {
                          if (event.keyCode === 13) saveAnswer()
                        }}
                        onChange={(evt: any) => {
                          setAnswerModel(evt.target.value)
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormControl>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={saveAnswer}>
                        Save answer
                      </Button>
                    </FormControl>
                  </Grid> */}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        )}
        <Grid xs={12} item>
          <FormControl>
            <Button variant="contained" color="primary" onClick={saveQuestion}>
              Save
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </Alert>
      </Snackbar>
    </AdminPage>
  )
}
