import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'

import './App.css'
import { configureStore } from './redux/store'
import { Routing } from './Routing'

const App = () => {
  const { store } = configureStore()
  return (
    <ReduxProvider store={store}>
      <CssBaseline>
        <Routing />
      </CssBaseline>
    </ReduxProvider>
  )
}

export default App
