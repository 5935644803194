import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
} from '@material-ui/core'
import * as actions from 'src/redux/actions'
import * as selectors from 'src/redux/selectors'

import { checkEncryptionKeyIsCorrect } from '../../services/Api'

export const DialogEncryptionKeyUpload = ({
  openEncryptionDialog,
  setOpenEncryptionDialog,
}) => {
  const dispatch = useDispatch()
  const token = useSelector(selectors.getToken)

  const [fileModel, setFileModel] = React.useState<any>()
  const [wrongKeyWarningVisible, setWrongKeyWarningVisible] = React.useState(
    false,
  )

  return (
    <Dialog
      open={openEncryptionDialog}
      onClose={() => {
        setOpenEncryptionDialog(false)
      }}
      aria-labelledby="Download">
      <DialogTitle id="delete-dialog-title">Preview Survey Data</DialogTitle>
      <DialogContent>
        <Grid container justify="space-around">
          <Grid item xs={12}>
            <Typography>
              To view a decrypted version of the survey data, you must enter the
              decrypt key and agree to the Terms and Conditions.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>
              Upload the decrypt key
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
              <input
                //className={classes.input}
                style={{ display: 'none' }}
                id="encryptionupload"
                type="file"
                onChange={(evt) => {
                  if (
                    evt &&
                    evt.target &&
                    evt.target.files &&
                    evt.target.files.length
                  ) {
                    setFileModel(evt.target.files[0])
                  }
                }}
              />
              <label htmlFor="encryptionupload">
                <Button variant="contained" color="primary" component="span">
                  {fileModel && `Choose a different file`}
                  {!fileModel && `Choose file`}
                </Button>
              </label>
              {fileModel && (
                <Typography>File chosen: {fileModel.name}</Typography>
              )}
              {wrongKeyWarningVisible && (
                <Typography style={{ color: 'red' }}>
                  That doesn't seem to be the correct key
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!fileModel}
          type="submit"
          variant="contained"
          onClick={async () => {
            //

            const objectUrl = URL.createObjectURL(fileModel)
            const keyContents = await fetch(objectUrl).then((res) => res.text())
            const resp = await checkEncryptionKeyIsCorrect(token, {
              privateKey: keyContents,
            })
            if (resp?.data?.isCorrect) {
              setWrongKeyWarningVisible(false)
              setOpenEncryptionDialog(false)
              dispatch(actions.enteredEncryptionKey({ key: keyContents }))
              setOpenEncryptionDialog(false)
              return
            }
            setWrongKeyWarningVisible(true)
          }}
          color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
