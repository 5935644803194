import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  Paper,
  Snackbar,
  Theme,
  Typography,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import MuiAlert from '@material-ui/lab/Alert'
import { env } from 'src/env'
import * as selectors from 'src/redux/selectors'
import {
  deleteAssessmentContent,
  uploadAssessmentContent,
} from 'src/services/Api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flex: 1,
      minHeight: 100,
      minWidth: 200,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    supportedTypes: {
      paddingTop: 4,
      fontSize: 12,
      color: '#6d6d6d',
    },
  }),
)

type VideoContainerProps = {
  video: {
    id: string
    title: string | undefined
    file: string
  }
  deleteButton?: boolean
  shouldUpload?: boolean
  uploadCallback?: () => void
  showVideoPreview?: boolean
  onFileChosen?: (file: any) => void
  showDummyBeforeUpload?: boolean
  customMinHeight?: number | 'auto'
  style?: any
}

export const VideoContainer = ({
  video,
  deleteButton = false,
  shouldUpload,
  uploadCallback,
  showVideoPreview, // if this is true, it will not show any video element
  onFileChosen,
  showDummyBeforeUpload, // if this is true, a video element will be shown but it will have dummy content before the first upload is made
  customMinHeight,
  style,
}: VideoContainerProps) => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)

  const videoRef1 = React.useRef<any>(null)
  const videoRef2 = React.useRef<any>(null)
  const [snackbarStatus, setSnackbarStatus] = React.useState<
    'success' | 'error' | undefined
  >(undefined)
  const [uploadProgress, setUploadProgress] = React.useState<
    undefined | number
  >(undefined)
  const [currentVideo, setCurrentVideo] = React.useState<any>()
  const formData = React.useRef<FormData>()
  const [fileChosen, setFileChosen] = React.useState<string>('')

  const updateProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    setUploadProgress(percentCompleted)
  }

  const uploadFile = (file) => {
    const data = new FormData()
    data.append('file', file)
    data.append('fileId', video.id)
    data.append('fileName', video.file)

    formData.current = data
    setFileChosen(file.name)

    if (onFileChosen) {
      onFileChosen(file)
    }

    if (shouldUpload === undefined || shouldUpload === true) {
      triggerUpload()
    }
  }

  const deleteFile = () => {
    deleteAssessmentContent(token, video.id)
      .then(() => {
        setSnackbarStatus('success')
      })
      .catch(() => {
        setSnackbarStatus('error')
      })
      .finally(() => {
        videoRef1.current?.load()
        videoRef2.current?.load()
      })
  }

  const triggerUpload = () => {
    if (formData.current) {
      uploadAssessmentContent(token, formData.current, updateProgress)
        .then(() => {
          setCurrentVideo(
            `${env.apiBaseUrl}/file-by-id/${
              video.id
            }?cache=${new Date().toString()}`,
          )
          videoRef1.current?.load()
          videoRef2.current?.load()
          setUploadProgress(undefined)
          setSnackbarStatus('success')
          if (uploadCallback) {
            uploadCallback()
          }
        })
        .catch(() => {
          setUploadProgress(undefined)
          setSnackbarStatus('error')
        })
    }
  }

  useEffect(() => {
    if (fileChosen && shouldUpload) {
      triggerUpload()
    }
    if (!currentVideo) {
      setCurrentVideo(
        `${env.apiBaseUrl}/file-by-id/${
          video.id
        }?cache=${new Date().toString()}`,
      )
      videoRef1.current?.load()
      videoRef2.current?.load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpload, video])

  return (
    <Paper className={classes.paper} {...(style && { style })}>
      <div className={classes.headerRow}>
        {deleteButton && (
          <Button
            style={{
              width: 30,
              height: 30,
              minWidth: 0,
              padding: 0,
            }}
            variant="contained"
            color="secondary"
            onClick={() => {
              deleteFile()
            }}>
            x
          </Button>
        )}
        <Typography component="h1" variant="subtitle1">
          {video.title ?? ''}
        </Typography>
        <label htmlFor={video.id}>
          <input
            disabled={uploadProgress !== undefined}
            onChange={(e) => {
              if (!e.target.files) return
              uploadFile(e.target.files[0])
            }}
            style={{ display: 'none' }}
            id={video.id}
            name={video.id}
            type="file"
            accept=".mp4"
          />

          <div style={{ float: 'right', textAlign: 'center' }}>
            <Button
              disabled={uploadProgress !== undefined}
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}>
              Upload
            </Button>
            <div className={classes.supportedTypes}>(mp4 only)</div>
          </div>
        </label>
      </div>
      {uploadProgress && (
        <LinearProgress variant="determinate" value={uploadProgress} />
      )}
      {showVideoPreview !== false && (
        <>
          <video
            width="100%"
            style={{
              maxHeight: 450,
              minHeight: customMinHeight ? customMinHeight : 450,
              display: showDummyBeforeUpload ? 'block' : 'none',
            }}
            controls
            ref={videoRef1}
            preload="metadata">
            <source src="placeholder.mp4" type="video/mp4" />
          </video>
          <video
            width="100%"
            style={{
              maxHeight: 450,
              minHeight: customMinHeight ? customMinHeight : 450,
              display: showDummyBeforeUpload ? 'none' : 'block',
            }}
            controls
            ref={videoRef2}
            preload="metadata">
            <source src={currentVideo} type="video/mp4" />
          </video>
        </>
      )}
      {showVideoPreview === false && fileChosen !== '' && (
        <div style={{ padding: 30 }}>File to be uploaded: {fileChosen}</div>
      )}
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </Paper>
  )
}
