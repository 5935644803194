import produce from 'immer'
import { Reducer } from 'src/redux/types'
import { SurveyFilters } from 'src/types'

const initialState: SurveyFilters = {
  gender: 'Any',
  dateOfBirth: null,
  ageStartYears: '',
  ageStartMonths: '',
  ageEndYears: '',
  ageEndMonths: '',
  diagnosis: 'Any',
  surveyStatus: 'Any',
  removalFlag: 'Any',
  country: 'Any',
  chwId: '',
  handedness: 'Any',
  childId: '',
  dataCollectionStart: null,
  dataCollectionEnd: null,
  deviceId: '',
}

export const surveysReducer: Reducer<SurveyFilters> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'SET_SURVEY_FILTERS':
      return produce(state, (draft) => {
        draft.gender = action.payload.gender
        draft.dateOfBirth = action.payload.dateOfBirth
        draft.ageStartYears = action.payload.ageStartYears
        draft.ageStartMonths = action.payload.ageStartMonths
        draft.ageEndYears = action.payload.ageEndYears
        draft.ageEndMonths = action.payload.ageEndMonths
        draft.diagnosis = action.payload.diagnosis
        draft.surveyStatus = action.payload.surveyStatus
        draft.removalFlag = action.payload.removalFlag
        draft.country = action.payload.country
        draft.chwId = action.payload.chwId
        draft.handedness = action.payload.handedness
        draft.childId = action.payload.childId
        draft.dataCollectionStart = action.payload.dataCollectionStart
        draft.dataCollectionEnd = action.payload.dataCollectionEnd
        draft.deviceId = action.payload.deviceId
      })
    default:
      return state
  }
}
