import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'
import { logUserAction } from 'src/services/Api'
import { getDateDiffInDays } from 'src/services/Utils'

import { fetchChild, updateChild } from '../services/Api'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles({
  formLabel: {
    fontSize: 18,
    marginTop: 20,
  },
})

export const EditChild = () => {
  const history = useHistory()
  const classes = useStyles()
  const token = useSelector(selectors.getToken)
  const authenticatedUser = useSelector(selectors.getUser)
  const [childId, setChildId] = React.useState<string>('')
  const [surveyId, setSurveyId] = React.useState<string>('')
  const [genderModel, setGenderModel] = React.useState<any>(undefined)
  const [countryModel, setCountryModel] = React.useState<any>(undefined)
  const [handednessModel, setHandednessModel] = React.useState<any>(undefined)
  const [weightModel, setWeightModel] = React.useState<string>('')
  const [heightModel, setHeightModel] = React.useState<string>('')
  const [heightByModel, setHeightByModel] = React.useState<any>()
  const [upperArmCircModel, setUpperArmCircModel] = React.useState<string>('')
  const [headCircModel, setHeadCircModel] = React.useState<string>('')
  const [diagnosisModel, setDiagnosisModel] = React.useState<string>('')
  const [diagnosisByModel, setDiagnosisByModel] = React.useState<string>('')
  const [dobModel, setDobModel] = React.useState<any>()
  const [showOtherGender, setShowOtherGender] = React.useState<boolean>(false)
  const [otherGenderModel, setOtherGenderModel] = React.useState<string>('')
  const [editedFields, setEditedFields] = React.useState<string[]>([])
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const countryOptions = [
    { label: '', value: undefined },
    { label: 'India', value: 'india' },
    { label: 'Malawi', value: 'malawi' },
  ]
  const handednessOptions = [
    { label: '', value: undefined },
    { label: 'Right', value: 'right' },
    { label: 'Left', value: 'left' },
    { label: 'Ambidextrous', value: 'ambidextrous' },
    { label: 'Unknown', value: 'unknown' },
  ]
  const [age, setAge] = React.useState<any>('?')

  const location = useLocation()

  const updateEditedFields = (field) => {
    const updatedFields = editedFields
    if (editedFields.indexOf(field) < 0) {
      updatedFields.push(field)
      setEditedFields(updatedFields)
    }
  }

  const save = () => {
    if (token) {
      updateChild(token, {
        childId,
        gender:
          genderModel !== 'male' && genderModel !== 'female'
            ? otherGenderModel
            : genderModel,
        birthDate: new Date(dobModel),
        country: countryModel,
        handedness: handednessModel,
        weight: weightModel,
        height: heightModel,
        heightBy: heightByModel,
        upperArmCircumference: upperArmCircModel,
        headCircumference: headCircModel,
        diagnosis: diagnosisModel,
        diagnosisBy: diagnosisByModel,
      })
        .then(() => {
          if (authenticatedUser === null) return

          logUserAction(token, {
            username: authenticatedUser.username,
            action: `Edited ${editedFields.join(', ')} of child ${childId}`,
          })
          setSnackbarStatus('success')
          history.push('/survey/view', {
            surveyId,
            showSuccess: 'success',
          })
        })
        .catch(() => {
          setSnackbarStatus('error')
        })
    }
  }

  useEffect(() => {
    const { state }: any = location
    if (state) {
      if (state.childId && token) {
        setChildId(state.childId)
        setSurveyId(state.surveyId)
        fetchChild(token, state.childId).then((resp: any) => {
          const { child } = resp.data

          setGenderModel(
            child.gender !== 'male' && child.gender !== 'female'
              ? 'other'
              : child.gender,
          )
          setCountryModel(child.country)
          setHandednessModel(child.handedness)
          setWeightModel(child.weight)
          setHeightModel(child.height)
          setHeightByModel(child.heightBy)
          setUpperArmCircModel(child.upperArmCircumference)
          setHeadCircModel(child.headCircumference)
          setDiagnosisModel(child.diagnosis)
          setDiagnosisByModel(child.diagnosisBy)
          setDobModel(moment(new Date(child.dateOfBirth)).format('YYYY-MM-DD'))
          setOtherGenderModel(
            child.gender !== 'male' && child.gender !== 'female'
              ? child.gender
              : '',
          )
          setAge(getDateDiffInDays(new Date(), new Date(child.dateOfBirth)))
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AdminPage title={`Edit child ${childId}`}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          {errorMessage !== false && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </Grid> */}
        <Grid item xs={12}>
          <form
            key="editchild"
            name="editchild"
            noValidate
            id="editchild"
            style={{ width: '100%' }}>
            <Typography className={classes.formLabel}>Gender</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={genderModel}
                onChange={(evt) => {
                  if (evt.target.value === 'other') {
                    setShowOtherGender(true)
                  }
                  updateEditedFields('gender')
                  setGenderModel(evt.target.value)
                }}>
                <FormControlLabel
                  value="female"
                  control={<Radio checked={genderModel === 'female'} />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio checked={genderModel === 'male'} />}
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={
                    <Radio
                      checked={
                        genderModel !== 'female' &&
                        genderModel !== 'male' &&
                        genderModel !== null &&
                        genderModel !== undefined
                      }
                    />
                  }
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            {(showOtherGender ||
              (otherGenderModel !== '' && otherGenderModel !== null)) && (
              <FormControl style={{ width: '100%', marginBottom: 5 }}>
                <TextField
                  key="othergender"
                  id="othergender"
                  name="othergender"
                  label="Other gender"
                  value={otherGenderModel}
                  onChange={(evt: any) => {
                    updateEditedFields('other gender')
                    setOtherGenderModel(evt.target.value)
                  }}
                  variant="outlined"
                />
              </FormControl>
            )}
            <Typography className={classes.formLabel}>Date of birth</Typography>
            <FormControl style={{ width: '100%', marginBottom: 5 }}>
              <TextField
                key="dob"
                id="dob"
                type="date"
                name="dob"
                value={dobModel}
                onChange={(evt: any) => {
                  updateEditedFields('date of birth')
                  setDobModel(evt.target.value)
                }}
                onBlur={() => {
                  // validateField(evt.target.value, setDobError)
                }}
                variant="outlined"
              />
              <Typography>Age = {age} days</Typography>
            </FormControl>
            <Typography className={classes.formLabel}>Country</Typography>
            <FormControl
              variant="outlined"
              style={{ display: 'flex', marginBottom: 25 }}>
              <Select
                native
                value={countryModel}
                style={{ marginBottom: 10, height: 40 }}
                onChange={(evt) => {
                  updateEditedFields('country')
                  setCountryModel(evt.target.value)
                }}
                inputProps={{
                  name: 'country',
                  id: 'country',
                }}>
                {countryOptions.map((country, ind) => (
                  <option
                    key={ind}
                    aria-label={country.value}
                    value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Typography className={classes.formLabel}>Handedness</Typography>
            <FormControl
              variant="outlined"
              style={{ display: 'flex', marginBottom: 25 }}>
              <Select
                native
                value={handednessModel}
                style={{ marginBottom: 10, height: 40 }}
                onChange={(evt) => {
                  updateEditedFields('handedness')
                  setHandednessModel(evt.target.value)
                }}
                inputProps={{
                  name: 'handedness',
                  id: 'handedness',
                }}>
                {handednessOptions.map((handedness, ind) => (
                  <option
                    key={ind}
                    aria-label={handedness.value}
                    value={handedness.value}>
                    {handedness.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Typography className={classes.formLabel}>Weight (kg)</Typography>
            <FormControl style={{ width: '100%', marginBottom: 5 }}>
              <TextField
                key="weight"
                id="weight"
                name="weight"
                value={weightModel}
                onChange={(evt: any) => {
                  updateEditedFields('weight')
                  setWeightModel(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <Typography className={classes.formLabel}>Height (cm)</Typography>

            <FormControl style={{ width: '100%', marginBottom: 5 }}>
              <TextField
                key="height"
                id="height"
                name="height"
                value={heightModel}
                onChange={(evt: any) => {
                  updateEditedFields('height')
                  setHeightModel(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <Typography className={classes.formLabel}>
              Type of measurement
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="heightby"
                name="heightby"
                value={heightByModel}
                onChange={(evt) => {
                  updateEditedFields('height by ' + evt.target.value)
                  setHeightByModel(evt.target.value)
                }}>
                <FormControlLabel
                  value="height"
                  control={<Radio />}
                  label="By Height"
                />
                <FormControlLabel
                  value="length"
                  control={<Radio />}
                  label="Length"
                />
              </RadioGroup>
            </FormControl>
            <Typography className={classes.formLabel}>
              Upper arm circumference (cm)
            </Typography>

            <FormControl style={{ width: '100%', marginBottom: 5 }}>
              <TextField
                key="upperarmcirc"
                id="upperarmcirc"
                name="upperarmcirc"
                value={upperArmCircModel}
                onChange={(evt: any) => {
                  updateEditedFields('upper arm circumference')
                  setUpperArmCircModel(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <Typography className={classes.formLabel}>
              Head circumference (cm)
            </Typography>

            <FormControl style={{ width: '100%', marginBottom: 5 }}>
              <TextField
                key="headcirc"
                id="headcirc"
                name="headcirc"
                value={headCircModel}
                onChange={(evt: any) => {
                  updateEditedFields('head circumference')
                  setHeadCircModel(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <Typography className={classes.formLabel}>Diagnosis</Typography>

            <FormControl style={{ width: '100%', marginBottom: 5 }}>
              <TextField
                key="diagnosis"
                id="diagnosis"
                name="diagnosis"
                value={diagnosisModel}
                onChange={(evt: any) => {
                  updateEditedFields('diagnosis')
                  setDiagnosisModel(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <Typography className={classes.formLabel}>
              Diagnosis Made By
            </Typography>

            <FormControl style={{ width: '100%', marginBottom: 25 }}>
              <TextField
                key="diagnosisby"
                id="diagnosisby"
                name="diagnosisby"
                placeholder="(Clinic / Doctor)"
                value={diagnosisByModel}
                onChange={(evt: any) => {
                  updateEditedFields('diagnosis made by')
                  setDiagnosisByModel(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <Button variant="contained" color="primary" onClick={save}>
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}
