import React from 'react'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import { VideoContainer } from '../components/VideoContainer'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectLabel: {
      margin: 15,
    },
  }),
)

export const SynchronyTask = () => {
  const classes = useStyles()

  return (
    <AdminPage title="Synchrony task">
      <Grid container className={classes.root} spacing={2}>
        <Grid xs={12} item>
          <Grid item xs={12}>
            <Typography variant="h6">Demo</Typography>
            <Grid container spacing={2}>
              <Grid md={12} item>
                <VideoContainer video={videos.demo} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Test</Typography>
          <Grid container spacing={2}>
            <Grid md={12} item>
              <VideoContainer video={videos.test} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const videos = {
  demo: {
    id: 'synchronyTaskDemoVideo',
    title: 'Demo Video',
    file: 'synchrony-demo-video',
  },
  test: {
    id: 'synchronyTaskTestVideo',
    title: 'Test Video',
    file: 'synchrony-test-video',
  },
}
