import React from 'react'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { SurveyResultEvent } from 'src/pages/SurveyResults/types'

export const SurveyCheckItem = ({
  name,
  subscribe,
  disabled = false,
  id,
  onChange,
}: {
  name: string
  id?: string
  subscribe: (listener: (event: SurveyResultEvent) => void) => () => void
  disabled?: boolean
  onChange?: (oldVal: boolean) => void
}) => {
  const [selected, setSelected] = React.useState(false)
  const classes = useStyles()

  React.useEffect(() => {
    return subscribe(onItemEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onItemEvent = (event: SurveyResultEvent) => {
    switch (event.type) {
      case 'select-all':
        setSelected(true)
        break
      case 'deselect-all':
        setSelected(false)
        break
      case 'select-column':
        if (name === event.payload.name) {
          setSelected(true)
        }
        break
      case 'deselect-column':
        if (name === event.payload.name) {
          setSelected(false)
        }
        break
      case 'select-row':
        if (id === event.payload.id) {
          setSelected(true)
        }
        break
      case 'deselect-row':
        if (id === event.payload.id) {
          setSelected(false)
        }
        break
      default:
        break
    }
  }

  return (
    <FormControlLabel
      className={classes.checkboxCell}
      control={
        <Checkbox
          disabled={disabled}
          checked={selected}
          onChange={() => {
            setSelected((oldVal) => {
              onChange && onChange(oldVal)
              return !oldVal
            })
          }}
          name={name}
          color="primary"
        />
      }
      label={``}
    />
  )
}

const useStyles = makeStyles({
  checkboxCell: {
    margin: 0,
  },
})
