import React, { useEffect } from 'react'
import ReactExport from 'react-export-excel'
import { useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'

import { fetchLogs } from '../services/Api'
import { AdminPage } from './AdminPage'

export const Logs = () => {
  const token = useSelector(selectors.getToken)
  const classes = useStyles()
  const { innerHeight: height } = window

  const [openDialog, setOpenDialog] = React.useState(false)
  const [queryModel, setQueryModel] = React.useState<any>()
  const [searchTermsModel, setSearchTermsModel] = React.useState<string>('')
  const [searching, setSearching] = React.useState<boolean>(false)
  const [filtersApplied, setFiltersApplied] = React.useState<boolean>(false)
  const [startDate, setStartDate] = React.useState<any>()
  const [endDate, setEndDate] = React.useState<any>()
  const [actionType, setActionType] = React.useState<string>('Any')

  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

  const searchWithFilter = () => {
    setSearching(true)
    if (token) {
      fetchLogs(token, {
        username: queryModel,
        startDate,
        endDate,
        actionType: actionTypes[actionType],
        searchTerms: searchTermsModel,
      }).then((resp: any) => {
        if (resp.data.success) {
          setLogs(resp.data.logs)
          setSearching(false)
          setOpenDialog(false)
          setFiltersApplied(true)
        }
      })
    } else {
      setSearching(false)
    }
  }

  const clearFilters = () => {
    setQueryModel(null)
    if (token) {
      fetchLogs(token).then((resp: any) => {
        if (resp.data.success) {
          setLogs(resp.data.logs)
          setFiltersApplied(false)
        }
      })
    }
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const [logs, setLogs] = React.useState<any>([])

  const getLogs = () => {
    if (token) {
      fetchLogs(token).then((resp: any) => {
        if (resp.data.success) {
          const logsSortedByTimestamp = resp.data.logs.sort((a, b) =>
            a.time > b.time ? 1 : b.time > a.time ? -1 : 0,
          )
          setLogs(logsSortedByTimestamp)
        }
      })
    }
  }

  useEffect(() => {
    getLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AdminPage title="Logs">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1">
            From this screen, you can track all the users and their actions
            performed on the backend.
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.addnew}>
          <div
            style={{ margin: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              style={{ alignContent: 'center' }}
              className={classes.filtersButton}
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenDialog(true)
              }}>
              <FilterListIcon style={{ marginRight: 5 }} />
              Filter
            </Button>
            <FormControl>
              <TextField
                id="searchterms"
                label="Search"
                size="small"
                className={classes.filtersButton}
                value={searchTermsModel}
                autoFocus={true}
                onKeyDown={(evt) => {
                  if (evt.keyCode === 13) {
                    searchWithFilter()
                  }
                }}
                onChange={(evt) => {
                  setSearchTermsModel(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            {filtersApplied && (
              <Button
                className={classes.filtersButton}
                variant="contained"
                color="secondary"
                onClick={() => {
                  clearFilters()
                }}>
                Reset Filters
              </Button>
            )}
          </div>
          <div>
            <ExcelFile
              filename={`stream_user_logs_${moment(new Date()).format(
                'YYYY-MM-DD',
              )}`}
              element={
                <Button
                  className={classes.exportButton}
                  color="primary"
                  variant="contained"
                  size="large">
                  Export
                </Button>
              }>
              <ExcelSheet data={logs} name="User Logs">
                <ExcelColumn label="User ID" value="username" />
                <ExcelColumn
                  label="Time &amp; Date"
                  value={(col) =>
                    moment(new Date(col.time)).format('DD/MM/YYYY h:mm A')
                  }
                />
                <ExcelColumn label="Action" value="action" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 0.65 * height }}>
            <Table stickyHeader className={classes.table} aria-label="App">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 280 }}>
                    <strong>User ID</strong>
                  </TableCell>
                  <TableCell style={{ width: 220 }}>
                    <strong>Date &amp; Time</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((row, rowIndex) => (
                  <React.Fragment key={row.id}>
                    <TableRow key={rowIndex}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ wordBreak: 'break-all' }}>
                        {row.username}
                      </TableCell>
                      <TableCell>
                        {moment(new Date(row.time)).format('DD/MM/YYYY h:mm A')}
                      </TableCell>
                      <TableCell>{row.action}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="Confirm user delete">
        <DialogTitle id="delete-dialog-title">Filter</DialogTitle>
        <DialogContent>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <FormControl style={{ display: 'flex', margin: 10 }}>
                <TextField
                  id="username"
                  label="Username"
                  size="small"
                  value={queryModel}
                  autoFocus={true}
                  onChange={(evt) => {
                    setQueryModel(evt.target.value)
                  }}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ display: 'flex', margin: 10 }}>
                <TextField
                  id="startdate"
                  variant="outlined"
                  size="small"
                  label="Starting on:"
                  type="date"
                  onChange={(evt) => {
                    setStartDate(evt.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ display: 'flex', margin: 10 }}>
                <TextField
                  id="enddate"
                  variant="outlined"
                  size="small"
                  label="Ending on:"
                  type="date"
                  onChange={(evt) => {
                    setEndDate(evt.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                <InputLabel
                  style={{
                    backgroundColor: 'white',
                    marginLeft: 10,
                    marginRight: 10,
                  }}>
                  Action type
                </InputLabel>
                <Select
                  native
                  value={actionType}
                  style={{
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    height: 40,
                  }}
                  onChange={(evt) => {
                    setActionType(evt.target.value as string)
                  }}>
                  {actionTypeList.map((type: string, index: number) => (
                    <option
                      key={`actionType-${index}`}
                      aria-label={type}
                      value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={searching}
            type="submit"
            variant="contained"
            onClick={() => searchWithFilter()}
            color="secondary">
            {searching ? <CircularProgress size="1.5rem" /> : 'Search'}
          </Button>
        </DialogActions>
      </Dialog>
    </AdminPage>
  )
}

const actionTypes = {
  Login: 'logged in',
  Logout: 'logged out',
  'Open survey': 'opened',
  'Set survey status': 'updated the status',
  'Download survey': 'downloaded',
  'Delete survey': 'deleted',
  'Create user': 'added',
  'Edit user': 'edited',
  'Delete user': 'deleted',
  'Accept Terms & Conditions': 'accepted',
  'Change decrypt key': 'changed the decrypt key',
  Any: '',
}

const actionTypeList = Object.keys(actionTypes)

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  addnew: {
    textAlign: 'right',
    padding: 12,
  },
  filtersButton: {
    marginLeft: 5,
    marginRight: 5,
  },
  exportButton: {
    width: 145,
    height: 40,
    margin: 15,
  },
})
