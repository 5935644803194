import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import * as selectors from 'src/redux/selectors'
import { editCustomFields, fetchCustomFields } from 'src/services/Api'

import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const FreePlayPCITask = () => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const [englishModel, setEnglishModel] = React.useState<string>('')
  const [hindiModel, setHindiModel] = React.useState<string>('')
  const [chichewaModel, setChichewaModel] = React.useState<string>('')
  const [fetchedInstructions, setFetchedInstructions] = React.useState<boolean>(
    false,
  )

  const updateInstructions = () => {
    editCustomFields(token, {
      fields: JSON.stringify({
        instructions_en: englishModel,
        instructions_ch: chichewaModel,
        instructions_hi: hindiModel,
      }),
      testId: 'freeplayPci',
    })
      .then((result) => {
        if (result.data.success) {
          setSnackbarStatus('success')
        } else {
          setSnackbarStatus('error')
        }
      })
      .catch(() => {
        setSnackbarStatus('error')
      })
  }

  useEffect(() => {
    if (!fetchedInstructions && token) {
      fetchCustomFields(token, 'freeplayPci').then((resp: any) => {
        if (resp.data.fields) {
          const parsedFields = JSON.parse(resp.data.fields)
          const {
            instructions_en,
            instructions_ch,
            instructions_hi,
          } = parsedFields
          if (instructions_en) {
            setEnglishModel(instructions_en)
          }
          if (instructions_ch) {
            setChichewaModel(instructions_ch)
          }
          if (instructions_hi) {
            setHindiModel(instructions_hi)
          }
        }
      })
      setFetchedInstructions(true)
    }
  }, [token, fetchedInstructions])

  return (
    <AdminPage title="PCI">
      <Grid container className={classes.root} spacing={2}>
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="instructions">
            <Typography variant="h6">English Instructions</Typography>
          </FormLabel>
          <FormControl style={{ width: '100%' }}>
            <TextField
              key="instructions_en"
              id="instructions_en"
              name="instructions_en"
              multiline
              rows={5}
              value={englishModel}
              onChange={(evt: any) => {
                setEnglishModel(evt.target.value)
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="instructions">
            <Typography variant="h6">Hindi Instructions</Typography>
          </FormLabel>
          <FormControl style={{ width: '100%' }}>
            <TextField
              key="instructions_hi"
              id="instructions_hi"
              name="instructions_hi"
              multiline
              rows={5}
              value={hindiModel}
              onChange={(evt: any) => {
                setHindiModel(evt.target.value)
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="instructions">
            <Typography variant="h6">Chichewa Instructions</Typography>
          </FormLabel>
          <FormControl style={{ width: '100%' }}>
            <TextField
              key="instructions_ch"
              id="instructions_ch"
              name="instructions_ch"
              multiline
              rows={5}
              value={chichewaModel}
              onChange={(evt: any) => {
                setChichewaModel(evt.target.value)
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => updateInstructions()}>
              Save
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}
