import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import * as selectors from 'src/redux/selectors'

const ProtectedRoute = ({ children, roles = [], ...rest }: any) => {
  const isAuthenticated = useSelector(selectors.isAuthenticated)
  const userRole = useSelector(selectors.getUserRole)

  if (isAuthenticated === null) {
    return <></>
  }
  let accessGranted = isAuthenticated
  if (roles.length > 0) {
    if (!roles.includes(userRole)) accessGranted = false
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        accessGranted ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default ProtectedRoute
