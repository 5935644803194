import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { AdminPage } from 'src/pages/AdminPage'
import * as selectors from 'src/redux/selectors'
import { getDateDiffInYearsAndMonths } from 'src/services/Utils'
import { ChildInfo } from 'src/types'

const useStyles = makeStyles({
  container: {
    padding: 12,
  },
  buttonContainer: {
    padding: 12,
  },
  button: {
    marginLeft: 5,
    marginRight: 5,
  },
  titleCell: {
    width: '25%',
    height: 20,
    padding: 10,
  },
  contentCell: {
    width: '25%',
    height: 20,
    padding: 10,
    color: 'blue',
  },
})

export const ViewChildInfo = ({
  childInfo,
  parent1Info,
  parent2Info,
  surveyId,
  surveyDate,
  setOpenChildDialog,
}: {
  childInfo: ChildInfo
  parent1Info: any
  parent2Info: any
  surveyId: string
  surveyDate: Date
  setOpenChildDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const user = useSelector(selectors.getUser)
  const history = useHistory()
  const styles = useStyles()

  const editChild = (id: string) => {
    history.push('/edit-child', { childId: id, surveyId })
  }

  const editParent1 = (id: string, ro?) => {
    history.push('/edit-parent', {
      childId: id,
      parent: 1,
      surveyId,
      readOnly: ro,
    })
  }

  const editParent2 = (id: string, ro?) => {
    history.push('/edit-parent', {
      childId: id,
      parent: 2,
      surveyId,
      readOnly: ro,
    })
  }

  return (
    <AdminPage
      onBackPressed={() => {
        setOpenChildDialog(false)
      }}
      title={`Child information`}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            View and edit the child's information.
          </Typography>
        </Grid>

        <Grid xs={6} className={styles.container}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={styles.titleCell}>Child ID</TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.id}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>Gender</TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.gender}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>
                  Date of birth
                </TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.dateOfBirth && childInfo.dateOfBirth.slice(0, 10)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>
                  Age at assessment
                </TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.dateOfBirth &&
                    ageToString(
                      getDateDiffInYearsAndMonths(
                        new Date(childInfo.dateOfBirth),
                        new Date(surveyDate),
                      ),
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>Country</TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.country && uppercaseFirstLetter(childInfo.country)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>Handedness</TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.handedness}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>Weight</TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.weight ? `${childInfo.weight} kg` : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>
                  {childInfo.heightBy === 'length'
                    ? 'Height (by length)'
                    : 'Height'}
                </TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.height ? `${childInfo.height} cm` : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>
                  Upper arm circumference
                </TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.upperArmCircumference
                    ? `${childInfo.upperArmCircumference} cm`
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>
                  Head circumference
                </TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.headCircumference
                    ? `${childInfo.headCircumference} cm`
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>Diagnosis</TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.diagnosis}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.titleCell}>
                  Diagnosis made by
                </TableCell>
                <TableCell className={styles.contentCell}>
                  {childInfo.diagnosisBy}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid xs={6} className={styles.container}>
          <Table>
            <TableBody>
              {parent1Info.parent && (
                <>
                  <TableRow>
                    <TableCell className={styles.titleCell}>Parent 1</TableCell>
                    <TableCell className={styles.contentCell} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>Gender</TableCell>
                    <TableCell className={styles.contentCell}>
                      {parent1Info.parent.gender}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>
                      Date of birth
                    </TableCell>
                    <TableCell className={styles.contentCell}>
                      {parent1Info.parent.birthDate
                        ? parent1Info.parent.birthDate.slice(0, 10)
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>Language</TableCell>
                    <TableCell className={styles.contentCell}>
                      {uppercaseFirstLetter(parent1Info.parent.language)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>
                      Upper arm circumference
                    </TableCell>
                    <TableCell className={styles.contentCell}>
                      {parent1Info.parent.upperArmCircumference}
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow />
              {parent2Info.parent && (
                <>
                  <TableRow>
                    <TableCell className={styles.titleCell}>Parent 2</TableCell>
                    <TableCell className={styles.contentCell} />
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>Gender</TableCell>
                    <TableCell className={styles.contentCell}>
                      {parent2Info.parent.gender}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>
                      Date of birth
                    </TableCell>
                    <TableCell className={styles.contentCell}>
                      {parent2Info.parent.birthDate
                        ? parent2Info.parent.birthDate.slice(0, 10)
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>Language</TableCell>
                    <TableCell className={styles.contentCell}>
                      {uppercaseFirstLetter(parent2Info.parent.language)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={styles.titleCell}>
                      Upper arm circumference
                    </TableCell>
                    <TableCell className={styles.contentCell}>
                      {parent2Info.parent.upperArmCircumference}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Grid>

        <Grid xs={12} className={styles.buttonContainer}>
          {user && user.role === 'superAdmin' && (
            <>
              <Button
                variant="contained"
                className={styles.button}
                onClick={() => editChild(childInfo.id)}
                color="primary">
                Edit Child
              </Button>
              <Button
                variant="contained"
                className={styles.button}
                onClick={() => editParent1(childInfo.id)}
                color="primary">
                Edit Parent 1
              </Button>
              <Button
                variant="contained"
                className={styles.button}
                onClick={() => editParent2(childInfo.id)}
                color="primary">
                Edit Parent 2
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const uppercaseFirstLetter = (input: string) => {
  if (!input) return
  return input.charAt(0).toUpperCase() + input.slice(1)
}

const ageToString = ({ years, months }: { years: number; months: number }) => {
  const yearOrYears = years === 1 ? 'year' : 'years'
  const monthOrMonths = months === 1 ? 'month' : 'months'
  return `  ${years} ${yearOrYears}, ${months} ${monthOrMonths}`
}
