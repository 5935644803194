import Axios from 'axios'
import { put, select, takeLatest } from 'redux-saga/effects'

import * as actions from '../actions'
import * as selectors from '../selectors'

function* init() {
  yield put(actions.init())
}
function* setAxiosToken() {
  const token = selectors.getToken(yield select())
  if (token) {
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}
function* rootSaga() {
  yield takeLatest(['persist/REHYDRATE'], init)
  yield takeLatest(['INIT'], setAxiosToken)
}
export default rootSaga
