import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'
import { logUserAction } from 'src/services/Api'
import { v4 as uuidv4 } from 'uuid'

import { createParent, fetchParent, updateParent } from '../services/Api'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles({
  formLabel: {
    fontSize: 18,
    marginTop: 20,
  },
})

export const EditParent = () => {
  const history = useHistory()
  const classes = useStyles()
  const token = useSelector(selectors.getToken)
  const authenticatedUser = useSelector(selectors.getUser)
  const [childId, setChildId] = React.useState<string>('')
  const [parentId, setParentId] = React.useState<string>('')
  // parent = index of parent (i.e. 1 or 2)
  const [parent, setParent] = React.useState<any>()
  const [readOnly, setReadOnly] = React.useState<any>()
  const [surveyId, setSurveyId] = React.useState<string>('')
  const [genderModel, setGenderModel] = React.useState<any>(undefined)
  const [showOtherGender, setShowOtherGender] = React.useState<boolean>(false)
  const [
    upperArmCircumference,
    setUpperArmCircumference,
  ] = React.useState<string>('')
  const [otherGenderModel, setOtherGenderModel] = React.useState<string>('')
  const [statusModel, setStatusModel] = React.useState<any>(undefined)
  const [otherStatusModel] = React.useState<any>()
  const [languageModel, setLanguageModel] = React.useState<any>(undefined)
  const [dobModel, setDobModel] = React.useState<any>()
  const [editedFields, setEditedFields] = React.useState<string[]>([])
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const languageOptions = [
    { label: '', value: undefined },
    { label: 'English', value: 'english' },
    { label: 'Hindi', value: 'hindi' },
    { label: 'Chichewa', value: 'chichewa' },
  ]

  const location = useLocation()

  const updateEditedFields = (field) => {
    const updatedFields = editedFields
    if (editedFields.indexOf(field) < 0) {
      updatedFields.push(field)
      setEditedFields(updatedFields)
    }
  }

  const save = () => {
    if (token) {
      if (!parentId) {
        const newParentId = uuidv4()
        createParent(token, {
          parentId: newParentId,
          childId,
          gender:
            genderModel !== 'male' && genderModel !== 'female'
              ? otherGenderModel
              : genderModel,
          birthDate: new Date(dobModel),
          language: languageModel,
          status: statusModel,
          otherStatus: otherStatusModel,
          upperArmCircumference,
        })
          .then(() => {
            if (authenticatedUser === null) return

            logUserAction(token, {
              username: authenticatedUser.username,
              action: `Created new parent ${newParentId} for child ${childId}`,
            })
            setSnackbarStatus('success')
            history.push('/survey/view', {
              surveyId,
              showSuccess: 'success',
            })
          })
          .catch(() => {
            setSnackbarStatus('error')
          })
      } else {
        updateParent(token, {
          parentId,
          gender:
            genderModel !== 'male' && genderModel !== 'female'
              ? otherGenderModel
              : genderModel,
          birthDate: new Date(dobModel),
          language: languageModel,
          status: statusModel,
          otherStatus: otherStatusModel,
          upperArmCircumference,
        })
          .then(() => {
            if (authenticatedUser === null) return

            logUserAction(token, {
              username: authenticatedUser.username,
              action: `Edited ${editedFields.join(', ')} of child ${childId}`,
            })
            setSnackbarStatus('success')
            history.push('/survey/view', {
              surveyId,
              showSuccess: 'success',
            })
          })
          .catch(() => {
            setSnackbarStatus('error')
          })
      }
    }
  }

  useEffect(() => {
    const { state }: any = location
    if (state) {
      if (state.childId && state.parent && token) {
        setChildId(state.childId)
        setSurveyId(state.surveyId)
        setParent(parent)
        if (state.readOnly) {
          setReadOnly(true)
        }
        fetchParent(token, state.childId, state.parent).then((resp: any) => {
          const { parent: responseParent } = resp.data

          if (responseParent) {
            setParentId(responseParent.id)
            setGenderModel(
              responseParent.gender !== 'male' &&
                responseParent.gender !== 'female'
                ? 'other'
                : responseParent.gender,
            )
            setLanguageModel(responseParent.language)
            setStatusModel(responseParent.status)
            setDobModel(
              moment(new Date(responseParent.birthDate)).format('YYYY-MM-DD'),
            )
            setOtherGenderModel(
              responseParent.gender !== 'male' &&
                responseParent.gender !== 'female'
                ? responseParent.gender
                : '',
            )
            setUpperArmCircumference(responseParent.upperArmCircumference ?? '')
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AdminPage
      title={
        parentId
          ? `Edit parent ${parent} of ${childId}`
          : `Create parent ${parent} for ${childId}`
      }>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          {errorMessage !== false && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </Grid> */}
        <Grid item xs={12}>
          <form
            key="editparent"
            name="editparent"
            noValidate
            id="editparent"
            style={{ width: '100%' }}>
            <Typography className={classes.formLabel}>Gender</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={genderModel}
                onChange={(evt) => {
                  if (evt.target.value === 'other') {
                    setShowOtherGender(true)
                  }
                  updateEditedFields('gender')
                  setGenderModel(evt.target.value)
                }}>
                <FormControlLabel
                  value="female"
                  control={<Radio checked={genderModel === 'female'} />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio checked={genderModel === 'male'} />}
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={
                    <Radio
                      checked={
                        genderModel !== 'female' &&
                        genderModel !== 'male' &&
                        genderModel !== null &&
                        genderModel !== undefined
                      }
                    />
                  }
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            {(showOtherGender ||
              (otherGenderModel !== '' && otherGenderModel !== null)) && (
              <FormControl style={{ width: '100%', marginBottom: 5 }}>
                <TextField
                  key="othergender"
                  id="othergender"
                  name="othergender"
                  label="Other gender"
                  value={otherGenderModel}
                  onChange={(evt: any) => {
                    updateEditedFields('other gender')
                    setOtherGenderModel(evt.target.value)
                  }}
                  variant="outlined"
                />
              </FormControl>
            )}
            <Typography className={classes.formLabel}>Status</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="status"
                name="status"
                value={statusModel}
                onChange={(evt) => {
                  updateEditedFields('status')
                  setStatusModel(evt.target.value)
                }}>
                <FormControlLabel
                  value="parent"
                  control={<Radio checked={statusModel === 'parent'} />}
                  label="Parent"
                />
                <FormControlLabel
                  value="guardian"
                  control={<Radio checked={statusModel === 'guardian'} />}
                  label="Guardian"
                />
              </RadioGroup>
            </FormControl>
            <Typography className={classes.formLabel}>Date of birth</Typography>
            <FormControl style={{ width: '100%', marginBottom: 5 }}>
              <TextField
                key="dob"
                id="dob"
                type="date"
                name="dob"
                value={dobModel}
                onChange={(evt: any) => {
                  updateEditedFields('date of birth')
                  setDobModel(evt.target.value)
                }}
                onBlur={() => {
                  // validateField(evt.target.value, setDobError)
                }}
                variant="outlined"
              />
            </FormControl>
            <Typography className={classes.formLabel}>Language</Typography>
            <FormControl
              variant="outlined"
              style={{ display: 'flex', marginBottom: 25 }}>
              <Select
                native
                value={languageModel}
                style={{ marginBottom: 10, height: 40 }}
                onChange={(evt) => {
                  updateEditedFields('language')
                  setLanguageModel(evt.target.value)
                }}
                inputProps={{
                  name: 'language',
                  id: 'language',
                }}>
                {languageOptions.map((lang, ind) => (
                  <option key={ind} aria-label={lang.value} value={lang.value}>
                    {lang.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Typography className={classes.formLabel}>
              Upper arm circumference
            </Typography>
            <FormControl
              variant="outlined"
              style={{ display: 'flex', marginBottom: 25 }}>
              <TextField
                key="upperArmCircumference"
                id="upperArmCircumference"
                name="upperArmCircumference"
                value={upperArmCircumference}
                onChange={(evt: any) => {
                  updateEditedFields('upperArmCircumference')
                  setUpperArmCircumference(evt.target.value)
                }}
                onBlur={() => {
                  // validateField(evt.target.value, setDobError)
                }}
                variant="outlined"
              />
            </FormControl>

            <FormControl>
              {!readOnly && (
                <Button variant="contained" color="primary" onClick={save}>
                  Save
                </Button>
              )}
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}
