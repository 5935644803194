import axios from 'axios'

import { env } from '../env'

const baseUrl = env.apiBaseUrl.replace(/\/+$/, '')

const setAuthorization = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const login = (username, password) => {
  return axios.post(`${baseUrl}/cms/login`, {
    username,
    password,
  })
}

export const resetPassword = (username) => {
  return axios.post(`${baseUrl}/cms/resetPassword`, {
    username,
  })
}

export const reEncryptAllSurveys = (oldPrivateKey) => {
  return axios.post(`${baseUrl}/re-encrypt-all-files`, {
    oldPrivateKey,
  })
}

export const checkReEncryptionStatus = () => {
  return axios.get(`${baseUrl}/check-re-encryption-status`)
}

export const createNewPassword = (token, password) => {
  return axios.post(`${baseUrl}/cms/createNewPassword`, {
    token,
    password,
  })
}

export const saveUser = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/register`, payload)
}

export const deleteUser = async (token, id) => {
  setAuthorization(token)
  return axios.delete(`${baseUrl}/deleteUser?id=${id}`)
}

export const logUserAction = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/logUserAction`, payload)
}

export const fetchLogs = async (token, query?) => {
  setAuthorization(token)
  if (query) {
    return axios.get(`${baseUrl}/logs`, { params: query })
  } else {
    return axios.get(`${baseUrl}/logs`)
  }
}

export const editUser = async (token, payload) => {
  setAuthorization(token)
  return axios.put(`${baseUrl}/editUser`, payload)
}

export const fetchUser = async (token, id) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/fetchUser?id=${id}`)
}

export const fetchUsers = async (token) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/fetchUsers`)
}

export const fetchAssessmentContent = async (token) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/cms/fetchAssessmentContent`)
}

export const editCustomFields = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/tasks/customFields`, payload)
}

export const fetchSurveys = async (token, filters) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/surveys/all`, filters)
}

export const fetchChildBySurveyId = async (token, surveyId) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/survey/${surveyId}/child`)
}

export const fetchChild = async (token, childId) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/child/id/${childId}`)
}

export const fetchParent = async (token, childId, parentIndex) => {
  setAuthorization(token)
  return axios.get(
    `${baseUrl}/parent?childId=${childId}&whichParent=${parentIndex}`,
  )
}

export const updateParent = async (token, payload) => {
  setAuthorization(token)
  return axios.put(`${baseUrl}/parent`, payload)
}

export const createParent = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/parent`, payload)
}

export const updateChild = async (token, payload) => {
  setAuthorization(token)
  return axios.put(`${baseUrl}/child`, payload)
}

export const fetchSurveyStatus = async (token, surveyId) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/survey/${surveyId}/status`)
}

export const updateSurveyStatus = async (token, surveyId, status) => {
  setAuthorization(token)
  return axios.put(`${baseUrl}/survey/${surveyId}/status/${status}`)
}

export const fetchSurveyTests = async (token, surveyId) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/survey/${surveyId}/view`)
}

export const fetchDEEPGames = async (token, surveyId, attemptId) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/survey/deep/games`, {
    params: {
      surveyId,
      attemptId,
    },
  })
}

export const fetchDEEPPreview = async (token, surveyId, attemptId, gameId) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/download/preview/request/deep`, {
    surveyId,
    attemptId,
    gameId,
  })
}

export const makeDownloadRequest = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/download/request`, payload)
}

export const fetchPreviewWeight = async (token, payload) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/download/determineDataWeight`, {
    params: payload,
  })
}

export const deleteSurveys = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/surveys/delete`, payload)
}

export const flagSurveyRemoval = async (token, surveyId) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/surveys/flag/removal`, surveyId)
}

export const makeHeavyPreviewRequest = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/download/preview/request/heavy`, payload)
}

export const makeLightPreviewRequest = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/download/preview/request/light`, payload)
}

export const pollDownloadRequest = async (token, id) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/download/status/${id}`)
}

export const fetchLightDataPreview = async (token, id) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/download/preview/light/data/id/${id}`)
}

export const fetchMediaPreview = async (token, id, fileName, fileType) => {
  setAuthorization(token)
  return axios.get(
    `${baseUrl}/download/preview/media/id/${id}?fileName=${fileName}&fileType=${fileType}`,
  )
}

export const fetchPreviewFileIdsAndMetadata = async (token, payload) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/download/preview/request/fileIdsAndMetadata`, {
    params: payload,
  })
}

export const checkEncryptionKeyIsCorrect = async (token, payload) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/download/check-encryption-key-correct`, {
    params: payload,
  })
}

export const getAvailableQuestionIds = async (token, testId) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/tasks/availableQuestionIds?testId=${testId}`)
}

export const fetchCustomFields = async (token, testId) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/tasks/customFields?testId=${testId}`)
}

export const fetchConsentForms = async (token) => {
  setAuthorization(token)
  return axios.get(`${baseUrl}/consent-forms`)
}

export const saveConsentForm = async (token, payload) => {
  setAuthorization(token)
  return axios.post(`${baseUrl}/cms/saveConsentForm`, payload)
}

export const whoami = async () => {
  return axios.get('/whoami')
}

export const fetchAgeBasedFiltration = async (token) => {
  return axios.get(`${baseUrl}/age-based-filtration`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const assessmentContentLastUpdated = async (token) => {
  return axios.get(`${baseUrl}/assessment-content-last-updated`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const updateAgeBasedFiltration = async (token, filtration) => {
  return axios.put(
    `${baseUrl}/age-based-filtration`,
    { filtration },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
}

export const uploadAssessmentContent = async (token, data, progress) => {
  return axios.post(`${baseUrl}/upload-assessment-content`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    onUploadProgress: (progressEvent) => progress(progressEvent),
  })
}

export const deleteAssessmentContent = async (token, dataId) => {
  return axios.post(
    `${baseUrl}/delete-assessment-content`,
    { fileId: dataId },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
}

export const fetchMdatAssessmentContent = (token) => {
  return axios.get(`${baseUrl}/mdat`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const fetchFileMetadata = async (token, fileId) => {
  return axios.get(`${baseUrl}/file-metadata/${fileId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const updateMdatAssessmentContent = (token, updates) => {
  return axios.put(
    `${baseUrl}/mdat`,
    { updates },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
}

export const fetchMdatStopRule = (token) => {
  return axios.get(`${baseUrl}/mdat/stop-rule`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const updateMdatStopRule = (
  token,
  contiguousStartRule: number,
  contiguousStopRule: number,
) => {
  return axios.put(
    `${baseUrl}/mdat/stop-rule`,
    { contiguousStartRule, contiguousStopRule },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
}
