import React from 'react'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'

import { VideoContainer } from '../components/VideoContainer'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const WheelTask = () => {
  const classes = useStyles()

  return (
    <AdminPage title="Wheel task">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Demo</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.demoVideo} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Test</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.video} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const videos = {
  demoVideo: {
    id: 'wheelTaskDemoVideo',
    title: '',
    file: 'wheel-task-demo-video',
  },
  video: {
    id: 'wheelTaskVideo',
    title: '',
    file: 'wheel-task-video',
  },
}
