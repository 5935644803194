import React from 'react'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'

import { VideoContainer } from '../components/VideoContainer'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const ChoiceTask = () => {
  const classes = useStyles()

  return (
    <AdminPage title="Button Task">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Demo</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.demoSocial} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.demoNonSocial} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Test</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.social} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.nonSocial} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const videos = {
  demoSocial: {
    id: 'choiceTaskDemoSocialVideo',
    title: 'Social Video',
    file: 'button-task-demo-social-video',
  },
  demoNonSocial: {
    id: 'choiceTaskDemoNonSocialVideo',
    title: 'Non Social Video',
    file: 'button-task-demo-non-social-video',
  },
  social: {
    id: 'choiceTaskSocialVideo',
    title: 'Social Video',
    file: 'button-task-social-video',
  },
  nonSocial: {
    id: 'choiceTaskNonSocialVideo',
    title: 'Non Social Video',
    file: 'button-task-non-social-video',
  },
}
