import React, { useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useSelector } from 'react-redux'
import {
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  Paper,
  Snackbar,
  Theme,
  Typography,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import MuiAlert from '@material-ui/lab/Alert'
import { env } from 'src/env'
import * as selectors from 'src/redux/selectors'
import { uploadAssessmentContent } from 'src/services/Api'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flex: 1,
      minHeight: 100,
      minWidth: 200,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    supportedTypes: {
      paddingTop: 4,
      fontSize: 12,
      color: '#6d6d6d',
    },
  }),
)

export const PdfContainer = ({
  pdf,
  shouldUpload,
  uploadCallback,
  showVideoPreview,
  onFileChosen,
  style,
}: any) => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)

  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const [uploadProgress, setUploadProgress] = React.useState<
    undefined | number
  >(undefined)
  const [currentPdf, setcurrentPdf] = React.useState<any>()
  const formData = React.useRef<any>()
  const [fileChosen, setFileChosen] = React.useState<string>('')
  const [numPages, setNumPages] = React.useState<number>(0)
  const [pageNumber, setPageNumber] = React.useState<number>(1)

  const updateProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    setUploadProgress(percentCompleted)
  }

  const uploadFile = (file) => {
    const data = new FormData()
    data.append('file', file)
    data.append('fileId', pdf.id)
    data.append('fileName', pdf.file)

    formData.current = data
    setFileChosen(file.name)

    if (onFileChosen) {
      onFileChosen(file)
    }

    if (shouldUpload === undefined || shouldUpload === true) {
      triggerUpload()
    }
  }

  const triggerUpload = () => {
    if (formData.current) {
      uploadAssessmentContent(token, formData.current, updateProgress)
        .then(() => {
          setcurrentPdf(
            `${env.apiBaseUrl}/file-by-id/${
              pdf.id
            }?cache=${new Date().toString()}`,
          )
          //videoRef.current?.load()
          setUploadProgress(undefined)
          setSnackbarStatus('success')
          if (uploadCallback) {
            uploadCallback()
          }
        })
        .catch(() => {
          setUploadProgress(undefined)
          setSnackbarStatus('error')
        })
    }
  }

  useEffect(() => {
    if (fileChosen && shouldUpload) {
      triggerUpload()
    }
    if (!currentPdf) {
      setcurrentPdf(
        `${env.apiBaseUrl}/file-by-id/${pdf.id}?cache=${new Date().toString()}`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpload, pdf])
  return (
    <Paper className={classes.paper} {...(style && { style })}>
      <div className={classes.headerRow}>
        <Typography component="h1" variant="subtitle1">
          {pdf.title}
        </Typography>
        <label htmlFor={pdf.id}>
          <input
            disabled={uploadProgress !== undefined}
            onChange={(e) => {
              if (!e.target.files) return
              uploadFile(e.target.files[0])
            }}
            style={{ display: 'none' }}
            id={pdf.id}
            name={pdf.id}
            type="file"
            accept=".pdf"
          />

          <div style={{ float: 'right', textAlign: 'center' }}>
            <Button
              disabled={uploadProgress !== undefined}
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}>
              Upload
            </Button>
            <div className={classes.supportedTypes}>(pdf only)</div>
          </div>
        </label>
      </div>
      {uploadProgress && (
        <LinearProgress variant="determinate" value={uploadProgress} />
      )}
      {showVideoPreview !== false && (
        <div style={{ margin: 20 }}>
          <Document
            file={currentPdf}
            onLoadSuccess={({ numPages: val }) => setNumPages(val)}
            onLoadError={console.error}>
            <Page pageNumber={pageNumber} />
          </Document>
          {currentPdf && (
            <>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={() => setPageNumber(pageNumber - 1)}>
                {'<'}
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={() => setPageNumber(pageNumber + 1)}>
                {'>'}
              </button>
            </>
          )}
        </div>
      )}
      {showVideoPreview === false && fileChosen !== '' && (
        <div style={{ padding: 30 }}>File to be uploaded: {fileChosen}</div>
      )}
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </Paper>
  )
}
