import { createAction } from '../helpers'

export const init = () => {
  return createAction('INIT')
}

export const acceptedTermsAndConditions = () => {
  return createAction('ACCEPTED_TERMS_AND_CONDITIONS')
}

export const enteredEncryptionKey = (payload: { key: string }) => {
  return createAction('ENTERED_ENCRYPTION_KEY', payload)
}
export const isReEncrypting = (payload: { status: boolean }) => {
  return createAction('IS_RE_ENCRYPTING', payload)
}

export const loginInit = () => {
  return createAction('LOGIN')
}

export const loginSuccess = (payload: { user: any; token: any }) => {
  return createAction('LOGIN_SUCCESS', payload)
}

export const loginFail = (payload: { error: string }) => {
  return createAction('LOGIN_FAIL', payload)
}

export const logout = () => {
  return createAction('LOGOUT')
}
