import React, { useEffect } from 'react'
import { List } from 'react-movable'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Alert from '@material-ui/lab/Alert'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'
import {
  editCustomFields,
  fetchCustomFields,
  getAvailableQuestionIds,
} from 'src/services/Api'

import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {
      minWidth: 650,
    },
  }),
)

export const ParentAssessmentQuestions = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const token = useSelector(selectors.getToken)
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const [maxError, setMaxError] = React.useState<boolean>(false)
  const [showMaxError, setShowMaxError] = React.useState<boolean>(false)
  const [questionIds, setQuestionIds] = React.useState<number[]>([])
  const [fetchedQuestionIds] = React.useState<boolean>(false)
  const [fetchedQuestions, setFetchedQuestions] = React.useState<boolean>(false)
  const [
    showDeleteQuestionModal,
    setShowDeleteQuestionModal,
  ] = React.useState<boolean>(false)
  const [removingQuestion, setRemovingQuestion] = React.useState<boolean>(false)
  const [questionToDelete, setQuestionToDelete] = React.useState<any>()
  const [questions, setQuestions] = React.useState<any[]>([])

  const availableIds = () => {
    getAvailableQuestionIds(token, 'parentAssessment').then((result) => {
      if (result.data.success) {
        setQuestionIds(result.data.availableIds)
      } else {
        setMaxError(true)
      }
    })
  }

  const cancelDelete = () => {
    setShowDeleteQuestionModal(false)
    setQuestionToDelete(null)
  }

  const triggerSaveReorderedQuestions = () => {
    editCustomFields(token, {
      fields: JSON.stringify({
        questions,
      }),
      testId: 'parentAssessment',
    })
      .then((result) => {
        if (result.data.success) {
          setSnackbarStatus('success')
          setRemovingQuestion(false)
          cancelDelete()
          getQuestions()
        } else {
          setSnackbarStatus('error')
        }
      })
      .catch(() => {
        setSnackbarStatus('error')
      })
  }

  const triggerDeleteQuestion = () => {
    setRemovingQuestion(true)

    let updatedQuestionList = questions.filter((question) => {
      if (question.question_id === questionToDelete) {
        return false
      }
      return true
    })

    editCustomFields(token, {
      fields: JSON.stringify({
        questions: updatedQuestionList,
      }),
      testId: 'parentAssessment',
    })
      .then((result) => {
        if (result.data.success) {
          setSnackbarStatus('success')
          setRemovingQuestion(false)
          cancelDelete()
          getQuestions()
        } else {
          setSnackbarStatus('error')
        }
      })
      .catch(() => {
        setSnackbarStatus('error')
      })
  }

  const deleteQuestion = (id: any) => {
    setShowDeleteQuestionModal(true)
    setQuestionToDelete(id)
  }

  const editQuestion = (question: any) => {
    history.push(`parent-assessment-question-builder`, { question })
  }

  const addNewQuestion = () => {
    if (questionIds.length > 0) {
      history.push(`/parent-assessment-question-builder`)
    } else if (maxError) {
      setShowMaxError(true)
    }
  }

  const getQuestions = () => {
    fetchCustomFields(token, 'parentAssessment').then((resp: any) => {
      if (resp.data.fields) {
        const parsedFields = JSON.parse(resp.data.fields)
        const { questions: retrievedQuestions } = parsedFields
        if (retrievedQuestions) {
          setQuestions(retrievedQuestions)
          setFetchedQuestions(true)
        }
      }
    })
  }

  useEffect(() => {
    if (!fetchedQuestionIds && token) {
      availableIds()
    }
    if (!fetchedQuestions && token) {
      getQuestions()
    }
    const { state }: any = location
    if (state) {
      const { showSuccess }: any = location.state
      if (showSuccess) {
        setSnackbarStatus('success')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AdminPage title="Parent assessment questions">
      <Grid container className={classes.root} spacing={2}>
        {maxError && showMaxError && (
          <Grid xs={12} item>
            <Alert severity="error">
              You have added the maximum number of questions. Please delete a
              question to add a new one.
            </Alert>
          </Grid>
        )}
        <Grid xs={12} item>
          <FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addNewQuestion()}>
              Add new question
            </Button>
          </FormControl>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            <List
              values={questions}
              onChange={({ oldIndex, newIndex }) => {
                let newQuestions = questions
                let movedQuestion = newQuestions.splice(oldIndex, 1)[0]
                newQuestions.splice(newIndex, 0, movedQuestion)
                triggerSaveReorderedQuestions()
              }}
              renderList={({ children, props }) => (
                // <ul {...props}>{children}</ul>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="App">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <strong>Question</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Video</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Last updated</strong>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody {...props}>{children}</TableBody>
                  </Table>
                </TableContainer>
              )}
              renderItem={({ value, props }) => (
                <TableRow key={value.question_id} {...props}>
                  <TableCell component="th" scope="row">
                    <DragHandleIcon />
                  </TableCell>
                  <TableCell>{value.question_en}</TableCell>
                  <TableCell>{value.video}</TableCell>
                  <TableCell>
                    {moment(new Date(value.last_updated)).format(
                      'DD/MM/YYYY h:mm A',
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: 5 }}
                      onClick={() => editQuestion(value)}>
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => deleteQuestion(value.question_id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={showDeleteQuestionModal}
        onClose={cancelDelete}
        aria-labelledby="Confirm question delete">
        <DialogTitle id="delete-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this question?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button
            disabled={removingQuestion}
            type="submit"
            variant="contained"
            onClick={() => triggerDeleteQuestion()}
            color="secondary">
            {removingQuestion ? (
              <CircularProgress size="1.5rem" />
            ) : (
              'Yes, delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </Alert>
      </Snackbar>
    </AdminPage>
  )
}
