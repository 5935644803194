import { ShortNames, TestsRecord } from 'src/pages/SurveyResults/types'

export const testShortNames: ShortNames[] = [
  'MDAT',
  'WT',
  'PLT',
  'LST',
  'BT',
  'MFT',
  'CT',
  'BPT',
  'ST',
  'DGT',
  'PA',
  'PCI',
  'DEEP',
]

export const testLongNames = {
  MDAT: 'MDAT',
  WT: 'Wheel Task',
  PLT: 'Preferential Looking Task',
  LST: 'Language Sampling Task',
  BT: 'Button Task',
  MFT: 'Motor Following Task',
  CT: 'Colouring Task',
  BPT: 'Bubble Popping Task',
  ST: 'Synchrony Task',
  DGT: 'Delayed Gratification Task',
  PA: 'Parent Assessment',
  PCI: 'PCI',
  DEEP: 'DEEP',
}

export const testNamesToData = {
  MDAT: 'mdat',
  WT: 'wheelTask',
  PLT: 'preferentialLookingTask',
  LST: 'languageSamplingTask',
  MFT: 'motorFollowingTask',
  BT: 'choiceTask',
  CT: 'colouringTask',
  BPT: 'bubblePoppingTask',
  ST: 'synchronyTask',
  DGT: 'delayedGratificationTask',
  PA: 'parentAssessment',
  PCI: 'freeplayPci',
  DEEP: 'deep',
}

export const defaultDownloadStates: TestsRecord = {
  MDAT: true,
  WT: true,
  PLT: true,
  LST: true,
  MFT: true,
  BT: true,
  CT: true,
  BPT: true,
  ST: true,
  DGT: true,
  PA: true,
  PCI: true,
  DEEP: true,
}
