import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <Container component="main">
      <div className={classes.paper}>
        <Typography component="h1" variant="h1">
          404.
        </Typography>
        <Typography component="h1" variant="h5">
          Page not found
        </Typography>
      </div>
    </Container>
  )
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
