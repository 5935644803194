import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Snackbar,
  TextField,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import * as selectors from 'src/redux/selectors'

import { fetchConsentForms, saveConsentForm } from '../../services/Api'
import { AdminPage } from '../AdminPage'

export const ConsentForm = () => {
  const token = useSelector(selectors.getToken)

  const [englishModel, setEnglishModel] = React.useState<string>('')
  const [hindiModel, setHindiModel] = React.useState<string>('')
  const [chichewaModel, setChichewaModel] = React.useState<string>('')
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)

  const save = (text, locale) => {
    if (token) {
      saveConsentForm(token, {
        text,
        locale,
      })
        .then((resp: any) => {
          if (resp.data.success) {
            setSnackbarStatus('success')
          } else {
            setSnackbarStatus('error')
          }
        })
        .catch(() => {
          setSnackbarStatus('error')
        })
    }
  }

  useEffect(() => {
    if (token) {
      fetchConsentForms(token).then((resp: any) => {
        if (resp.data.success) {
          setEnglishModel(resp.data.forms.en || '')
          setHindiModel(resp.data.forms.hi || '')
          setChichewaModel(resp.data.forms.ch || '')
        }
      })
    }
  }, [token])

  const languages = [
    {
      locale: 'en',
      title: 'English',
      updateModel: setEnglishModel,
      value: englishModel,
    },
    {
      locale: 'ch',
      title: 'Chichewa',
      updateModel: setChichewaModel,
      value: chichewaModel,
    },
    {
      locale: 'hi',
      title: 'Hindi',
      updateModel: setHindiModel,
      value: hindiModel,
    },
  ]

  return (
    <AdminPage title={'Consent Forms'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form
            key="form"
            name="consentforms"
            noValidate
            id="consentForm"
            style={{ width: '100%' }}>
            {languages.map((item) => (
              <React.Fragment key={item.locale}>
                <FormControl
                  style={{ width: '100%', marginTop: 25, marginBottom: 25 }}>
                  <FormLabel style={{ paddingBottom: 10 }} htmlFor="english">
                    {item.title}
                  </FormLabel>
                  <TextField
                    key={item.locale}
                    id={item.locale}
                    name={item.locale}
                    multiline
                    rows={15}
                    value={item.value}
                    onChange={(evt: any) => {
                      item.updateModel(evt.target.value)
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => save(item.value, item.locale)}>
                    Save
                  </Button>
                </FormControl>
              </React.Fragment>
            ))}
          </form>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}
