import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

export const DialogLightAndHeavy = ({
  openLightOrHeavyDataDialog,
  setOpenLightOrHeavyDataDialog,
  lightOrHeavyDataModel,
  setLightOrHeavyDataModel,
  downloadSurveys,
  enteredEncryptionKey,
  setOpenEncryptionDialog,
}) => {
  return (
    <Dialog
      open={openLightOrHeavyDataDialog}
      onClose={() => {
        setOpenLightOrHeavyDataDialog(false)
      }}
      aria-labelledby="Download">
      <DialogTitle id="delete-dialog-title">Download</DialogTitle>
      <DialogContent>
        <Grid container justify="space-around">
          <Grid item xs={12}>
            <Typography>
              You can download the light (text) data only, the heavy (video or
              image) data only, or all data.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="light or heavy data"
                name="lightorheavydata"
                value={lightOrHeavyDataModel}
                onChange={(evt) => setLightOrHeavyDataModel(evt.target.value)}>
                <FormControlLabel
                  value="light"
                  control={
                    <Radio checked={lightOrHeavyDataModel === 'light'} />
                  }
                  label="Light only"
                />
                <FormControlLabel
                  value="heavy"
                  control={
                    <Radio checked={lightOrHeavyDataModel === 'heavy'} />
                  }
                  label="Heavy only"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio checked={lightOrHeavyDataModel === 'both'} />}
                  label="All data"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!lightOrHeavyDataModel}
          type="submit"
          variant="contained"
          onClick={() => {
            setOpenLightOrHeavyDataDialog(false)
            if (lightOrHeavyDataModel === 'light') {
              downloadSurveys()
            } else {
              if (enteredEncryptionKey) {
                downloadSurveys()
              } else {
                setOpenEncryptionDialog(true)
              }
            }
          }}
          color="primary">
          <CloudDownloadIcon style={{ marginRight: 5 }} />
          Download
        </Button>
      </DialogActions>
    </Dialog>
  )
}
