import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import * as selectors from 'src/redux/selectors'
import { logUserAction } from 'src/services/Api'

import { editUser, fetchUser, saveUser } from '../../services/Api'
import { AdminPage } from '../AdminPage'

export const NewUser = () => {
  const history = useHistory()
  const token = useSelector(selectors.getToken)
  const authenticatedUser = useSelector(selectors.getUser)
  const [currentRoleLabel, setCurrentRoleLabel] = React.useState<any>(
    'Community Health Worker',
  )
  const [firstNameModel, setFirstNameModel] = React.useState<string>('')
  const [lastNameModel, setLastNameModel] = React.useState<string>('')
  const [usernameModel, setUsernameModel] = React.useState<string>('')
  const [passwordModel, setPasswordModel] = React.useState<string>('')
  const [errorMessage, setErrorMessage] = React.useState<any>(false)
  const [editedUserId, setEditedUserId] = React.useState<any>()
  const [usernameError, setUsernameError] = React.useState<any>(false)
  const [passwordError, setPasswordError] = React.useState<any>(false)
  const [firstNameError, setFirstNameError] = React.useState<any>(false)
  const [lastNameError, setLastNameError] = React.useState<any>(false)
  const [roleModel, setRoleModel] = React.useState<string>(
    'communityHealthWorker',
  )
  const [editedFields, setEditedFields] = React.useState<string[]>([])
  const location = useLocation()

  const changeRole = (event) => {
    if (authenticatedUser === null) return
    setRoleModel(event.target.value)
    roles[authenticatedUser.role].map((role) => {
      if (role.value === event.target.value) {
        setCurrentRoleLabel(role.label)
      }
    })
  }

  const updateEditedFields = (field) => {
    const updatedFields = editedFields
    if (editedFields.indexOf(field) < 0) {
      updatedFields.push(field)
      setEditedFields(updatedFields)
    }
  }

  const validateEmail = (email?: string) => {
    let emailToTest = email
    if (!emailToTest) {
      emailToTest = usernameModel
    }
    if (
      roleModel === 'superAdmin' ||
      roleModel === 'backendResearcher' ||
      roleModel === 'admin'
    ) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (re.test(String(emailToTest).toLowerCase())) {
        setUsernameError(false)
        return true
      } else {
        setUsernameError('Username must be a valid email address.')
        return false
      }
    } else {
      if (emailToTest && emailToTest.length > 0) {
        setUsernameError(false)
        return true
      } else {
        setUsernameError('Username is required.')
        return false
      }
    }
  }

  const validatePassword = (password?: string) => {
    let pwToTest = password
    if (!pwToTest) {
      pwToTest = passwordModel
    }
    if (!pwToTest || pwToTest.length < 8) {
      setPasswordError('Password must be at least 8 characters.')
      return false
    } else {
      setPasswordError(false)
      return true
    }
  }

  const validateField = (value: string, errorSetter?: any) => {
    if (value.length > 0) {
      if (errorSetter) {
        errorSetter(false)
      }
      return true
    }
    if (errorSetter) {
      errorSetter('This field is required.')
    }
    return false
  }

  const formValidation = () => {
    setErrorMessage(false)

    if (!token) {
      window.scrollTo(0, 0)
      setErrorMessage('Token is missing. Please log out and log back in again.')
      return false
    } else {
      let errors = false
      if (!validateField(firstNameModel, setFirstNameError)) {
        errors = true
      }
      if (!validateField(lastNameModel, setLastNameError)) {
        errors = true
      }
      if (!validatePassword()) {
        errors = true
      }
      if (!validateEmail()) {
        errors = true
      }
      if (!errors) {
        setErrorMessage(false)
        return true // form is valid
      } else {
        window.scrollTo(0, 0)
        setErrorMessage(
          'Some fields have errors. Please correct them to continue.',
        )
        return false
      }
    }
  }

  const save = () => {
    if (!formValidation()) {
      return false
    }
    if (editedUserId) {
      editUser(token, {
        id: editedUserId,
        firstName: firstNameModel,
        lastName: lastNameModel,
        username: usernameModel,
        role: roleModel,
        password: passwordModel === '' ? undefined : passwordModel,
      })
        .then((resp: any) => {
          if (resp.data.success) {
            if (authenticatedUser === null) return

            logUserAction(token, {
              username: authenticatedUser.username,
              action:
                editedFields.length > 0
                  ? `Edited ${editedFields.join(
                      ', ',
                    )} of ${currentRoleLabel} ${usernameModel}`
                  : `Edited ${currentRoleLabel} ${usernameModel}`,
            })
            history.push('/system-users')
          }
        })
        .catch((err) => {
          window.scrollTo(0, 0)
          setErrorMessage(err.response.data.message)
        })
    } else {
      saveUser(token, {
        firstName: firstNameModel,
        lastName: lastNameModel,
        username: usernameModel,
        role: roleModel,
        password: passwordModel === '' ? undefined : passwordModel,
      })
        .then((resp: any) => {
          if (resp.data.success) {
            if (authenticatedUser === null) return
            logUserAction(token, {
              username: authenticatedUser.username,
              action: `Added ${currentRoleLabel} ${usernameModel}`,
            })
            history.push('/system-users')
          }
        })
        .catch((err) => {
          window.scrollTo(0, 0)
          setErrorMessage(err.response.data.message)
        })
    }
  }

  useEffect(() => {
    const { state }: any = location
    if (state) {
      const { userId }: any = location.state
      if (userId) {
        if (token) {
          fetchUser(token, userId).then((resp: any) => {
            if (resp.data.success) {
              const { user } = resp.data
              setFirstNameModel(user.firstName)
              setLastNameModel(user.lastName)
              setUsernameModel(user.username)
              setRoleModel(user.role)
              setEditedUserId(user.id)
            }
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <AdminPage title={editedUserId ? `Edit user ${usernameModel}` : 'New user'}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {errorMessage !== false && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <form
            key="newuser"
            name="newuser"
            noValidate
            id="newuser"
            style={{ width: '100%' }}>
            <FormControl>
              <TextField
                id="role"
                select
                label="Role"
                required
                value={roleModel}
                onChange={(role) => {
                  if (editedUserId) {
                    updateEditedFields('role')
                  }
                  changeRole(role)
                }}
                helperText="Please select a role"
                variant="outlined">
                {authenticatedUser !== null &&
                  roles[authenticatedUser.role].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
            <FormControl
              style={{ width: '100%', marginTop: 25, marginBottom: 25 }}>
              <TextField
                key="firstName"
                id="firstName"
                name="firstName"
                error={firstNameError !== false}
                helperText={firstNameError}
                required
                label="First Name"
                value={firstNameModel}
                onChange={(evt: any) => {
                  if (editedUserId) {
                    updateEditedFields('first name')
                  }
                  setFirstNameModel(evt.target.value)
                }}
                onBlur={(evt: any) => {
                  validateField(evt.target.value, setFirstNameError)
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl
              style={{ width: '100%', marginTop: 25, marginBottom: 25 }}>
              <TextField
                key="lastName"
                id="lastName"
                required
                error={lastNameError !== false}
                helperText={lastNameError}
                name="lastName"
                label="Last Name"
                value={lastNameModel}
                onChange={(evt: any) => {
                  if (editedUserId) {
                    updateEditedFields('last name')
                  }
                  setLastNameModel(evt.target.value)
                }}
                onBlur={(evt: any) => {
                  validateField(evt.target.value, setLastNameError)
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl
              style={{ width: '100%', marginTop: 25, marginBottom: 25 }}>
              <TextField
                key="username"
                id="username"
                required
                name="username"
                label="Username"
                error={usernameError !== false}
                helperText={usernameError}
                value={usernameModel}
                onChange={(evt: any) => {
                  if (editedUserId) {
                    updateEditedFields('username')
                  }
                  setUsernameModel(evt.target.value)
                }}
                onBlur={(evt) => {
                  validateEmail(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl
              style={{ width: '100%', marginTop: 25, marginBottom: 25 }}>
              <TextField
                key="password"
                id="password"
                name="password"
                value={passwordModel}
                error={passwordError !== false}
                helperText={passwordError}
                required
                type="password"
                label={editedUserId ? 'Enter new password' : 'Password'}
                onChange={(evt: any) => {
                  if (editedUserId) {
                    updateEditedFields('password')
                  }
                  setPasswordModel(evt.target.value)
                }}
                onBlur={(evt: any) => {
                  validatePassword(evt.target.value)
                }}
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <Button variant="contained" color="primary" onClick={save}>
                Save
              </Button>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const roles = {
  admin: [
    {
      label: 'Community Health Worker',
      value: 'communityHealthWorker',
    },
  ],
  superAdmin: [
    {
      label: 'Community Health Worker',
      value: 'communityHealthWorker',
    },
    {
      label: 'Super Admin',
      value: 'superAdmin',
    },
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'Backend Researcher',
      value: 'backendResearcher',
    },
  ],
}
