import React from 'react'
import { useSelector } from 'react-redux'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import * as selectors from 'src/redux/selectors'

import { PdfContainer } from '../components/PdfContainer'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const UserGuide = () => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)

  return (
    <AdminPage title="User guide">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">PDF upload</Typography>
          <Grid container spacing={2}>
            <Grid style={{ minWidth: 650, marginTop: 20 }}>
              <PdfContainer pdf={pdfs.userGuide} token={token} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const pdfs = {
  userGuide: {
    id: 'aboutStream',
    title: 'User Guide',
    file: 'about-stream',
  },
}
