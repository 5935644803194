import React from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import {
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import * as selectors from 'src/redux/selectors'

const menuItems = [
  {
    label: 'Home',
    link: '/',
    roles: ['superAdmin', 'admin', 'backendResearcher'],
  },
  {
    label: 'Survey list',
    link: '/surveys',
    roles: ['superAdmin', 'admin', 'backendResearcher'],
  },
  {
    label: 'System users',
    link: '/system-users',
    roles: ['superAdmin', 'admin'],
  },
  {
    label: 'Assessment Content',
    link: '/assessment-content',
    roles: ['superAdmin'],
    subLinks: ['/age-based-filtration', '/choice-task'],
  },
  { label: 'Logs', link: '/logs', roles: ['superAdmin'] },
]

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      /*width: drawerWidth,*/
      width: 0,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

export const LeftMenu = (props) => {
  const { window } = props
  const user = useSelector(selectors.getUser)
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const location = useLocation()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {menuItems.map(({ label, link, subLinks, roles }) => {
          let isSelected = location.pathname === link
          if (subLinks && subLinks.includes(location.pathname))
            isSelected = true
          if (user && roles.indexOf(user.role) > -1) {
            return (
              <ReactRouterLink key={label} to={link}>
                <ListItem selected={isSelected} button key={label}>
                  <ListItemText>{label}</ListItemText>
                </ListItem>
              </ReactRouterLink>
            )
          } else {
            return <></>
          }
        })}
      </List>
      <Divider />
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <nav className={classes.drawer} aria-label="menu">
      {/* Mobile menu */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {drawer}
        </Drawer>
      </Hidden>
      {/* Desktop menu */}
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open>
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  )
}
