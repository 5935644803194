import React from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

export const DialogTermsAndConditions = ({
  openTCDialog,
  setOpenTCDialog,
  TCAccepted,
  setTCAccepted,
  acceptanceButtonText,
  onDownload,
}: {
  openTCDialog: boolean
  setOpenTCDialog: React.Dispatch<React.SetStateAction<boolean>>
  TCAccepted: boolean
  setTCAccepted: React.Dispatch<React.SetStateAction<boolean>>
  acceptanceButtonText: string
  onDownload: () => void
}) => {
  return (
    <Dialog
      open={openTCDialog}
      onClose={() => {
        setOpenTCDialog(false)
      }}
      aria-labelledby="Terms &amp; Conditions">
      <DialogTitle id="terms-conditions-dialog-title">
        Terms &amp; Conditions
      </DialogTitle>
      <DialogContent>
        <Grid container justify="space-around">
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>
              <strong>1. Definitions</strong>
            </Typography>
            <Typography style={{ marginTop: 10 }}>
              Data Processor shall mean the user of the STREAM backend who
              downloads personal data;
              <br />
              Data Controller shall have the meaning given to those terms in the
              applicable Data Protection Laws. In the context of this document,
              all parties noted within the Academic Collaboration Agreement
              shall act as a Data Controller;
              <br />
              Data Protection Laws shall mean (a) any law, statute, declaration,
              decree, directive, legislative enactment, order, ordinance,
              regulation, rule or other binding restriction (as amended,
              consolidated or re-enacted from time to time) in any jurisdiction
              which relates to the protection of individuals with regard to the
              processing of Personal Data to which a Data Processor or
              Controller is subject, including the Data Protection Act 2018
              (“DPA”) and the General Data Protection Regulation (“GDPR”); and
              (b) any code of practice or guidance published by the UK
              Information Commissioner’s Office (or equivalent regulatory body)
              from time to time;
              <br />
              Personal Data means any personal data (as defined in the Data
              Protection Laws) processed by either the Data Processor or Data
              Controller. In the context of this document, the Data Processor
              will process data in relation to video footage obtained using the
              in-built camera of the STREAM mobile platform.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>
              <strong>2. Data Processor Obligations</strong>
            </Typography>
            <Typography style={{ marginTop: 10 }}>
              2.1. The Data Processor agrees that the processing and storing of
              Personal Data from the STREAM backend will be be carried out in
              accordance with the applicable Data Protection Laws of the
              jurisdiction in which the user is established, and does not
              violate relevant provisions of that jurisdiction with regard to
              the handling of Personal Data;
              <br />
              2.2. The Data Processor is responsible for the safe processing,
              handling, and storing of Personal Data downloaded from the STREAM
              backend;
              <br />
              2.3. The Data Processor agrees to ensure that appropriate
              technical and organisational security measures are in place for
              the security of Personal Data to comply with the obligations
              imposed by the Data Protection Laws;
              <br />
              2.4. The Data Processor agrees to not share downloaded Personal
              Data with, or make available to, any third party;
              <br />
              2.5. The Data Processor agrees to not create additional copies of
              the Personal Data;
              <br />
              2.6. The Data Processor agrees to permanently delete all Personal
              Data at the request of the Data Controller;
              <br />
              2.7. The Data Processor agrees to permanently delete all
              downloaded Personal Data upon conclusion of the analysis for which
              the personal data was required.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginTop: 10 }}
              control={
                <Checkbox
                  checked={TCAccepted}
                  onChange={(evt) => {
                    setTCAccepted(evt.target.checked)
                  }}
                  name="tcaccepted"
                  color="primary"
                />
              }
              label={
                <Typography style={{ fontSize: 20 }}>
                  I agree to the above terms and conditions
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!TCAccepted}
          type="submit"
          variant="contained"
          onClick={onDownload}
          color="primary">
          <CloudDownloadIcon style={{ marginRight: 5 }} />
          {acceptanceButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
