import React from 'react'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'

import { VideoContainer } from '../components/VideoContainer'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const PreferentialLookingTask = () => {
  const classes = useStyles()

  return (
    <AdminPage title="Preferential Looking Task">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Demo</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.demoSocial1} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.demoNonSocial1} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Test</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.social1} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.nonSocial1} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.social2} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.nonSocial2} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.social3} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.nonSocial3} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.social4} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.nonSocial4} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.social5} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.nonSocial5} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <VideoContainer video={videos.social6} />
            </Grid>
            <Grid md={6} item>
              <VideoContainer video={videos.nonSocial6} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const videos = {
  demoSocial1: {
    id: 'preferentialLookingTaskDemoSocialVideo1',
    title: 'Social Video 1',
    file: 'preferential-looking-task-demo-social-video-1',
  },
  demoNonSocial1: {
    id: 'preferentialLookingTaskDemoNonSocialVideo1',
    title: 'Non Social Video 1',
    file: 'preferential-looking-task-demo-non-social-video-1',
  },
  social1: {
    id: 'preferentialLookingTaskSocialVideo1',
    title: 'Social Video 1',
    file: 'preferential-looking-task-social-video-1',
  },
  nonSocial1: {
    id: 'preferentialLookingTaskNonSocialVideo1',
    title: 'Non Social Video 1',
    file: 'preferential-looking-task-non-social-video-1',
  },
  social2: {
    id: 'preferentialLookingTaskSocialVideo2',
    title: 'Social Video 2',
    file: 'preferential-looking-task-social-video-2',
  },
  nonSocial2: {
    id: 'preferentialLookingTaskNonSocialVideo2',
    title: 'Non Social Video 2',
    file: 'preferential-looking-task-non-social-video-2',
  },
  social3: {
    id: 'preferentialLookingTaskSocialVideo3',
    title: 'Social Video 3',
    file: 'preferential-looking-task-social-video-3',
  },
  nonSocial3: {
    id: 'preferentialLookingTaskNonSocialVideo3',
    title: 'Non Social Video3',
    file: 'preferential-looking-task-non-social-video-3',
  },
  social4: {
    id: 'preferentialLookingTaskSocialVideo4',
    title: 'Social Video 4',
    file: 'preferential-looking-task-social-video-4',
  },
  nonSocial4: {
    id: 'preferentialLookingTaskNonSocialVideo4',
    title: 'Non Social Video 4',
    file: 'preferential-looking-task-non-social-video-4',
  },
  social5: {
    id: 'preferentialLookingTaskSocialVideo5',
    title: 'Social Video 5',
    file: 'preferential-looking-task-social-video-5',
  },
  nonSocial5: {
    id: 'preferentialLookingTaskNonSocialVideo5',
    title: 'Non Social Video 5',
    file: 'preferential-looking-task-non-social-video-5',
  },
  social6: {
    id: 'preferentialLookingTaskSocialVideo6',
    title: 'Social Video 6',
    file: 'preferential-looking-task-social-video-6',
  },
  nonSocial6: {
    id: 'preferentialLookingTaskNonSocialVideo6',
    title: 'Non Social Video 6',
    file: 'preferential-looking-task-non-social-video-6',
  },
}
