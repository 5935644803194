import React from 'react'
import { Button } from '@material-ui/core'

export const KeepRemoveButtons = ({
  onClick,
  flagged,
}: {
  onClick: (value: boolean) => void
  flagged: boolean
}) => {
  const [selected, setSelected] = React.useState(flagged || false)

  // React.useEffect(()=> {
  //   subscribe()
  // },[subscribe])

  return (
    <Button
      variant={selected ? 'contained' : 'text'}
      color={selected ? 'primary' : 'secondary'}
      onClick={() => {
        setSelected((oldVal) => {
          onClick(!oldVal)
          return !oldVal
        })
      }}>
      {selected ? 'Keep' : 'Remove'}
    </Button>
  )
}
