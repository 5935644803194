import React from 'react'
import { useSelector } from 'react-redux'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import * as selectors from 'src/redux/selectors'

import { MediaContainer } from '../components/MediaContainer'
import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const LanguageSamplingTask = () => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)

  return (
    <AdminPage title="Language sampling task">
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Demo</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <MediaContainer media={media.media1} token={token} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Test</Typography>
          <Grid container spacing={2}>
            <Grid md={6} item>
              <MediaContainer media={media.media2} token={token} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  )
}

const media = {
  media1: {
    id: 'languageSamplingTaskMedia1',
    title: 'Media 1',
    file: 'language-sampling-task-media-1',
  },
  media2: {
    id: 'languageSamplingTaskMedia2',
    title: 'Media 2',
    file: 'language-sampling-task-media-2',
  },
}
