import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import retry from 'async-retry'
import { DialogTermsAndConditions } from 'src/pages/SurveyResults/DialogTermsAndConditions'
import { testNamesToData } from 'src/pages/SurveyResults/staticValues'
import * as actions from 'src/redux/actions'
import * as selectors from 'src/redux/selectors'

import { env } from '../env'
import {
  checkEncryptionKeyIsCorrect,
  fetchDEEPGames,
  fetchDEEPPreview,
  fetchPreviewFileIdsAndMetadata,
  logUserAction,
  makeHeavyPreviewRequest,
  pollDownloadRequest,
} from '../services/Api'
import { AdminPage } from './AdminPage'
const baseUrl = env.apiBaseUrl

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  mediaContainer: {
    display: 'flex',
  },
  lightDataPreview: {
    maxWidth: '100%',
    padding: 30,
    background: 'transparent',
    overflow: 'auto',
  },
  mainCol: {
    minWidth: 100,
  },
  image: {
    maxWidth: 500,
    width: 'auto',
    height: 'auto',
    margin: 20,
  },
})

export const ViewSurveyData = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectors.getUser)
  const token = useSelector(selectors.getToken)
  const TCAcceptance = useSelector(selectors.getTCAcceptance)
  const [testId, setTestId] = React.useState<any>()
  const [attemptId, setAttemptId] = React.useState<string | null>(null)
  const [error, setError] = React.useState<boolean>(false)
  const enteredEncryptionKey = useSelector(selectors.getEncryptionKey)
  const [
    showDEEPGameSelector,
    setShowDEEPGameSelector,
  ] = React.useState<boolean>(false)
  const [DEEPGameModel, setDEEPGameModel] = React.useState<string>('')
  const [DEEPGames, setDEEPGames] = React.useState<string[]>([])
  const classes = useStyles()
  const fileList = React.useRef<any>([])
  const [video1, setVideo1] = React.useState<any>()
  const [snackbarStatus, setSnackbarStatus] = React.useState<string | null>(
    null,
  )
  const [audio1, setAudio1] = React.useState<any>()
  const [images, setImages] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [wrongKeyWarningVisible, setWrongKeyWarningVisible] = React.useState(
    false,
  )
  const [surveyData, setSurveyData] = React.useState<any>()
  const fileDownloadIds = React.useRef<any>({})
  const [fileModel, setFileModel] = React.useState<any>()
  const [lightDataPreview, setLightDataPreview] = React.useState<any>(null)
  const [surveyId, setSurveyId] = React.useState<string | null>(null)
  const [openEncryptionDialog, setOpenEncryptionDialog] = React.useState(false)
  const [TCAccepted, setTCAccepted] = React.useState(TCAcceptance || false)
  const [openTCDialog, setOpenTCDialog] = React.useState(false)
  const location = useLocation()

  const previewMedia = async (fileId) => {
    if (!token) return
    try {
      const resp = await makeHeavyPreviewRequest(token, {
        storedKey: enteredEncryptionKey,
        fileId,
      })
      retry(
        async () => {
          fileDownloadIds.current[fileId] = resp.data.downloadLinkId
          let fileName = resp.data.fileName
          let fileType = resp.data.fileType

          const response = await pollDownloadRequest(
            token,
            fileDownloadIds.current[fileId],
          )
          if (response.status !== 202) return
          const newMedia = {
            fileId,
            fileType,
            link: `${baseUrl}/download/preview/media/id/${fileDownloadIds.current[fileId]}?fileName=${fileName}&fileType=${fileType}&token=${token}`,
          }
          fileList.current.push(newMedia)
          if (newMedia.fileType.indexOf('video') > -1) {
            const newVideos = fileList.current
            setLoading(true)
            setTimeout(() => {
              setVideo1(newVideos)
              setLoading(false)
            }, 1000)
          }
          if (newMedia.fileType.indexOf('audio') > -1) {
            setLoading(true)
            setTimeout(() => {
              setAudio1(newMedia)
              setLoading(false)
            }, 1000)
          }
          if (newMedia.fileType.indexOf('image') > -1) {
            setImages((val) => val.concat(newMedia))
          }
        },
        {
          maxTimeout: 1000,
          retries: 30,
        },
      )
    } catch (err) {
      console.log(err.response.data.message)
      setSnackbarStatus(err.response.data.message)
      setError(true)
    }
  }

  const getPreviewFileIdsAndMetadata = async (
    surveyIdLocal,
    testIdLocal,
    attemptIdLocal,
  ) => {
    if (token) {
      const testRequest = {
        [surveyIdLocal]: {
          [testIdLocal]: true,
        },
      }

      try {
        const resp = await fetchPreviewFileIdsAndMetadata(token, {
          surveyIds: [surveyIdLocal],
          testsRequested: testRequest,
          attemptId: attemptIdLocal,
        })
        if (resp.data && resp.data.fileIds && resp.data.fileIds.length) {
          for (const fileId of resp.data.fileIds) {
            await previewMedia(fileId)
          }
        }

        let dbRows: any[] = []
        let attemptInfo = {}
        const testMetaData = resp.data.metadata[testNamesToData[testIdLocal]]
        const surveyAttemptData = testMetaData[surveyIdLocal][attemptIdLocal]
        console.log(surveyAttemptData)
        const dataKeys = Object.keys(surveyAttemptData)
        dataKeys.forEach((dataKey) => {
          if (dataKey === 'metaData') {
            dbRows = surveyAttemptData[dataKey]
            return
          }
          attemptInfo[dataKey] = surveyAttemptData[dataKey]
        })

        if (dbRows && attemptInfo) {
          setSurveyData({ dbRows, attemptInfo })
        }
      } catch (err) {
        console.log('****', err)
        setError(true)
      }
    }
  }

  const getDEEPGames = async (surveyIdLocal, attemptIdLocal) => {
    try {
      const resp = await fetchDEEPGames(token, surveyIdLocal, attemptIdLocal)
      setDEEPGames(resp.data)
      setShowDEEPGameSelector(true)
    } catch (err) {
      console.log('****', err)
      setError(true)
    }
  }

  const previewDEEP = async (surveyIdLocal, attemptIdLocal, gameId) => {
    try {
      const resp = await fetchDEEPPreview(
        token,
        surveyIdLocal,
        attemptIdLocal,
        gameId,
      )
      setLightDataPreview(resp.data)
    } catch (err) {
      console.log('****', err)
    }
  }

  useEffect(() => {
    if (!surveyId && !testId && !attemptId) {
      const { state }: any = location
      if (!state) return
      if (state.surveyId && state.testId && state.attemptId) {
        setSurveyId(state.surveyId)
        setTestId(state.testId)
        setAttemptId(state.attemptId)
        if (state.testId === 'DEEP') {
          getDEEPGames(state.surveyId, state.attemptId)
          return
        }
        if (state.privateKeyFile) {
          setFileModel(state.privateKeyFile)
          getPreviewFileIdsAndMetadata(
            state.surveyId,
            state.testId,
            state.attemptId,
          )
        }
        if (state.encryptionKeyNeeded) {
          if (enteredEncryptionKey) {
            getPreviewFileIdsAndMetadata(
              state.surveyId,
              state.testId,
              state.attemptId,
            )
          } else {
            setOpenEncryptionDialog(true)
          }
        } else {
          getPreviewFileIdsAndMetadata(
            state.surveyId,
            state.testId,
            state.attemptId,
          )
        }
        // previewExcel(state.surveyId, testId, attemptId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, video1, images, surveyData])

  return (
    <AdminPage title="Preview Survey Attempt Data">
      <Grid container spacing={3}>
        {error && (
          <Typography
            variant="h6"
            style={{
              textAlign: 'center',
              color: 'grey',
              width: '100%',
            }}>
            Not all data could be retrieved
          </Typography>
        )}
        <Grid item xs={12} className={classes.mediaContainer}>
          {/* width = width of the browser minus the width of the left menu bar and padding */}
          <Grid container style={{ width: window.innerWidth - 300 }}>
            {loading && <CircularProgress />}
            {audio1 && (
              <audio
                style={{
                  width: '100%',
                  margin: 5,
                  outline: 'none',
                }}
                controls
                controlsList="nodownload"
                preload="none">
                <source src={audio1.link} type="audio/wav" />
              </audio>
            )}
            {video1 &&
              video1.map((video, index) => {
                return (
                  <Grid key={index} item xs={6} style={{ padding: 15 }}>
                    <Typography variant="h3" style={{ textAlign: 'center' }}>
                      {video.fileId && video.fileId.split('_')[2]}
                    </Typography>
                    <video
                      width="100%"
                      style={{
                        maxHeight: 450,
                        minHeight: 450,
                      }}
                      controls
                      //ref={videoRef}
                      preload="metadata">
                      <source src={video.link} type="video/mp4" />
                    </video>
                  </Grid>
                )
              })}
            {images &&
              images.map((item, key) => (
                <img
                  key={key}
                  id={`image-${key}`}
                  className={classes.image}
                  src={item.link}
                />
              ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {showDEEPGameSelector && (
            <TextField
              id="game"
              select
              label="Game ID"
              required
              style={{ width: 320 }}
              value={DEEPGameModel}
              onChange={(evt) => {
                setDEEPGameModel(evt.target.value)
                previewDEEP(surveyId, attemptId, evt.target.value)
              }}
              helperText="Please select a game"
              variant="outlined">
              {DEEPGames.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          {surveyData && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="App">
                <TableBody>
                  <>
                    {Object.keys(surveyData.attemptInfo).map((infoKey, ind) => {
                      const infoValue = surveyData.attemptInfo[infoKey]
                      const infoValueString =
                        typeof infoValue === 'boolean'
                          ? infoValue.toString()
                          : infoValue

                      return (
                        <TableRow key={`meta-column-${ind}`}>
                          <TableCell style={{ border: 0 }}>{infoKey}</TableCell>
                          <TableCell style={{ border: 0 }}>
                            {infoValueString}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {/* Separator row */}
                    <TableRow>
                      <TableCell style={{ border: 0, height: 75 }} />
                    </TableRow>
                    {/* Metadata */}
                    <TableRow>
                      {/* row column headers */}
                      {surveyData &&
                        surveyData.dbRows &&
                        Array.isArray(surveyData.dbRows) &&
                        surveyData.dbRows[0] &&
                        Object.keys(surveyData.dbRows[0])
                          .sort()
                          .map((col) => {
                            return (
                              <>
                                <>
                                  <TableCell style={{ border: 0 }}>
                                    {col}
                                  </TableCell>
                                </>
                              </>
                            )
                          })}
                    </TableRow>
                    {surveyData &&
                      surveyData.dbRows &&
                      Array.isArray(surveyData.dbRows) &&
                      surveyData.dbRows?.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          style={{ width: '100%', border: 1 }}>
                          {Object.keys(surveyData.dbRows[rowIndex])
                            .sort()
                            .map((col) => (
                              <>
                                <TableCell style={{ border: 0 }}>
                                  {surveyData.dbRows[rowIndex][col]}
                                </TableCell>
                              </>
                            ))}
                        </TableRow>
                      ))}
                    {surveyData.dbRows &&
                      !Array.isArray(surveyData.dbRows) &&
                      Object.keys(surveyData.dbRows).map((category) => (
                        <>
                          {/* If metadata.dbRows is an object with multiple arrays,
                            meaning that there are several database tables to be displayed,
                            the column headers are rendered here */}
                          <TableRow>
                            {surveyData.dbRows[category][0] &&
                              Object.keys(surveyData.dbRows[category][0]).map(
                                (rowLabel, ind) => {
                                  return (
                                    <TableCell key={ind} style={{ border: 0 }}>
                                      {rowLabel}
                                    </TableCell>
                                  )
                                },
                              )}
                          </TableRow>
                          {surveyData.dbRows[category].map((row, rowIndex) => (
                            <>
                              <TableRow>
                                {Object.keys(
                                  surveyData.dbRows[category][rowIndex],
                                ).map((col) => (
                                  <>
                                    <>
                                      <TableCell style={{ border: 0 }}>
                                        {
                                          surveyData.dbRows[category][rowIndex][
                                            col
                                          ]
                                        }
                                      </TableCell>
                                    </>
                                  </>
                                ))}
                              </TableRow>
                              {/* Separator row */}
                              <TableRow>
                                <TableCell style={{ border: 0, height: 75 }} />
                              </TableRow>
                            </>
                          ))}
                        </>
                      ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {lightDataPreview && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="App">
                <TableBody>
                  {lightDataPreview &&
                    lightDataPreview.map((row) => (
                      <>
                        {Object.keys(row).map((key, ind) => (
                          <TableRow key={ind}>
                            <TableCell style={{ padding: 11, width: 100 }}>
                              {key}
                            </TableCell>

                            {lightDataPreview.map((subRow, innerInd) => {
                              const data =
                                typeof subRow[key] === 'string' ||
                                typeof subRow[key] === 'number'
                                  ? subRow[key]
                                  : JSON.stringify(subRow[key])
                              return (
                                <TableCell
                                  key={innerInd}
                                  style={{ padding: 11 }}>
                                  {data}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        ))}
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openEncryptionDialog}
        onClose={() => {
          setOpenEncryptionDialog(false)
        }}
        aria-labelledby="Download">
        <DialogTitle id="delete-dialog-title">Preview Survey Data</DialogTitle>
        <DialogContent>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <Typography>
                To view a decrypted version of the survey data, you must enter
                the decrypt key and agree to the Terms and Conditions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ marginTop: 10 }}>
                Upload the decrypt key
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                <input
                  //className={classes.input}
                  style={{ display: 'none' }}
                  id="encryptionupload"
                  type="file"
                  onChange={(evt) => {
                    if (
                      evt &&
                      evt.target &&
                      evt.target.files &&
                      evt.target.files.length
                    ) {
                      setFileModel(evt.target.files[0])
                    }
                  }}
                />
                <label htmlFor="encryptionupload">
                  <Button variant="contained" color="primary" component="span">
                    {fileModel && `Choose a different file`}
                    {!fileModel && `Choose file`}
                  </Button>
                </label>
                {fileModel && (
                  <Typography>File chosen: {fileModel.name}</Typography>
                )}
                {wrongKeyWarningVisible && (
                  <Typography style={{ color: 'red' }}>
                    That doesn't seem to be the correct key
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!fileModel}
            type="submit"
            variant="contained"
            onClick={async () => {
              //

              const objectUrl = URL.createObjectURL(fileModel)
              const keyContents = await fetch(objectUrl).then((res) =>
                res.text(),
              )
              const resp = await checkEncryptionKeyIsCorrect(token, {
                privateKey: keyContents,
              })
              console.log(resp?.data)
              if (resp?.data?.isCorrect) {
                setWrongKeyWarningVisible(false)
                setOpenEncryptionDialog(false)
                dispatch(actions.enteredEncryptionKey({ key: keyContents }))

                if (!TCAcceptance) {
                  setOpenTCDialog(true)
                } else {
                  getPreviewFileIdsAndMetadata(surveyId, testId, attemptId)
                }
                return
              }
              setWrongKeyWarningVisible(true)
            }}
            color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <DialogTermsAndConditions
        openTCDialog={openTCDialog}
        setOpenTCDialog={setOpenTCDialog}
        TCAccepted={TCAccepted}
        setTCAccepted={setTCAccepted}
        acceptanceButtonText={'Preview data'}
        onDownload={() => {
          if (TCAccepted) {
            dispatch(actions.acceptedTermsAndConditions())
            if (user === null) return

            logUserAction(token, {
              username: user.username,
              action: `Accepted Terms & Conditions`,
            })
            setOpenTCDialog(false)
            getPreviewFileIdsAndMetadata(surveyId, testId, attemptId)
          }
        }}
      />
      <Snackbar
        open={snackbarStatus !== null}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(null)}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(null)}
          severity={'error'}>
          {snackbarStatus}
        </Alert>
      </Snackbar>
    </AdminPage>
  )
}
