import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
} from '@material-ui/core'
import retry from 'async-retry'
import * as actions from 'src/redux/actions'
import * as selectors from 'src/redux/selectors'
import { checkReEncryptionStatus, reEncryptAllSurveys } from 'src/services/Api'

export const ReEncryptionDialog = ({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [fileModel, setFileModel] = React.useState<any>()
  const dispatch = useDispatch()
  const isCurrentlyReEncrypting = useSelector(selectors.getIsReEncryptingStatus)

  return (
    <Dialog
      open={isVisible}
      onClose={() => {
        setIsVisible(false)
      }}
      aria-labelledby="ReEncrypt">
      <DialogTitle id="delete-dialog-title">Re encrypt</DialogTitle>
      <DialogContent>
        <Grid container justify="space-around">
          <Grid item xs={12}>
            <Typography>
              To re-encrypt all surveys the old private key needs to be provided
              so that they can all be decrypted before a re-encryption can be
              done with the new lock key.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>
              Upload the decrypt key
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
              <input
                //className={classes.input}
                style={{ display: 'none' }}
                id="encryptionupload"
                type="file"
                onChange={(evt) => {
                  if (
                    evt &&
                    evt.target &&
                    evt.target.files &&
                    evt.target.files.length
                  ) {
                    setFileModel(evt.target.files[0])
                  }
                }}
              />
              <label htmlFor="encryptionupload">
                <Button variant="contained" color="primary" component="span">
                  {fileModel && `Choose a different file`}
                  {!fileModel && `Choose file`}
                </Button>
              </label>
              {fileModel && (
                <Typography>File chosen: {fileModel.name}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!fileModel || isCurrentlyReEncrypting}
          type="submit"
          variant="contained"
          onClick={async () => {
            const objectUrl = URL.createObjectURL(fileModel)
            const keyContents = await fetch(objectUrl).then((res) => res.text())
            dispatch(actions.isReEncrypting({ status: true }))
            reEncryptAllSurveys(keyContents)
            retry(
              async () => {
                const response = await checkReEncryptionStatus()
                console.log('***resp', response.data)
                if (response.data.status === 'done') {
                  setIsVisible(false)
                  dispatch(actions.isReEncrypting({ status: false }))
                  return
                }
                throw Error('not finished yet')
              },
              {
                maxTimeout: 5000,
                retries: 120,
              },
            )
          }}
          color="primary">
          {!isCurrentlyReEncrypting ? 'Continue' : 'Processing...'}
        </Button>
        {isCurrentlyReEncrypting && <CircularProgress size={25} />}
      </DialogActions>
    </Dialog>
  )
}
