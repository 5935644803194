import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Avatar,
  CircularProgress,
  Container,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LockOutlined from '@material-ui/icons/LockOutlined'
import Alert from '@material-ui/lab/Alert'

import { createNewPassword } from '../services/Api'

export const CreateNewPassword = () => {
  const history = useHistory()

  const [newPassword, setNewPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [isResettingPassword, setIsResettingPassword] = React.useState<boolean>(
    false,
  )
  const [resetPasswordError, setResetPasswordError] = React.useState<any>(false)
  const [passwordError, setPasswordError] = React.useState<any>('')
  const [passwordReset, setPasswordReset] = React.useState<boolean>(false)

  let { search } = useLocation()

  const query = new URLSearchParams(search)
  const token = query.get('token')

  const changePassword = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match.')
      return false
    }
    if (newPassword.length < 8) {
      setPasswordError('Password must be at least 8 characters.')
      return false
    }

    try {
      const response = await createNewPassword(token, newPassword)

      const { result } = response.data
      if (!result) {
        setResetPasswordError(true)
      }

      if (result) {
        setPasswordReset(true)
        setIsResettingPassword(false)
      }
    } catch (err) {
      let message = typeof err === 'string' ? err : 'Oops, something went wrong'
      if (err.response && err.response.data && err.response.data.message)
        message = err.response.data.message

      setPasswordError(message)
    }
  }

  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar>
          <LockOutlined />
        </Avatar>
        {passwordReset && (
          <React.Fragment>
            <Alert
              severity="success"
              style={{ marginBottom: 15, marginTop: 15 }}>
              Your password has been reset. Please press the button below to be
              taken back to the login screen.
            </Alert>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => history.push('/login')}>
              Go back to login
            </Button>
          </React.Fragment>
        )}
        {!passwordReset && (
          <React.Fragment>
            {passwordError && (
              <Alert
                severity="error"
                style={{ marginBottom: 15, marginTop: 15 }}>
                {passwordError}
              </Alert>
            )}
            {resetPasswordError && (
              <Alert
                severity="error"
                style={{ marginBottom: 15, marginTop: 15 }}>
                An error occurred. Your password was not changed.
              </Alert>
            )}
            <Typography component="h1" variant="h5">
              Change your password
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={(evt) => {
                evt.preventDefault()
                changePassword()
              }}>
              <TextField
                value={newPassword}
                type="password"
                onChange={(e: any) => setNewPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="new_password"
                label="New password"
                name="new_password"
              />
              <TextField
                value={confirmPassword}
                type="password"
                onChange={(e: any) => setConfirmPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confirm_password"
                label="Confirm password"
                name="confirm_password"
              />
              <Button
                disabled={isResettingPassword}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}>
                {isResettingPassword ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  'Change password'
                )}
              </Button>
              {resetPasswordError && (
                <Typography color="error" align="center">
                  {resetPasswordError}
                </Typography>
              )}
            </form>
          </React.Fragment>
        )}
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: '2.5rem',
  },
}))
