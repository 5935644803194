import React from 'react'
import { isEmpty, omit } from 'lodash'
import { defaultDownloadStates } from 'src/pages/SurveyResults/staticValues'
import { SurveyResultEvent, TestsRecord } from 'src/pages/SurveyResults/types'
import { Survey } from 'src/types'

export const surveyDownloadDataManager = ({
  event,
  surveyIdsForDownload,
  surveysFlaggedForRemoval,
  surveys,
}: {
  event: SurveyResultEvent
  surveyIdsForDownload: React.MutableRefObject<Record<string, TestsRecord>>
  surveysFlaggedForRemoval: React.MutableRefObject<string[]>
  surveys: Survey[]
}) => {
  switch (event.type) {
    //--------------------------------------------------------------

    case 'select-all': {
      const allValues = surveys.reduce<Record<string, TestsRecord>>(
        (acc, item) => {
          acc[item.surveyId] = defaultDownloadStates
          return acc
        },
        {},
      )
      surveyIdsForDownload.current = allValues
      console.log('SEL ALL', Object.keys(surveyIdsForDownload.current))
      surveysFlaggedForRemoval.current = Object.keys(allValues)
      break
    }

    //--------------------------------------------------------------

    case 'deselect-all':
      surveyIdsForDownload.current = {}
      surveysFlaggedForRemoval.current = []
      break

    //--------------------------------------------------------------

    case 'select-column': {
      const newItems = surveys.reduce<Record<string, TestsRecord>>(
        (acc, item) => {
          acc[item.surveyId] = {
            ...acc[item.surveyId],
            [event.payload.name]: true,
          }
          return acc
        },
        surveyIdsForDownload.current,
      )
      surveyIdsForDownload.current = newItems
      break
    }

    //--------------------------------------------------------------

    case 'deselect-column': {
      const newItems = surveys.reduce<Record<string, TestsRecord>>(
        (acc, item) => {
          acc[item.surveyId] = omit(acc[item.surveyId], [event.payload.name])
          if (isEmpty(acc[item.surveyId])) {
            const removedSurveyId = omit(acc, [item.surveyId])
            return removedSurveyId
          }
          return acc
        },
        surveyIdsForDownload.current,
      )
      surveyIdsForDownload.current = newItems
      break
    }

    //--------------------------------------------------------------

    case 'select-row':
      surveyIdsForDownload.current = {
        ...surveyIdsForDownload.current,
        [event.payload.id]: defaultDownloadStates,
      }
      surveysFlaggedForRemoval.current = addToArray(
        surveysFlaggedForRemoval.current,
        event.payload.id,
      )
      break

    //--------------------------------------------------------------

    case 'deselect-row':
      surveyIdsForDownload.current = omit(surveyIdsForDownload.current, [
        event.payload.id,
      ])
      surveysFlaggedForRemoval.current = removeFromArray(
        surveysFlaggedForRemoval.current,
        event.payload.id,
      )
      break
    //--------------------------------------------------------------

    case 'update-individual-item':
      if (event.payload.value) {
        surveyIdsForDownload.current = {
          ...surveyIdsForDownload.current,
          [event.payload.surveyId]: {
            ...surveyIdsForDownload.current[event.payload.surveyId],
            [event.payload.name]: true,
          },
        }
      } else {
        surveyIdsForDownload.current = {
          ...surveyIdsForDownload.current,
          [event.payload.surveyId]: omit(
            surveyIdsForDownload.current[event.payload.surveyId],
            [event.payload.name],
          ),
        }
      }
      if (isEmpty(surveyIdsForDownload.current[event.payload.surveyId])) {
        surveyIdsForDownload.current = omit(surveyIdsForDownload.current, [
          event.payload.surveyId,
        ])
      }
      break
  }
}

const addToArray = (oldArray: string[], newItem: string) => {
  let newArray = [...oldArray]
  if (!newArray.includes(newItem)) {
    newArray.push(newItem)
  }
  return newArray
}
const removeFromArray = (oldArray: string[], itemToRemove: string) => {
  return oldArray.filter((item) => item !== itemToRemove)
}
