import React from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { Alert as MuiAlert, Autocomplete } from '@material-ui/lab'
import * as selectors from 'src/redux/selectors'

import {
  fetchMdatAssessmentContent,
  updateMdatAssessmentContent,
} from '../../../services/Api'
import { AdminPage } from '../../AdminPage'

type SnackBarStatusType = 'success' | 'error' | undefined

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    table: {
      minWidth: 650,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
)

export const MdatAutocomplete = () => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)

  const [grid, setGrid] = React.useState('gm')
  const [pageData, setPageData] = React.useState<any>([])
  const [
    snackbarStatus,
    setSnackbarStatus,
  ] = React.useState<SnackBarStatusType>()
  const [isSaving, setIsSaving] = React.useState(false)
  const updates = React.useRef({})

  React.useEffect(() => {
    if (token) {
      fetchMdatAssessmentContent(token).then(({ data }) => {
        setPageData(data)
      })
    }
  }, [token])

  const handleChangeGrid = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGrid(event.target.value as string)
  }

  const addUpdate = (id, data) => {
    updates.current[id] = data
  }

  function save() {
    setIsSaving(true)
    updateMdatAssessmentContent(token, updates.current)
      .then(() => setSnackbarStatus('success'))
      .catch(() => setSnackbarStatus('error'))
      .finally(() => setIsSaving(false))
  }
  return (
    <AdminPage title="MDAT">
      <Typography variant="h6">
        Auto-completion / Auto-highlight rules
      </Typography>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
        <FormControl className={classes.formControl}>
          <Select
            labelId="grid-select-label"
            id="grid-select"
            value={grid}
            onChange={handleChangeGrid}>
            <MenuItem value="s">S</MenuItem>
            <MenuItem value="fm">FM</MenuItem>
            <MenuItem value="l">L</MenuItem>
            <MenuItem value="gm">GM</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={isSaving}
          onClick={save}
          variant="contained"
          color="primary">
          Save
          {isSaving && (
            <CircularProgress className={classes.buttonProgress} size={24} />
          )}
        </Button>
      </div>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="App">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>
                  <strong>Item</strong>
                </TableCell>
                <TableCell style={{ width: '40%' }}>
                  <strong>Auto-pass</strong>
                </TableCell>
                <TableCell style={{ width: '40%' }}>
                  <strong>Highlights</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData &&
                pageData
                  .filter((item) => item.grid === grid.toLowerCase())
                  .map((item) => (
                    <CustomRow
                      key={`row_${item.id}`}
                      {...{ item, pageData, grid }}
                      item={item}
                      updates={updates.current}
                      addUpdate={addUpdate}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}

const CustomRow = ({ item, pageData, grid, updates, addUpdate }) => {
  let values = updates[item.id] || item
  const [autoPass, setAutoPass] = React.useState<string[]>(
    values.auto_pass || [],
  )
  const [highlights, setHighlights] = React.useState<string[]>(
    values.highlights || [],
  )

  let options: string[] = pageData
    .filter((page) => page.grid === grid.toLowerCase())
    .map((it) => it.id)

  return (
    <TableRow key={item.id} style={{ alignItems: 'flex-start' }}>
      <TableCell>{getMdatName(item.id)}</TableCell>
      <TableCell>
        <Autocomplete
          multiple
          key={item.id}
          id={`autocomplete_${item.id}`}
          options={options}
          value={autoPass}
          onChange={(evt, val) => {
            setAutoPass(val)
            addUpdate(item.id, { autoPass: val, highlights })
          }}
          getOptionLabel={(option: any) => getMdatName(option)}
          renderInput={(params) => (
            <TextField key={item.id} {...params} variant="standard" />
          )}
        />
      </TableCell>
      <TableCell>
        <Autocomplete
          multiple
          key={item.id}
          id={`highlight_${item.id}`}
          options={options}
          value={highlights}
          onChange={(evt, val) => {
            setHighlights(val)
            addUpdate(item.id, { autoPass, highlights: val })
          }}
          getOptionLabel={(option: any) => getMdatName(option)}
          renderInput={(params) => (
            <TextField key={item.id} {...params} variant="standard" />
          )}
        />
      </TableCell>
    </TableRow>
  )
}

const getMdatName = (id) => id.replace(/gm|fm|l|s/g, '$& ').toUpperCase()
