import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'
import { assessmentContentLastUpdated } from 'src/services/Api'

import { AdminPage } from './AdminPage'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cell: {
    fontSize: 16,
  },
  instructions: {
    fontSize: 17,
  },
})

export const AssessmentContent = () => {
  const [lastUpdatedData, setLastUpdatedData] = React.useState<any>()
  const token = useSelector(selectors.getToken)

  const classes = useStyles()

  const appItems = [
    { title: 'Consent forms', link: 'consent-form', dbName: 'consentForms' },
    {
      title: 'Age-based filtration',
      link: 'age-based-filtration',
      dbName: 'ageBasedFiltration',
    },
    { title: 'User guide', link: 'user-guide', dbName: 'userGuide' },
  ]

  const testItems = [
    { title: 'MDAT', link: 'mdat', dbName: 'mdat' },
    {
      title: 'Preferential Looking Task',
      link: 'preferential-looking-task',
      dbName: 'preferentialLookingTask',
    },
    { title: 'Wheel Task', link: 'wheel-task', dbName: 'wheelTask' },
    {
      title: 'Language Sampling Task',
      link: 'language-sampling-task',
      dbName: 'languageSamplingTask',
    },
    { title: 'Button Task', link: 'choice-task', dbName: 'choiceTask' },
    {
      title: 'Colouring Task',
      link: 'colouring-task',
      dbName: 'colouringTask',
    },
    {
      title: 'Synchrony Task',
      link: 'synchrony-task',
      dbName: 'synchronyTask',
    },
    {
      title: 'Delayed Gratification Task',
      link: 'delayed-gratification-task',
      dbName: 'delayedGratificationTask',
    },
    {
      title: 'PCI',
      link: 'free-play-pci-task',
      dbName: 'freeplayPci',
    },
    {
      title: 'Parent Assessment Task',
      link: 'parent-assessment-questions',
      dbName: 'parentAssessment',
    },
  ]

  useEffect(() => {
    assessmentContentLastUpdated(token).then((result) => {
      if (result && result.data) {
        setLastUpdatedData(result.data.assessmentContentLastUpdated)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPage title="Assessment content">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.instructions}>
            From this screen, you can edit the app consent form, the age-based
            filtration of the tests, and the content of different tests.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="App">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell} style={{ width: '50%' }}>
                    <strong>App</strong>
                  </TableCell>
                  <TableCell className={classes.cell} style={{ width: '50%' }}>
                    <strong>Latest Update</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appItems.map((row) => (
                  <TableRow key={row.link}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cell}>
                      <ReactRouterLink to={`${row.link}`}>
                        {row.title}
                      </ReactRouterLink>
                    </TableCell>
                    <TableCell>
                      {lastUpdatedData &&
                        moment(new Date(lastUpdatedData[row.dbName])).format(
                          'DD/MM/YYYY h:mm A',
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Tests">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell} style={{ width: '50%' }}>
                    <strong>Tests</strong>
                  </TableCell>
                  <TableCell className={classes.cell} style={{ width: '50%' }}>
                    <strong>Latest Update</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {testItems.map((row) => (
                  <TableRow key={row.title}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.cell}>
                      <ReactRouterLink to={`${row.link}`}>
                        {row.title}
                      </ReactRouterLink>
                    </TableCell>
                    <TableCell>
                      {lastUpdatedData &&
                        lastUpdatedData.tasks &&
                        moment(
                          new Date(lastUpdatedData.tasks[row.dbName]),
                        ).format('DD/MM/YYYY h:mm A')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </AdminPage>
  )
}
