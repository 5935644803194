import React, { useEffect } from 'react'
import {
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  Paper,
  Snackbar,
  Theme,
  Typography,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import MuiAlert from '@material-ui/lab/Alert'
import { env } from 'src/env'
import { fetchFileMetadata, uploadAssessmentContent } from 'src/services/Api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flex: 1,
      minHeight: 200,
      minWidth: 200,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    supportedTypes: {
      paddingTop: 4,
      fontSize: 12,
      color: '#6d6d6d',
    },
  }),
)

export const MediaContainer = ({
  media,
  token,
  restrictType,
  onUpload,
}: {
  media: { id: string; title: string; file: string }
  token: string
  restrictType?: any
  onUpload?: any
}) => {
  const classes = useStyles()
  //@TODO: fix this later
  // eslint-disable-next-line no-undef
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const imageRef = React.useRef<any>(null)
  const [mediaType, setMediaType] = React.useState<any>(undefined)
  const [previewMediaType, setPreviewMediaType] = React.useState<any>(undefined)
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const [uploadProgress, setUploadProgress] = React.useState<
    undefined | number
  >(undefined)

  useEffect(() => {
    if (previewMediaType === undefined) {
      fetchFileMetadata(token, media.id)
        .then((result) => {
          setPreviewMediaType(result.data.mediaType)
        })
        .catch(() => {
          setPreviewMediaType(undefined)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateProgress = (progressEvent) => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    )
    setUploadProgress(percentCompleted)
  }

  const getMediaType = (file: any) => {
    if (file) {
      if (file.type) {
        if (file.type.indexOf('video') > -1) {
          return 'video'
        }
        if (file.type.indexOf('image') > -1) {
          return 'image'
        }
      }
    }
    return undefined
  }

  const uploadFile = (file) => {
    const mType = getMediaType(file)
    if (mType === 'video') {
      setMediaType('video')
    } else if (mType === 'image') {
      setMediaType('image')
    } else {
      setSnackbarStatus('error')
      return
    }

    const data = new FormData()
    data.append('file', file)
    data.append('fileId', media.id)
    data.append('fileName', media.file)

    uploadAssessmentContent(token, data, updateProgress)
      .then(() => {
        if (onUpload) {
          onUpload(file, mediaType, token)
        }
        setPreviewMediaType(mType)
        setUploadProgress(undefined)
        setSnackbarStatus('success')
      })
      .catch(() => {
        setUploadProgress(undefined)
        setSnackbarStatus('error')
      })
  }

  return (
    <Paper className={classes.paper}>
      <React.Fragment>
        <div className={classes.headerRow}>
          <Typography component="h1" variant="subtitle1">
            {media.title}
          </Typography>
          <label htmlFor={media.id}>
            {restrictType && restrictType.indexOf('image') >= 0 && (
              <React.Fragment>
                <input
                  disabled={uploadProgress !== undefined}
                  onChange={(e) => {
                    if (!e.target.files) return
                    uploadFile(e.target.files[0])
                  }}
                  style={{ display: 'none' }}
                  id={media.id}
                  name={media.id}
                  type="file"
                  accept="image/png, image/jpeg"
                />
                <div style={{ float: 'right', textAlign: 'center' }}>
                  <Button
                    disabled={uploadProgress !== undefined}
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    Upload
                  </Button>
                  <div className={classes.supportedTypes}>(jpg, png only)</div>
                </div>
              </React.Fragment>
            )}
            {restrictType && restrictType.indexOf('video') >= 0 && (
              <React.Fragment>
                <input
                  disabled={uploadProgress !== undefined}
                  onChange={(e) => {
                    if (!e.target.files) return
                    uploadFile(e.target.files[0])
                  }}
                  style={{ display: 'none' }}
                  id={media.id}
                  name={media.id}
                  type="file"
                  accept=".mp4"
                />
                <div style={{ float: 'right', textAlign: 'center' }}>
                  <Button
                    disabled={uploadProgress !== undefined}
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    Upload
                  </Button>
                  <div className={classes.supportedTypes}>(mp4 only)</div>
                </div>
              </React.Fragment>
            )}
            {(restrictType === undefined || restrictType === false) && (
              <React.Fragment>
                <input
                  disabled={uploadProgress !== undefined}
                  onChange={(e) => {
                    if (!e.target.files) return
                    uploadFile(e.target.files[0])
                  }}
                  style={{ display: 'none' }}
                  id={media.id}
                  name={media.id}
                  type="file"
                  accept="image/png, image/jpeg, video/mp4"
                />
                <div style={{ float: 'right', textAlign: 'center' }}>
                  <Button
                    disabled={uploadProgress !== undefined}
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}>
                    Upload
                  </Button>
                  <div className={classes.supportedTypes}>
                    (jpg, png, mp4 only)
                  </div>
                </div>
              </React.Fragment>
            )}
          </label>
        </div>
        {uploadProgress && (
          <LinearProgress variant="determinate" value={uploadProgress} />
        )}
        {previewMediaType === 'image' && (
          <img
            style={{
              maxHeight: '100%',
              height: 'auto',
              width: 'auto',
              maxWidth: '100%',
            }}
            ref={imageRef}
            src={`${env.apiBaseUrl}/file-by-id/${
              media.id
            }?cachebuster=${new Date().toString()}`}
          />
        )}
        {previewMediaType === 'video' && (
          <video
            style={{
              width: '100%',
            }}
            controls
            ref={videoRef}>
            <source
              src={`${env.apiBaseUrl}/file-by-id/${
                media.id
              }?cachebuster=${new Date().toString()}`}
              type="video/mp4"
            />
          </video>
        )}
        {previewMediaType === undefined && <span>Upload images or videos</span>}
      </React.Fragment>

      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </Paper>
  )
}
