import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { AddParentAssessmentQuestion } from './pages/AddParentAssessmentQuestion'
import { AssessmentContent } from './pages/AssessmentContent'
import { AgeBasedFiltration } from './pages/AssessmentContent/AgeBasedFiltration'
import { ConsentForm } from './pages/AssessmentContent/ConsentForm'
import { Mdat } from './pages/AssessmentContent/Tests/Mdat'
import { MdatAutocomplete } from './pages/AssessmentContent/Tests/MdatAutocomplete'
import { ChoiceTask } from './pages/ChoiceTask'
import { ColouringTask } from './pages/ColouringTask'
import { CreateNewPassword } from './pages/CreateNewPassword'
import { DelayedGratificationTask } from './pages/DelayedGratificationTask'
import { EditChild } from './pages/EditChild'
import { EditParent } from './pages/EditParent'
import { FreePlayPCITask } from './pages/FreePlayPCITask'
import { Home } from './pages/Home'
import { LanguageSamplingTask } from './pages/LanguageSamplingTask'
import { Login } from './pages/Login'
import { Logs } from './pages/Logs'
import { NotFoundPage } from './pages/NotFound'
import { ParentAssessmentQuestions } from './pages/ParentAssessmentQuestions'
import { PreferentialLookingTask } from './pages/PreferentialLookingTask'
import { SurveyResults } from './pages/SurveyResults'
import { SynchronyTask } from './pages/SynchronyTask'
import { SystemUsers } from './pages/SystemUsers'
import { NewUser } from './pages/SystemUsers/NewUser'
import { UserGuide } from './pages/UserGuide'
import { ViewSurvey } from './pages/ViewSurvey'
import { ViewSurveyData } from './pages/ViewSurveyData'
import { WheelTask } from './pages/WheelTask'
import ProtectedRoute from './routing/ProtectedRoute'

export const Routing = () => (
  <Router>
    <Switch>
      <ProtectedRoute
        exact
        path="/"
        roles={['admin', 'superAdmin', 'backendResearcher']}>
        <Home />
      </ProtectedRoute>
      <Route path="/login" component={Login} />
      <Route path="/create-new-password" component={CreateNewPassword} />
      <ProtectedRoute path="/assessment-content" roles={['superAdmin']}>
        <AssessmentContent />
      </ProtectedRoute>
      <ProtectedRoute path="/consent-form" roles={['superAdmin']}>
        <ConsentForm />
      </ProtectedRoute>
      <ProtectedRoute path="/system-users" roles={['superAdmin', 'admin']}>
        <SystemUsers />
      </ProtectedRoute>
      <ProtectedRoute path="/user-form" roles={['superAdmin', 'admin']}>
        <NewUser />
      </ProtectedRoute>
      <ProtectedRoute path="/age-based-filtration" roles={['superAdmin']}>
        <AgeBasedFiltration />
      </ProtectedRoute>
      <ProtectedRoute path="/choice-task" roles={['superAdmin']}>
        <ChoiceTask />
      </ProtectedRoute>
      <ProtectedRoute path="/wheel-task" roles={['superAdmin']}>
        <WheelTask />
      </ProtectedRoute>
      <ProtectedRoute path="/language-sampling-task" roles={['superAdmin']}>
        <LanguageSamplingTask />
      </ProtectedRoute>
      <ProtectedRoute path="/preferential-looking-task" roles={['superAdmin']}>
        <PreferentialLookingTask />
      </ProtectedRoute>
      <ProtectedRoute path="/free-play-pci-task" roles={['superAdmin']}>
        <FreePlayPCITask />
      </ProtectedRoute>
      <ProtectedRoute path="/colouring-task" roles={['superAdmin']}>
        <ColouringTask />
      </ProtectedRoute>
      <ProtectedRoute
        path="/surveys"
        roles={['superAdmin', 'admin', 'backendResearcher']}>
        <SurveyResults />
      </ProtectedRoute>
      <ProtectedRoute path="/mdat" roles={['superAdmin']}>
        <Mdat />
      </ProtectedRoute>
      <ProtectedRoute path="/mdat-autocomplete" roles={['superAdmin']}>
        <MdatAutocomplete />
      </ProtectedRoute>
      <ProtectedRoute
        path="/parent-assessment-questions"
        roles={['superAdmin']}>
        <ParentAssessmentQuestions />
      </ProtectedRoute>
      <ProtectedRoute
        path="/parent-assessment-question-builder"
        roles={['superAdmin']}>
        <AddParentAssessmentQuestion />
      </ProtectedRoute>
      <ProtectedRoute path="/delayed-gratification-task" roles={['superAdmin']}>
        <DelayedGratificationTask />
      </ProtectedRoute>
      <ProtectedRoute path="/synchrony-task" roles={['superAdmin']}>
        <SynchronyTask />
      </ProtectedRoute>
      <ProtectedRoute path="/logs" roles={['superAdmin']}>
        <Logs />
      </ProtectedRoute>
      <ProtectedRoute path="/user-guide" roles={['superAdmin']}>
        <UserGuide />
      </ProtectedRoute>
      <ProtectedRoute
        path="/survey/view"
        roles={['superAdmin', 'admin', 'backendResearcher']}>
        <ViewSurvey />
      </ProtectedRoute>
      <ProtectedRoute
        path="/survey/attempt/preview"
        roles={['superAdmin', 'backendResearcher']}>
        <ViewSurveyData />
      </ProtectedRoute>
      <ProtectedRoute path="/edit-child" roles={['superAdmin']}>
        <EditChild />
      </ProtectedRoute>
      <ProtectedRoute
        path="/edit-parent"
        roles={['superAdmin', 'admin', 'backendResearcher']}>
        <EditParent />
      </ProtectedRoute>
      <Route path="/" component={NotFoundPage} />
    </Switch>
  </Router>
)
