import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  createStyles,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import * as selectors from 'src/redux/selectors'
import { editCustomFields, fetchCustomFields } from 'src/services/Api'

import { AdminPage } from './AdminPage'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
)

export const DelayedGratificationTask = () => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)
  const [snackbarStatus, setSnackbarStatus] = React.useState<any>(undefined)
  const [durationModel, setDurationModel] = React.useState<any>()
  const [fetchedData, setFetchedData] = React.useState<boolean>(false)

  const updateData = () => {
    editCustomFields(token, {
      fields: JSON.stringify({
        duration: parseInt(durationModel, 10),
      }),
      testId: 'delayedGratificationTask',
    })
      .then((result) => {
        if (result.data.success) {
          setSnackbarStatus('success')
        } else {
          setSnackbarStatus('error')
        }
      })
      .catch(() => {
        setSnackbarStatus('error')
      })
  }

  useEffect(() => {
    if (!fetchedData && token) {
      fetchCustomFields(token, 'delayedGratificationTask').then((resp: any) => {
        if (resp.data.fields) {
          const parsedFields = JSON.parse(resp.data.fields)
          const { duration } = parsedFields
          if (duration) {
            setDurationModel(String(duration))
          }
        }
      })
      setFetchedData(true)
    }
  }, [token, fetchedData])

  return (
    <AdminPage title="Delayed Gratification task">
      <Grid container className={classes.root} spacing={2}>
        <Grid xs={12} item>
          <FormLabel style={{ paddingBottom: 10 }} htmlFor="instructions">
            <Typography variant="h6">Duration (in ms)</Typography>
          </FormLabel>
          <FormControl style={{ width: 100 }}>
            <TextField
              key="duration"
              id="duration"
              name="duration"
              value={durationModel}
              onChange={(evt: any) => {
                setDurationModel(evt.target.value)
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => updateData()}>
              Save
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}
