import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'
import moment from 'moment'
import * as selectors from 'src/redux/selectors'

import { VideoContainer } from '../../../components/VideoContainer'
import {
  fetchMdatAssessmentContent,
  fetchMdatStopRule,
  updateMdatStopRule,
} from '../../../services/Api'
import { AdminPage } from '../../AdminPage'

type SnackBarStatusType = 'success' | 'error' | undefined

type MdatItem = {
  id: string
  grid: 's' | 'fm' | 'l' | 'gm'
  instruction: string
  file_id: string
  auto_pass: string[] | null
  highlights: string[] | null
  last_updated: Date | string
  video_last_updated: Date | string
  icon: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: 120,
    },
    table: {
      minWidth: 650,
    },
    contiguousContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: theme.spacing(2),
      alignItems: 'center',
    },
  }),
)

export const Mdat = () => {
  const classes = useStyles()
  const token = useSelector(selectors.getToken)

  const [grid, setGrid] = React.useState<'s' | 'fm' | 'l' | 'gm'>('gm')
  const [contiguousYeses, setContiguousYeses] = React.useState(4)
  const [contiguousNos, setContiguousNos] = React.useState(4)
  const [pageData, setPageData] = React.useState<MdatItem[]>([])
  const [
    snackbarStatus,
    setSnackbarStatus,
  ] = React.useState<SnackBarStatusType>()

  React.useEffect(() => {
    if (token) {
      fetchMdatAssessmentContent(token).then(({ data }) => {
        setPageData(data)
      })
      fetchMdatStopRule(token).then(({ data }) => {
        setContiguousYeses(data.contiguousStartRule)
        setContiguousNos(data.contiguousStopRule)
      })
    }
  }, [token])

  function updateStartRule(val) {
    setContiguousYeses(val)
    updateMdatStopRule(token, val, contiguousNos)
      .then(() => setSnackbarStatus('success'))
      .catch(() => setSnackbarStatus('error'))
  }

  function updateStopRule(val) {
    setContiguousNos(val)
    updateMdatStopRule(token, contiguousYeses, val)
      .then(() => setSnackbarStatus('success'))
      .catch(() => setSnackbarStatus('error'))
  }

  function changeGrid(event: React.ChangeEvent<{ value: unknown }>) {
    setGrid(event.target.value as 's' | 'fm' | 'l' | 'gm')
  }

  return (
    <AdminPage title="MDAT">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2">
          From this page, you can edit the stop rule, i.e. the number of
          contiguous "no" responses to be reached for the grid to be considered
          as complete. The same stop rule applies to the four domains.
        </Typography>
        <Typography variant="body2">
          Use the drop-down menu to select the grid you want to edit. For each
          of the items, you can change the instruction supportive video, and the
          auto-completion / highlight rules.
        </Typography>

        <div className={classes.contiguousContainer}>
          <Typography variant="body1" style={{ width: 300 }}>
            Number of contiguous "YES" responses for the start rule to be met
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: 100, marginLeft: 8 }}>
            <Select
              labelId="contiguous-select-label"
              id="contiguous-select"
              value={contiguousYeses}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                updateStartRule(event.target.value)
              }}>
              {Array.from(Array(15)).map((val, index) => (
                <MenuItem key={`contiguous_option_${index}`} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={classes.contiguousContainer}>
          <Typography variant="body1" style={{ width: 300 }}>
            Number of contiguous "NO" responses for the stop rule to be met
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: 100, marginLeft: 8 }}>
            <Select
              labelId="contiguous-select-label"
              id="contiguous-select"
              value={contiguousNos}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                console.log('NO', event.target.value, typeof event.target.value)
                updateStopRule(event.target.value)
              }}>
              {Array.from(Array(15)).map((val, index) => (
                <MenuItem key={`contiguous_option_${index}`} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Link
          to={'mdat-autocomplete'}
          style={{
            paddingTop: 24,
            paddingBottom: 24,
            width: 300,
          }}>
          <Typography variant="h6">Autocompletion Rule</Typography>
        </Link>

        <FormControl className={classes.formControl}>
          <Select
            labelId="grid-select-label"
            id="grid-select"
            value={grid}
            onChange={changeGrid}>
            <MenuItem value="s">S</MenuItem>
            <MenuItem value="fm">FM</MenuItem>
            <MenuItem value="l">L</MenuItem>
            <MenuItem value="gm">GM</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="App">
            <TableHead>
              <TableRow>
                <TableCell style={{ flex: 1 }}>
                  <strong>Item</strong>
                </TableCell>
                <TableCell style={{ flex: 2 }}>
                  <strong>Instruction</strong>
                </TableCell>
                <TableCell style={{ flex: 2 }}>
                  <strong>Video</strong>
                </TableCell>
                <TableCell style={{ flex: 2 }}>
                  <strong>Latest Update</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData &&
                pageData
                  .filter((item) => item.grid === grid.toLowerCase())
                  .map((item) => (
                    <TableRow
                      key={item.id}
                      style={{ alignItems: 'flex-start' }}>
                      <TableCell>{getMdatName(item.id)}</TableCell>
                      <TableCell style={{ maxWidth: 200 }}>
                        {item.instruction}
                      </TableCell>
                      <TableCell>
                        <VideoContainer
                          style={{ width: 200 }}
                          customMinHeight={'auto'}
                          video={{
                            id: item.file_id,
                            title: undefined,
                            file: item.file_id,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {moment(item.video_last_updated).format('lll')}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Snackbar
        open={snackbarStatus !== undefined}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setSnackbarStatus(undefined)}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbarStatus(undefined)}
          severity={snackbarStatus}>
          {snackbarStatus === 'success'
            ? 'Data successfully updated'
            : 'Operation could not be completed'}
        </MuiAlert>
      </Snackbar>
    </AdminPage>
  )
}

const getMdatName = (id) => id.replace(/gm|fm|l|s/g, '$& ').toUpperCase()
