import { ThunkAction } from 'redux-thunk'
import * as actions from 'src/redux/actions'
import { AllActions, RootState } from 'src/redux/types'

import { login as apiLogin } from '../../services/Api'

type ReturnType = { user: any; token: string } | string

export const login = (
  username,
  password,
): ThunkAction<Promise<ReturnType>, RootState, unknown, AllActions> => {
  return async (dispatch) => {
    dispatch(actions.loginInit())
    try {
      const response = await apiLogin(username, password)
      const { user, token } = response.data
      if (!user || !token) throw new Error('user or token not returned')
      dispatch(actions.loginSuccess({ user, token }))
      return { user, token }
    } catch (err) {
      let message = typeof err === 'string' ? err : 'Oops, something went wrong'
      if (err.response && err.response.data && err.response.data.message)
        message = err.response.data.message
      dispatch(actions.loginFail({ error: message }))
      return message
    }
  }
}
