import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as selectors from 'src/redux/selectors'

import { AdminPage } from './AdminPage'
import homeImage from './assets/STREAM.jpg'

export const Home = () => {
  const classes = useStyles()
  const user = useSelector(selectors.getUser)

  return (
    <AdminPage title="Home">
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.headerImageContainer}>
              <img src={homeImage} className={classes.headerImage} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.mainContentContainer}>
              <h1>Welcome</h1>
              <div className={classes.userInfo}>
                <Typography>
                  <strong>User: </strong>
                  {user ? user.firstName + ' ' + user.lastName : ''}
                </Typography>
                <Typography>
                  <strong>Role: </strong> {user ? user.role : ''}
                </Typography>
              </div>
              <Typography className={classes.p} variant="body1">
                STREAM is an open-source, scalable screening tool used to
                identify children who may be at risk of developmental
                difficulties. Due to a scarcity of highly-skilled professionals
                and limited access to appropriate services, many developmental
                disorders go undetected, creating a ‘detection gap.
              </Typography>
              <Typography className={classes.p} variant="body1">
                STREAM aims to address this detection gap and improve
                risk-screening for various developmental disorders that may be
                evident during the early developmental period. The STREAM tool
                is a tablet-based app comprising various game-style tests,
                direct observations, and parent questionnaires. STREAM is
                designed to be engaging and easy to use and is currently
                available in English, Hindi, and Chichewa.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    </AdminPage>
  )
}

const useStyles = makeStyles(() => ({
  userInfo: {
    marginBottom: 20,
    marginTop: 20,
  },
  p: {
    marginBottom: 20,
    marginTop: 20,
  },
  headerImageContainer: {
    padding: 15,
  },
  mainContentContainer: {
    padding: 15,
  },
  headerImage: {
    width: '100%',
  },
}))
