import React from 'react'
import { Checkbox } from '@material-ui/core'
import { SurveyResultEvent } from 'src/pages/SurveyResults/types'
import { User } from 'src/redux/reducers/auth'

export const SelectAllChildren = ({
  user,
  publishEvent,
  subscribe,
}: {
  user: User | null
  subscribe: (listener: (event: SurveyResultEvent) => void) => () => void
  publishEvent: (event: SurveyResultEvent) => void
}) => {
  const [selected, setSelected] = React.useState(false)

  React.useEffect(() => {
    return subscribe(onItemEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onItemEvent = (event: SurveyResultEvent) => {
    switch (event.type) {
      case 'select-all':
        setSelected(true)
        break
      case 'deselect-all':
        setSelected(false)
        break
      default:
        break
    }
  }

  return (
    <Checkbox
      disabled={user ? user.role === 'admin' : false}
      checked={selected}
      onChange={() => {
        if (!selected) {
          return publishEvent({ type: 'select-all' })
        }
        publishEvent({ type: 'deselect-all' })
      }}
      name={'select-all'}
      color="primary"
    />
  )
}
